/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-constructed-context-values */
/*
 * Author M. Atoar Rahman
 * Title: D2 Distribution Context
 * Description: Page for D2 Distribution Context
 * Date: 30/05/2022
 */

import { createContext, useContext, useState } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';

// Create Context
const ContextAPI = createContext();

export function useD2Distribution() {
    return useContext(ContextAPI);
}

// Provider Component
export function D2DistributionProvider({ children }) {
    const [show, setShow] = useState(false);
    const [formShow, setFormShow] = useState(false);
    const [rowData, setRowData] = useState('');
    const [d2CheckedData, setD2CheckedData] = useState();
    const [d2ApprovedData, setD2ApprovedData] = useState();
    const [receiveData, setReceiveData] = useState({});
    const [receiveList, setReceiveList] = useState({});

    // Dependency
    const axiosPrivate = useAxiosPrivate();

    // API Function: Fatch All Data  All D2 Data
    const getD2CheckedData = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getAllD2Url('final_approved', 0), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setD2CheckedData(response?.data?.d2_preparation_info);
            if (response?.data?.status === 'error') {
                ToastifyService.errorNotify(response?.data?.message);
            }
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    const getD2ApprovedData = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getAllD2Url('d2_supplied', 0), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setD2ApprovedData(response?.data?.d2_preparation_info);
            if (response?.data?.status === 'error') {
                ToastifyService.errorNotify(response?.data?.message);
            }
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    return (
        <ContextAPI.Provider
            value={{
                show,
                setShow,
                rowData,
                setRowData,
                formShow,
                setFormShow,
                d2CheckedData,
                getD2CheckedData,
                d2ApprovedData,
                getD2ApprovedData,
                receiveData,
                setReceiveData,
                receiveList,
                setReceiveList,
            }}
        >
            {children}
        </ContextAPI.Provider>
    );
}

export default ContextAPI;
