/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import { Badge, Button } from 'react-bootstrap';
import { useAuth } from '../../../contexts/AuthContext';
import useDataTable from '../../../hooks/useDataTable';
import SweetAlertService from '../../../services/SweetAlertService';
import UrlService from '../../../services/UrlService';
import { useData } from './ContextAPI';

export default function List({ editHander }) {
    const { auth } = useAuth();
    const { route_permissions } = auth;
    const { data, getAllData } = useData();

    const { deleteSwal } = SweetAlertService();

    const dataList = useCallback(() => {
        getAllData();
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    const deleteHandler = (row) => {
        deleteSwal({
            id: row.id,
            url: UrlService.deleteWarehouseInfoUrl(),
            refreshList: getAllData,
            beforeTitle: 'Are you sure?',
            beforeText: "You won't be able to revert this!",
            afterTitle: 'Deleted!',
            afterText: 'Your file has been deleted.',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        });
    };

    const columns = [
        {
            name: 'Code',
            selector: (row) => row.code_no,
            sortable: true,
        },
        {
            name: 'Name',
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: 'Address',
            selector: (row) => row.address,
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row) => row.status,
            cell: (row) =>
                row.status === 1 ? (
                    <Badge className="badgeActive" bg="success">
                        Active
                    </Badge>
                ) : (
                    <Badge className="badgeInactive" bg="success">
                        Inactive
                    </Badge>
                ),
        },
        {
            name: 'Action',
            selector: (row) => row.action,
            cell: (row) => (
                <>
                    {route_permissions['warehouse-info'].edit && (
                        <Button
                            className="btn btn-light actionBtn"
                            variant="primary"
                            onClick={() => editHander(row)}
                        >
                            <i className="fas fa-edit" />
                        </Button>
                    )}

                    {route_permissions['warehouse-info'].delete && (
                        <Button
                            onClick={() => deleteHandler(row)}
                            type="button"
                            className="btn btn-light actionBtn"
                        >
                            <i className="fas fa-trash-alt" />
                        </Button>
                    )}
                </>
            ),
        },
    ];

    const search_column = {
        code_no: 'Code No',
        name: 'Name',
        address: 'Address',
    };
    return useDataTable({ columns, data, search_column });
}
