/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Current Stock
 * Description: Index for Current Stock
 * Date: 13/07/2022
 */

import { useCurrentStock } from './ContextAPI';
import CurrentData from './CurrentData';
import FormInfo from './FormInfo';
import PrintIndex from './pdf/PrintIndex';

export default function Index() {
    const { showForm, showPdf } = useCurrentStock();

    return showForm ? <FormInfo /> : showPdf ? <PrintIndex /> : <CurrentData />;
}
