/* eslint-disable jsx-a11y/anchor-is-valid */
/*
 * Author M. Atoar Rahman
 * Title: Admin Header
 * Description: Admin Header
 * Date: 15/03/2022
 */

import React from 'react';
import UserDropdown from './UserDropdown';
import WindowFullscreen from './WindowFullscreen';

function Header(props, ref) {
    return (
        <nav className="main-header navbar navbar-expand navbar-dark navbar-light">
            {/* Left navbar links */}
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a ref={ref} className="nav-link" href="#" role="button">
                        <i className="fas fa-exchange-alt" />
                    </a>
                </li>
            </ul>

            {/* Right navbar links */}
            <ul className="navbar-nav ml-auto">
                {/* Notifications Dropdown Menu */}
                <li className="nav-item dropdown notification">
                    {/* <a className="nav-link" data-toggle="dropdown" href="#">
                        <i className="fa-solid fa-bell" />
                        <span className="badge badge-danger navbar-badge">15</span>
                        <span className="pulse-ring" />
                    </a> */}
                </li>
                <li className="nav-item">
                    <WindowFullscreen />
                </li>
                {/* User Info  */}
                <UserDropdown />
            </ul>
        </nav>
    );
}
const forwardedHeader = React.forwardRef(Header);
export default forwardedHeader;
