/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title:Requisition Table
 * Description: Requisition Table
 * Date: 30/05/2022
 */

import moment from 'moment';
import { Button } from 'react-bootstrap';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useDataTable from '../../../hooks/useDataTable';
import UrlService from '../../../services/UrlService';
import { layout } from '../../SvgIcon';
import { useD2Form } from './ContextAPI';
// import data from './data';

export default function RequisitionTable() {
    // State for Model
    const { setShow, setRowData, requisitionData } = useD2Form();
    const axiosPrivate = useAxiosPrivate();

    const showHander = async (row) => {
        console.log({ row });
        try {
            const response = await axiosPrivate.get(
                UrlService.getD1RecordByIdUrl(row.d1_master_id),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            const row_info = {
                facility_name: row.facility_name,
                stock_status: row.stock_status,
                submitted_date: row.submitted_date,
                records: response?.data?.d1_record_list,
            };
            setRowData(row_info);
            setShow(true);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    const columns = [
        {
            name: 'Facility Name',
            selector: (row) => row.facility_name,
            sortable: true,
            grow: 3,
        },
        {
            name: 'Submitted Date',
            selector: (row) => row.submitted_date,
            format: (row) => moment(row.submitted_date).format('DD MMM YYYY'),
            sortable: true,
        },
        {
            name: 'Action',
            selector: (row) => row.action,
            cell: (row) => (
                <Button
                    onClick={() => showHander(row)}
                    type="button"
                    className="btn btn-light actionBtn"
                >
                    {layout}
                </Button>
            ),
        },
    ];

    const search_column = {
        facility_name: 'Facility',
        submited_date: 'Submited Date',
    };
    return useDataTable({ columns, data: requisitionData, search_column, pagination: false });
}
