/*
 * Author M. Atoar Rahman
 * Title: User Information
 * Description: Page for User Information
 * Date: 15/03/2022
 */

import React from 'react';
import { Card } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';
import { UserProvider } from '../userManagement/userInfo/ContexAPI';
import DataTable from '../userManagement/userInfo/DataTable';
import FormInfo from '../userManagement/userInfo/FormInfo';
import PermissionDenied from './PermissionDenied';

export default function UserInformation() {
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['user-info']) return <PermissionDenied />;
    return (
        <UserProvider>
            <Card>
                <Card.Header className="cardHeader">
                    <h3>User Information</h3>
                    <div>
                        <FormInfo />
                    </div>
                </Card.Header>
                <Card.Body>
                    <DataTable />
                </Card.Body>
            </Card>
        </UserProvider>
    );
}
