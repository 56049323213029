/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useContext, useState } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import UrlService from '../../../services/UrlService';

const LIST_URL = UrlService.getAllRoleUrl();
const ContextAPI = createContext({});

export function useData() {
    return useContext(ContextAPI);
}

export function DataProvider({ children }) {
    const axiosPrivate = useAxiosPrivate();
    const [data, setData] = useState();

    const getAllData = async () => {
        try {
            const response = await axiosPrivate.get(LIST_URL, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setData(response?.data?.role_info);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    return (
        <ContextAPI.Provider value={{ data, setData, getAllData }}>{children}</ContextAPI.Provider>
    );
}

export default ContextAPI;
