/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: D1 Form: Medicines Requisition for Facility
 * Description: D1 Form: Medicines Requisition for Facility
 * Date: 15/03/2022
 */

import { toArray } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useDataTable from '../../../hooks/useDataTable';
import UrlService from '../../../services/UrlService';
import { useD1Form } from './ContextAPI';

export default function RequisitionTable({ id }) {
    const { requisitionList, setRequisitionList, requisitionData, setRequisitionData } =
        useD1Form();
    const [data, setData] = useState();
    const axiosPrivate = useAxiosPrivate();

    const categoryHandler = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getMedicineListByIdUrl(id), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setData(response?.data?.medicine_list);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    useEffect(() => {
        categoryHandler();
    }, []);

    const requisitionRowHandler = (value, item) => {
        const itemdata = item;
        itemdata.req_qty = value;
        const list = { ...requisitionData };
        list[itemdata.drug_id] = itemdata;
        if (value === '') {
            delete list[itemdata.drug_id];
        }
        setRequisitionData(list);
    };

    // Object to Array Convart
    useEffect(() => {
        const arrOfObj = toArray(requisitionData);
        setRequisitionList(arrOfObj);
    }, [requisitionData]);

    const columns = [
        {
            name: 'Item Names',
            selector: (row) => row.drug_name,
            sortable: true,
            wrap: true,
            width: '250px',
            fixed: true,
        },
        {
            name: 'Opening Balance',
            selector: (row) => row.opening_balance,
            sortable: true,
        },
        {
            name: 'Total Quantity Received During The Month',
            selector: (row) => row.received_qty,
            sortable: true,
        },
        {
            name: 'Total Quantity Consumed By the Facility (OPD, IPD, Emergency, any other dept)',
            selector: (row) => row.consumed_qty,
            sortable: true,

            width: '150px',
        },
        {
            name: 'Number Of Days Out Of Stock In The Pharmacy',
            selector: (row) => row.stock_out_days,
            sortable: true,
        },
        {
            name: 'Average Monthly Consumption',
            selector: (row) => row.avg_monthly_consumption,
            sortable: true,
        },
        {
            name: 'Total Quantities Returned To Main Agency Warehouse (Due To over stock)',
            selector: (row) => row.total_return,
            sortable: true,

            width: '150px',
        },
        {
            name: 'Total Quanties Expired or Damaged',
            selector: (row) => row.expired_qty,
            sortable: true,
        },
        {
            name: 'Earliest Date of Expiry of Physical Stocks',
            selector: (row) => row.expire_date,
            format: (row) => moment(row.expire_date).format('DD MMM YYYY'),
            sortable: true,

            width: '150px',
        },
        {
            name: 'Closing Balance (Physical Stock)',
            selector: (row) => row.closing_balance,
            sortable: true,
        },
        {
            name: 'Theoretical Stock',
            selector: (row) => row.theoritical_stock,
            sortable: true,
        },
        {
            name: 'Number of Days of Physical Stock Available',
            selector: (row) => row.number_of_days_available_stock,
            sortable: true,
        },
        {
            name: 'Request Qty',
            selector: (row) => row.req_qty,
            cell: (row) => (
                <Form.Control
                    size="sm"
                    type="number"
                    onBlur={(e) => {
                        requisitionRowHandler(e.target.value, row);
                    }}
                    defaultValue={requisitionList?.[row.id]?.req_qty}
                />
            ),
            width: '150px',
            reorder: true,
        },
    ];

    const search_column = {
        drug_name: 'Name',
    };
    return useDataTable({ columns, data, search_column, pagination: false });
}
