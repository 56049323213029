/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable radix */
/* eslint-disable no-useless-escape */

import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import Select from 'react-select';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import CommonApiService from '../../../services/CommonApiService';
import * as pattern from '../../../services/Helpers';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import PartnerForm from '../../masterSetup/partnerInfo/FormInfo';
import { useData } from './ContextAPI';

export default function FormInfo({ editData, hideFunction }) {
    const { getAllData } = useData();
    const { partner, getPartner } = CommonApiService();
    const [partnerData, setPartnerData] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const dataList = useCallback(() => {
        getPartner();
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    useEffect(() => {
        setPartnerData(
            partner?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
    }, [partner]);

    const axiosPrivate = useAxiosPrivate();
    const handleClose = () => {
        hideFunction(false);
    };

    // API URL
    let SAVE_URL = '';
    if (editData === '') {
        SAVE_URL = UrlService.saveWarehouseInfoUrl();
    } else {
        SAVE_URL = UrlService.updateWarehouseInfoUrl(editData.id);
    }

    const {
        register,
        formState: { errors },
        handleSubmit,
        control,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
        defaultValues: {
            code_no: editData?.code_no ?? '',
            name: editData?.name ?? '',
            address: editData?.address ?? '',
            phone: editData?.phone ?? '',
            mobile: editData?.mobile ?? '',
            email: editData?.email ?? '',
            contact_person: editData?.contact_person ?? '',
            service_partner: editData?.service_partner_list?.map((list) => ({
                partner_id: {
                    value: list?.partner_id,
                    label: list?.partner?.name,
                },
            })) ?? [
                {
                    partner_id: '',
                },
            ],
            status: parseInt(editData?.status) !== 0,
        },
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'service_partner',
    });

    const formSubmitHandler = async (data) => {
        setIsSubmitted(true);

        try {
            const response = await axiosPrivate.post(SAVE_URL, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response?.data?.status === 'success') {
                ToastifyService.successNotify(response?.data?.message);
            } else {
                console.log(response);
                ToastifyService.errorNotify(response?.data?.message);
            }
            getAllData();
            handleClose();
        } catch (err) {
            console.log(err?.response);
            ToastifyService.errorNotify(err?.response?.statusText);
        }
    };

    return (
        <Modal className="modalWidth55" show onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add/Edit Warehouse Info</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-2">
                        <Row>
                            <Col lg={2}>
                                <Form.Label>
                                    Code No
                                    <span className="required">*</span>
                                </Form.Label>
                            </Col>
                            <Col lg={4}>
                                <Form.Control
                                    type="text"
                                    placeholder=""
                                    id="code_no"
                                    name="code_no"
                                    autoComplete="off"
                                    {...register('code_no', {
                                        required: 'Please enter your Code No.',
                                        pattern: {
                                            value: /^[a-z]*?\-?\d+$/i,
                                            message: 'Only allow example: AB-1234 or 1234 format',
                                        },
                                    })}
                                    className={errors?.code_no && 'invalidInput'}
                                />
                                {errors?.code_no && (
                                    <span className="invalid">{errors?.code_no?.message}</span>
                                )}
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-2">
                        <Row>
                            <Col lg={2}>
                                <Form.Label>
                                    Name
                                    <span className="required">*</span>
                                </Form.Label>
                            </Col>
                            <Col lg={10}>
                                <Form.Control
                                    type="text"
                                    placeholder=""
                                    id="name"
                                    name="name"
                                    autoComplete="off"
                                    {...register('name', {
                                        required: 'Please enter your name.',
                                        pattern: pattern.namePattern,
                                    })}
                                    className={errors?.name && 'invalidInput'}
                                />
                                {errors?.name && (
                                    <span className="invalid">{errors?.name?.message}</span>
                                )}
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-2">
                        <Row>
                            <Col lg={2}>
                                <Form.Label>
                                    Address
                                    <span className="required">*</span>
                                </Form.Label>
                            </Col>
                            <Col lg={10}>
                                <Form.Control
                                    // type="text"
                                    as="textarea"
                                    placeholder=""
                                    id="address"
                                    name="address"
                                    autoComplete="off"
                                    {...register('address', {
                                        required: 'Please enter your address.',
                                        pattern: pattern.addressPattern,
                                    })}
                                    className={errors?.address && 'invalidInput'}
                                />
                                {errors?.address && (
                                    <span className="invalid">{errors?.address?.message}</span>
                                )}
                            </Col>
                        </Row>
                    </Form.Group>

                    <Row>
                        <Col lg={6}>
                            <Form.Group className="mb-2">
                                <Row>
                                    <Col lg={4}>
                                        <Form.Label>
                                            Phone
                                            <span className="required">*</span>
                                        </Form.Label>
                                    </Col>
                                    <Col lg={8}>
                                        <Form.Control
                                            type="text"
                                            placeholder=""
                                            id="phone"
                                            name="phone"
                                            autoComplete="off"
                                            {...register('phone', {
                                                required: 'Please enter your phone number.',
                                                pattern: pattern.phonePattern,
                                            })}
                                            className={errors?.phone && 'invalidInput'}
                                        />
                                        {errors?.phone && (
                                            <span className="invalid">
                                                {errors?.phone?.message}
                                            </span>
                                        )}
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mb-2">
                                <Row>
                                    <Col lg={4}>
                                        <Form.Label>
                                            Mobile
                                            <span className="required">*</span>
                                        </Form.Label>
                                    </Col>
                                    <Col lg={8}>
                                        <Form.Control
                                            type="text"
                                            placeholder=""
                                            id="mobile"
                                            name="mobile"
                                            autoComplete="off"
                                            {...register('mobile', {
                                                required: 'Please enter your mobile number.',
                                                pattern: pattern.mobilePattern,
                                            })}
                                            className={errors?.mobile && 'invalidInput'}
                                        />
                                        {errors?.mobile && (
                                            <span className="invalid">
                                                {errors?.mobile?.message}
                                            </span>
                                        )}
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mb-2">
                                <Row>
                                    <Col lg={4}>
                                        <Form.Label>
                                            Email
                                            <span className="required">*</span>
                                        </Form.Label>
                                    </Col>
                                    <Col lg={8}>
                                        <Form.Control
                                            type="text"
                                            placeholder=""
                                            id="email"
                                            name="email"
                                            autoComplete="off"
                                            {...register('email', {
                                                required: 'Please enter your email.',
                                                pattern: pattern.emailPattern,
                                            })}
                                            className={errors?.email && 'invalidInput'}
                                        />
                                        {errors?.email && (
                                            <span className="invalid">
                                                {errors?.email?.message}
                                            </span>
                                        )}
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mb-2">
                                <Row>
                                    <Col lg={4}>
                                        <Form.Label>
                                            Contact Person
                                            <span className="required">*</span>
                                        </Form.Label>
                                    </Col>
                                    <Col lg={8}>
                                        <Form.Control
                                            type="text"
                                            placeholder=""
                                            id="contact_person"
                                            name="contact_person"
                                            autoComplete="off"
                                            {...register('contact_person', {
                                                required: 'Please enter your Contact Person.',
                                                pattern: pattern.mobilePattern,
                                            })}
                                            className={errors?.contact_person && 'invalidInput'}
                                        />
                                        {errors?.contact_person && (
                                            <span className="invalid">
                                                {errors?.contact_person?.message}
                                            </span>
                                        )}
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>
                    </Row>

                    <fieldset className="customFieldset p-2 mb-3">
                        <Row>
                            <Col lg={12}>
                                <Form.Group className="mb-3">
                                    <Row>
                                        <Col lg={6} className="pe-1">
                                            <div className="labelRightButton">
                                                <Form.Label className="float-start pe-5">
                                                    Service Partner
                                                    <span className="required">*</span>
                                                </Form.Label>
                                                <PartnerForm instantCreate />
                                            </div>
                                        </Col>
                                    </Row>
                                    {fields.map((item, index) => (
                                        <Row key={Math.random()}>
                                            <Col lg={6} className="pe-1 mb-1">
                                                <Controller
                                                    name={`service_partner[${index}].partner_id`}
                                                    id={`service_partner[${index}].partner_id`}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            classNamePrefix={
                                                                typeof errors?.service_partner?.[
                                                                    index
                                                                ]?.partner_id !== 'undefined' &&
                                                                errors?.service_partner?.[index]
                                                                    ?.partner_id &&
                                                                'invalidInput'
                                                            }
                                                            options={partnerData}
                                                            // onFocus={() => getPartner()}
                                                        />
                                                    )}
                                                    rules={{
                                                        required: 'Please select your supplier.',
                                                    }}
                                                />
                                                {errors?.service_partner?.[index]?.partner_id && (
                                                    <span className="invalid">
                                                        {
                                                            errors.service_partner?.[index]
                                                                ?.partner_id?.message
                                                        }
                                                    </span>
                                                )}
                                            </Col>
                                            <Col lg={2} className="p-0">
                                                {fields.length - 1 === index && (
                                                    <button
                                                        onClick={() => {
                                                            append({ service_partner: '' });
                                                        }}
                                                        type="button"
                                                        className="btn btn-success text-white actionBtn"
                                                    >
                                                        <i className="fas fa-plus" />
                                                    </button>
                                                )}

                                                {fields.length !== 1 && (
                                                    <button
                                                        onClick={() => remove(index)}
                                                        type="button"
                                                        className="btn btn-danger text-white actionBtn"
                                                    >
                                                        <i className="fas fa-trash-alt" />
                                                    </button>
                                                )}
                                            </Col>
                                        </Row>
                                    ))}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col />
                        </Row>
                    </fieldset>

                    <Form.Group className="mb-3">
                        <Row>
                            <Col lg={3}>
                                <Form.Label>
                                    Status
                                    <span className="required">*</span>
                                </Form.Label>
                            </Col>
                            <Col lg={9}>
                                <Form.Check
                                    type="switch"
                                    id="status"
                                    name="status"
                                    {...register('status')}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Modal.Footer className="borderTop0 pe-0">
                        <Button className="btnCancel" variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            className="btnSuccess"
                            variant="success"
                            onClick={handleSubmit(formSubmitHandler)}
                            disabled={isSubmitted}
                        >
                            Create New
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
