/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Center Stock Loss
 * Description: Center Stock Loss
 * Date: 15/06/2022
 */

import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import useDataTable from '../../../hooks/useDataTable';
import { layout } from '../../SvgIcon';
import { useStockLoss } from './ContextAPI';

export default function DataTable() {
    const { stockLoss, getAllData, setShowModal, setRowData } = useStockLoss();

    const dataList = useCallback(() => {
        getAllData();
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    const showHander = (row) => {
        setShowModal(true);
        setRowData(row);
    };

    const columns = [
        {
            name: 'Warehouse Name',
            selector: (row) => row.warehouse_name,
            sortable: true,
        },
        {
            name: 'Date',
            selector: (row) => row.date,
            format: (row) => moment(row.date).format('DD MMM YYYY'),
            sortable: true,
        },
        {
            name: 'Action',
            selector: (row) => row.action,
            cell: (row) => (
                <Button
                    className="btn btn-light actionBtn"
                    variant="primary"
                    onClick={() => showHander(row)}
                >
                    {layout}
                </Button>
            ),
        },
    ];

    return useDataTable({ columns, data: stockLoss });
}
