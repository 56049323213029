import { Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { useStockReturn } from './ContextAPI';

export default function ShowModel() {
    const { showModal, setShowModal, rowData } = useStockReturn();
    // Closing the Model
    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <Modal className="modalWidth55" show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Dispensary Stock Return Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Row>
                            <Col>
                                <Table bordered hover size="sm">
                                    <thead className="table-light">
                                        <tr>
                                            <th>Partner Name</th>
                                            <th>Facility Name</th>
                                            <th>Dispensary Name</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{rowData?.partner_name}</td>
                                            <td>{rowData?.facility_name}</td>
                                            <td>{rowData?.dispensary_name}</td>
                                            <td>{rowData?.date}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table bordered hover size="sm">
                                    <thead className="table-light">
                                        <tr>
                                            <th>Item Name</th>
                                            <th>Req.Qty</th>
                                            <th>Apv.Qty</th>
                                        </tr>
                                    </thead>
                                    {rowData?.items?.map((el) => (
                                        <tbody key={Math.random()}>
                                            <tr>
                                                <td>{el.drug_name}</td>
                                                <td>{el.req_qty}</td>
                                                <td>{el.apv_qty}</td>
                                            </tr>
                                        </tbody>
                                    ))}
                                </Table>
                            </Col>
                        </Row>
                    </Form.Group>

                    <Modal.Footer className="borderTop0 pe-0">
                        <Button className="btnCancel" variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
