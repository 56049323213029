/*
 * Author M. Atoar Rahman
 * Title: Partner Stock Loss
 * Description: Page for Partner Stock Loss
 * Date: 15/06/2022
 */

import { useAuth } from '../../contexts/AuthContext';
import { PartnerStockLossProvider } from '../partner/stockLoss/ContextAPI';
import Index from '../partner/stockLoss/Index';
import PermissionDenied from './PermissionDenied';

export default function PartnerStockLoss() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['partner-stock-loss']) return <PermissionDenied />;

    return (
        <PartnerStockLossProvider>
            <Index />
        </PartnerStockLossProvider>
    );
}
