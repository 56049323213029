/*
 * Author M. Atoar Rahman
 * Date: 15/03/2022
 * Time: 11:40 AM
 */

import React from 'react';
import { Card } from 'react-bootstrap';
import { useAuth } from '../../../contexts/AuthContext';
import NotFound from '../../pages/NotFound';
import FormInfo from './FormInfo';

export default function Index() {
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['permission-management/role-permission']) return <NotFound />;
    return (
        <Card>
            <Card.Header className="cardHeader">
                <h3>Menu Permission</h3>
            </Card.Header>
            <Card.Body>
                <FormInfo />
            </Card.Body>
        </Card>
    );
}
