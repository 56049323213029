/*
 * Author M. Atoar Rahman
 * Title: Dispensary Internal Request Approve
 * Description: Index for Dispensary Internal Request Approve
 * Date: 12/06/2022
 */

import { Button, Card } from 'react-bootstrap';
import { useInternalRequest } from './ContextAPI';
import DataTable from './DataTable';
import FormInfo from './FormInfo';

export default function Index() {
    const { show, setShow } = useInternalRequest();

    const backHander = () => {
        setShow(false);
    };
    return (
        <Card>
            <Card.Header className="cardHeader">
                <h3>Dispensary Internal Request Approve</h3>
                {show && (
                    <div>
                        <Button onClick={() => backHander()} className="btnInfo" variant="primary">
                            Back to List
                        </Button>
                    </div>
                )}
            </Card.Header>
            <Card.Body>{show ? <FormInfo /> : <DataTable />}</Card.Body>
        </Card>
    );
}
