/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Dispensary Stock Loss
 * Description: Dispensary Stock Loss Form: Create and Update
 * Date: 26/04/2022
 */

import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import CommonApiService from '../../../services/CommonApiService';
import UrlService from '../../../services/UrlService';
import { useStockLoss } from './ContextAPI';

export default function FormInfo({ editData = null }) {
    const { setShow, addStockLoss, editStockLoss } = useStockLoss();
    const axiosPrivate = useAxiosPrivate();
    // Local State
    const [drugData, setDrugData] = useState([]);
    const [itemDetails, setItemDetails] = useState([]);
    const [lossReasonData, setLossReasonData] = useState();
    const [batchNoData, setBatchNoData] = useState();
    const [isSubmitted, setIsSubmitted] = useState(false);

    const { drugInfo, getDrugInfo } = CommonApiService();

    const {
        register,
        formState: { errors },
        reset,
        handleSubmit,
        control,
        setValue,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
        defaultValues: useMemo(
            () => ({
                ...editData,
                date: editData && new Date(editData.date),
            }),
            [editData]
        ),
    });

    // Reset default values
    useEffect(() => {
        reset({
            ...editData,
            date: editData && new Date(editData.date),
        });
    }, [editData]);

    // Item Details
    const {
        register: register2,
        formState: { errors: errors2 },
        handleSubmit: handleSubmit2,
        reset: reset2,
        control: control2,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
    });

    // Set default values
    useEffect(() => {
        setValue('itemDetails', itemDetails);
    }, [itemDetails]);

    const dataList = useCallback(() => {
        getDrugInfo(); // DrugInfo
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList, editData]);

    useEffect(() => {
        // DrugInfo
        setDrugData(
            drugInfo?.map((el) => ({
                value: el.drug_id,
                label: el.drug_name,
            }))
        );
    }, [drugInfo]);

    // Item Details: Add
    const itemDetailsAppendHandler = (data) => {
        const detailFormData = {
            ...data,
            drug_id: data.drug_id.value,
            batch_no: data.batch_no.value,
            loss_reason: data.loss_reason.value,
            item: { value: data.drug_id.value, label: data.drug_id.label },
            batchno: { value: data.batch_no.value, label: data.batch_no.label },
            lossreason: { value: data.loss_reason.value, label: data.loss_reason.label },
        };
        setItemDetails([...itemDetails, detailFormData]);
        reset2({
            drug_id: '',
            batch_no: '',
            loss_qty: '',
            loss_reason: '',
            loss_remark: '',
        });
    };
    // Item Details: Remove
    const itemDetailsRemoveHandler = (drug_id) => {
        const updatedItemDetails = itemDetails && itemDetails.filter((m) => m.drug_id !== drug_id);
        setItemDetails(updatedItemDetails);
    };

    // batchNo: Fatch All Data
    const batchNo = async (id) => {
        try {
            const response = await axiosPrivate.post(
                UrlService.batchNoListUtilityUrl(),
                { item_id: id },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            setBatchNoData(
                response.data.batch_no_list.map((el) => ({
                    value: el.batch_no,
                    label: el.batch_no,
                }))
            );
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    // lossReason: Fatch All Data
    const lossReason = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.lossReasonUtilityUrl(), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setLossReasonData(
                response.data.loss_reason_list.map((el) => ({
                    value: el.id,
                    label: el.title,
                }))
            );
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    useEffect(() => {
        lossReason();
    }, []);

    register('itemDetails', { required: 'Item details is required!' });

    // Form Submit Handelar
    const formSubmitHandler = (data) => {
        setIsSubmitted(true);

        const formData = {
            ...data,
            date: data.date.toLocaleDateString('sv-SE'),
            status: data.status === true ? 1 : 0,
        };
        if (editData) {
            editStockLoss(formData, editData.id); // Update Data
        } else {
            addStockLoss(formData); // Create Data
        }
        setShow(false);
        reset();
    };

    return (
        <Form>
            <fieldset style={{ background: '#f3f3f3' }} className="customFieldset p-3 pb-0 mb-3">
                <Row>
                    <Col lg={6}>
                        <Form.Group className="mb-3">
                            <Row>
                                <Col lg={3}>
                                    <Form.Label>
                                        Date<span className="required">*</span>
                                    </Form.Label>
                                </Col>
                                <Col lg={9}>
                                    <Controller
                                        control={control}
                                        name="date"
                                        render={({ field }) => (
                                            <DatePicker
                                                {...field}
                                                closeOnScroll
                                                placeholderText="Select date"
                                                dateFormat="dd-MM-yyyy"
                                                onChange={(date) => field.onChange(date)}
                                                selected={field.value}
                                                className="form-control"
                                                popperPlacement="top-end"
                                                minDate={new Date()}
                                            />
                                        )}
                                        rules={{
                                            required: 'Please select your date.',
                                            valueAsDate: true,
                                        }}
                                    />
                                    {errors.date && (
                                        <span className="invalid">{errors.date?.message}</span>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>
            </fieldset>
            <Row className="mb-3">
                <Col lg={11}>
                    <Row>
                        <Col lg={6} className="pe-0">
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Item Name<span className="required">*</span>
                                </Form.Label>
                                <Controller
                                    name="drug_id"
                                    id="drug_id"
                                    control={control2}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            classNamePrefix={errors2?.drug_id && 'invalidInput'}
                                            options={drugData}
                                            onBlur={() => batchNo(field.value)}
                                        />
                                    )}
                                    rules={{
                                        required: 'Please select drug item.',
                                    }}
                                />
                                {errors2.drug_id && (
                                    <span className="invalid">{errors2.drug_id?.message}</span>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="pe-0">
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Batch No<span className="required">*</span>
                                </Form.Label>
                                <Controller
                                    name="batch_no"
                                    id="batch_no"
                                    control={control2}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            classNamePrefix={errors2?.batch_no && 'invalidInput'}
                                            options={batchNoData}
                                        />
                                    )}
                                    rules={{
                                        required: 'Please select drug item.',
                                    }}
                                />
                                {errors2.batch_no && (
                                    <span className="invalid">{errors2.batch_no?.message}</span>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="pe-0">
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Loss Type<span className="required">*</span>
                                </Form.Label>
                                <Controller
                                    name="loss_reason"
                                    id="loss_reason"
                                    control={control2}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            classNamePrefix={errors2?.loss_reason && 'invalidInput'}
                                            options={lossReasonData}
                                        />
                                    )}
                                    rules={{
                                        required: 'Please select drug item.',
                                    }}
                                />
                                {errors2.loss_reason && (
                                    <span className="invalid">{errors2.loss_reason?.message}</span>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={2} className="pe-0">
                            <Form.Group>
                                <Form.Label>
                                    Loss Qty<span className="required">*</span>
                                </Form.Label>
                                <Form.Control
                                    name="loss_qty"
                                    id="loss_qty"
                                    type="number"
                                    placeholder=""
                                    autoComplete="off"
                                    {...register2('loss_qty', {
                                        required: 'Field is required.',
                                    })}
                                    className={errors2?.loss_qty && 'invalidInput'}
                                />
                                {errors2.loss_qty && (
                                    <span className="invalid">{errors2.loss_qty?.message}</span>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={10} className="pe-0">
                            <Form.Group>
                                <Form.Label>Remark</Form.Label>
                                <Form.Control
                                    name="loss_remark"
                                    id="loss_remark"
                                    type="text"
                                    placeholder=""
                                    autoComplete="off"
                                    {...register2('loss_remark', {
                                        // required: 'Please enter your loss_remark.',
                                    })}
                                    className={errors2?.loss_remark && 'invalidInput'}
                                />
                                {errors2.loss_remark && (
                                    <span className="invalid">{errors2.loss_remark?.message}</span>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
                <Col lg={1} className="pt-3">
                    <Button
                        className="btnSteelBlue h-100"
                        variant="primary"
                        onClick={handleSubmit2(itemDetailsAppendHandler)}
                    >
                        Add
                    </Button>
                </Col>
            </Row>
            <Row>
                {errors.itemDetails && (
                    <span className="invalid">{errors.itemDetails?.message}</span>
                )}
                <Col lg={12}>
                    <Table className="borderDashed" responsive="sm">
                        <tbody>
                            {itemDetails.map((item) => (
                                <tr key={Math.random()}>
                                    <td>{item.item?.label}</td>
                                    <td>{item.batchno?.label}</td>
                                    <td>{item.lossreason?.label}</td>
                                    <td>{item.loss_qty}</td>
                                    <td>{item.loss_remark}</td>
                                    <td className="text-end">
                                        <Button
                                            className="btn btn-light actionBtn"
                                            variant="primary"
                                            onClick={() => itemDetailsRemoveHandler(item.drug_id)}
                                        >
                                            <i className="fas fa-trash-alt" />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Form.Group className="mb-3">
                    <Row className="text-start">
                        <Col lg={12}>
                            <Button
                                onClick={handleSubmit(formSubmitHandler)}
                                className="btnSuccess"
                                type="button"
                                variant="success"
                                disabled={isSubmitted}
                            >
                                {editData ? 'Update' : 'Submit'}
                            </Button>
                        </Col>
                    </Row>
                </Form.Group>
            </Row>
        </Form>
    );
}
