/*
 * Author M. Atoar Rahman
 * Title: Therapeutic Action
 * Description: Page for Therapeutic Action
 * Date: 15/03/2022
 */

import React from 'react';
import { Card } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';
import { TherapeuticProvider } from '../masterSetup/therapeuticAction/ContextAPI';
import DataTable from '../masterSetup/therapeuticAction/DataTable';
import FormInfo from '../masterSetup/therapeuticAction/FormInfo';
import PermissionDenied from './PermissionDenied';

export default function TherapeuticAction() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['therapeutic-action']) return <PermissionDenied />;

    return (
        <TherapeuticProvider>
            <Card>
                <Card.Header className="cardHeader">
                    <h3>Therapeutic Action Class</h3>
                    <div>
                        <FormInfo />
                    </div>
                </Card.Header>
                <Card.Body>
                    <DataTable />
                </Card.Body>
            </Card>
        </TherapeuticProvider>
    );
}
