/*
 * Author M. Atoar Rahman
 * Title: Axios
 * Description: Set Axios Base URL and Create Axios
 * Date: 15/03/2022
 */

import axios from 'axios';
import UrlService from '../services/UrlService';

const BASE_URL = UrlService.baseUrl();

export default axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    credentials: true,
});
