/*
 * Author M. Atoar Rahman
 * Title: D4 Report
 * Description: Index for D4 Report
 * Date: 05/06/2022
 */

import { Card } from 'react-bootstrap';
import { useD4Report } from './ContextAPI';
import FormInfo from './FormInfo';
import ShowModel from './ShowModel';

export default function Index() {
    const { show } = useD4Report();

    return show ? (
        <Card>
            <Card.Body>
                <ShowModel />
            </Card.Body>
        </Card>
    ) : (
        <FormInfo />
    );
}
