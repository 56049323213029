import React from 'react';
import Barcode from 'react-barcode';
import { Col, Row, Table } from 'react-bootstrap';
import { useApproval } from '../ContextAPI';

function ComponentToPrint(props, ref) {
    const { pdfData } = useApproval();
    console.log({ pdfData });
    return (
        <fieldset ref={ref} className="customFieldset p-5">
            <Row className="mb-4">
                <Col className="text-center">
                    <h3>WAYBILL</h3>
                    <h5>
                        {pdfData?.delivery_details && pdfData?.delivery_details?.warehouse_name}
                    </h5>
                </Col>
            </Row>
            <Row>
                <Table bordered>
                    <tbody>
                        <tr>
                            <td>
                                <h6>WAYBILL NUMBER:</h6>
                            </td>
                            <td>
                                <Barcode
                                    height="30"
                                    displayValue
                                    value={pdfData && pdfData?.waybill_number}
                                    format="CODE128"
                                />
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Row>
            <Row>
                <Table bordered>
                    <tbody>
                        <tr>
                            <td width="25%">Consigner</td>
                            <td width="25%">
                                {pdfData?.delivery_details &&
                                    pdfData?.delivery_details?.warehouse_name}
                            </td>
                            <td width="25%">Date</td>
                            <td width="25%">{pdfData?.waybill_date}</td>
                        </tr>
                        <tr>
                            <td width="25%">Consigner Address</td>
                            <td colSpan={3}>
                                {pdfData?.delivery_details &&
                                    pdfData?.delivery_details?.warehouse_address}
                            </td>
                        </tr>
                        <tr>
                            <td width="25%">Consignee</td>
                            <td colSpan={3}>
                                {pdfData?.delivery_details &&
                                    pdfData?.delivery_details?.partner_name}
                            </td>
                        </tr>
                        <tr>
                            <td width="25%">Delivery Address</td>
                            <td colSpan={3}>
                                {pdfData?.delivery_details &&
                                    pdfData?.delivery_details?.partner_address}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Row>
            <Row>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>SL</th>
                            <th>Item Description</th>
                            <th>Barcode</th>
                            <th>Batch No.</th>
                            <th>No. of Units</th>
                            <th>Reject Reason(s)/Remarks</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pdfData?.waybill_details?.map((el, index) => (
                            <tr key={Math.random()}>
                                <td>{index + 1}</td>
                                <td>{el.drug_name}</td>
                                <td>
                                    <Barcode
                                        width="1"
                                        height="20"
                                        displayValue={false}
                                        value={el.code_no}
                                        format="CODE128"
                                    />
                                </td>
                                <td>{el.batch_no}</td>
                                <td>{el.supplied_qty}</td>
                                <td />
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Row>
            <Row>
                <Table bordered>
                    <tbody>
                        <tr>
                            <td width="20%">Store Keeper</td>
                            <td width="30%">{pdfData?.initiated_by && pdfData?.initiated_by}</td>
                            <td width="20%">Supervisor</td>
                            <td width="30%">{pdfData?.approved_by && pdfData?.approved_by}</td>
                        </tr>
                        <tr>
                            <td>Consignee</td>
                            <td colSpan={4} style={{ height: '80px' }}>
                                <span
                                    style={{ fontSize: '9px', display: 'flex', paddingTop: '50px' }}
                                >
                                    Signature of Consignee
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td width="20%">Loading Started</td>
                            <td width="30%" />
                            <td width="20%">Uploading Started</td>
                            <td width="30%" />
                        </tr>
                        <tr>
                            <td width="20%">Loading Finished</td>
                            <td width="30%" />
                            <td width="20%">Uploading Finished</td>
                            <td width="30%" />
                        </tr>

                        <tr>
                            <td colSpan={4}>
                                NOTE: The consignee must check the quantity delivered and note any
                                loss of darlage. Ary losses OI danrage must be noted on th is form.
                                The consignee must sign all three copies of this fom. If th IS
                                document 1S cancelled,please mark appropriately.
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Row>
        </fieldset>
    );
}

const forwardedInput = React.forwardRef(ComponentToPrint);

export default forwardedInput;
