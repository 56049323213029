/*
 * Author M. Atoar Rahman
 * Title: Center Stock In
 * Description: Page for Center Stock In
 * Date: 15/03/2022
 */

import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { CenterStockInProvider } from '../centerWarehouse/centerStockIn/ContextAPI';
import Index from '../centerWarehouse/centerStockIn/Index';
import { ManufacturerProvider } from '../masterSetup/manufacturer/ContextAPI';
import { SupplierProvider } from '../masterSetup/supplier/ContextAPI';
import PermissionDenied from './PermissionDenied';

export default function CenterStockIn() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['center-stock-in']) return <PermissionDenied />;

    return (
        <CenterStockInProvider>
            <ManufacturerProvider>
                <SupplierProvider>
                    <Index />
                </SupplierProvider>
            </ManufacturerProvider>
        </CenterStockInProvider>
    );
}
