/*
 * Author M. Atoar Rahman
 * Title: Current Stock
 * Description: Current Stock
 * Date: 13/07/2022
 */

import useDataTable from '../../../hooks/useDataTable';

export default function DataTable({ data }) {
    const tableStyle = {
        paddingLeft: '0px',
        paddingRight: '0px',
        lineHeight: '33px',
    };

    function ItemTitle() {
        return <span className="ps-2">Item Name</span>;
    }

    function Title() {
        return (
            <table className="table table-bordered mb-0 text-center" style={tableStyle}>
                <thead>
                    <tr style={{ borderTopWidth: '0px', borderBottomWidth: '0px' }}>
                        <th style={{ lineHeight: '34px' }} width="150px" className="m-0">
                            Batch No
                        </th>
                        <th width="150px" className="m-0">
                            Expired Date
                        </th>
                        <th width="150px" className="m-0">
                            Available Qty
                        </th>
                    </tr>
                </thead>
            </table>
        );
    }

    const columns = [
        {
            name: <ItemTitle />,
            cell: (row) => <span className="ps-2">{row.drug_name}</span>,
            sortable: true,
            wrap: true,
            width: '300px',
            fixed: true,
            style: { borderBottom: '1px solid #ddd' },
        },
        {
            name: <Title />,
            cell: (row) => (
                <table className="table table-bordered mb-0 text-center" style={tableStyle}>
                    <tbody>
                        {row?.batch_info?.map((item) => (
                            <tr key={Math.random() * 1000} style={{ borderTopWidth: '0px' }}>
                                <td width="150px">{item.batch_no}</td>
                                <td width="150px">{item.expire_date}</td>
                                <td width="150px">{item.available_qty}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ),
            style: { padding: 0 },
        },
    ];

    return useDataTable({ columns, data, pagination: false });
}
