/* eslint-disable no-return-assign */
/* eslint-disable no-unused-expressions */
/*
 * Author M. Atoar Rahman
 * Title: Persist Login
 * Description: Persist Login
 * Date: 15/03/2022
 */

import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import useRefreshToken from '../hooks/useRefreshToken';

function PersistLogin() {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const { auth, persist } = useAuth();

    useEffect(() => {
        let isMounted = true;

        const verifyRefreshToken = async () => {
            try {
                await refresh();
            } catch (err) {
                console.error(err);
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        // Avoids unwanted call to verifyRefreshToken
        !auth?.accessToken && persist ? verifyRefreshToken() : setIsLoading(false);

        return () => (isMounted = false);
    }, [auth?.accessToken, refresh, persist]);

    return !persist ? <Outlet /> : isLoading ? null : <Outlet />;
}

export default PersistLogin;
