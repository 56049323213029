/* eslint-disable react-hooks/exhaustive-deps */
import useDataTable from '../../../hooks/useDataTable';

export default function ShowTable({ data }) {
    const columns = [
        {
            name: 'Item Name',
            selector: (row) => row.drug_name,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Available Quantity',
            selector: (row) => row.available_stock,
            sortable: true,
        },
        {
            name: 'Distribution Quantity',
            selector: (row) => row.supplied_qty,
            sortable: true,
        },
    ];

    const search_column = {
        drug_name: 'Name',
    };
    return useDataTable({ columns, data, search_column, pagination: false });
}
