/*
 * Author M. Atoar Rahman
 * Title: Not Found
 * Description: Page for Not Found
 * Date: 15/03/2022
 */

import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import logoWhite from '../../assets/unhcr-logo-white.png';
import styles from '../../styles/NotFound.module.css';

export default function NotFound() {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);
    return (
        <div className={styles.notFound}>
            <div className={styles.logo}>
                <img src={logoWhite} alt="" height={50} />
            </div>

            <Form>
                <h4 style={{ color: 'red', textShadow: '1px 1px 5px #fff' }} className="mt-4">
                    This page is not found!
                </h4>
                <NavLink exact="true" to="/login">
                    <Button
                        className={styles.btnGroup}
                        variant="primary"
                        type="submit"
                        onClick={goBack}
                    >
                        Go Back
                    </Button>
                </NavLink>
            </Form>
        </div>
    );
}
