/*
 * Author M. Atoar Rahman
 * Title: Dispensary Internal Approve
 * Description: Page for Dispensary Internal Approve
 * Date: 28/04/2022
 */

import { useAuth } from '../../contexts/AuthContext';
import { DispensaryInternalRequestApproveProvider } from '../dispensary/internalRequestApprove/ContextAPI';
import Index from '../dispensary/internalRequestApprove/Index';
import PermissionDenied from './PermissionDenied';

export default function DispensaryInternalRequestApprove() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['dispensary-internal-request-approval']) return <PermissionDenied />;

    return (
        <DispensaryInternalRequestApproveProvider>
            <Index />
        </DispensaryInternalRequestApproveProvider>
    );
}
