/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Modal, Row } from 'react-bootstrap';
import { useItemDispatch } from './ContextAPI';
import ShowTable from './ShowTable';

export default function ShowModel() {
    const { showModal, setShowModal, rowData } = useItemDispatch();

    console.log({ rowData });

    // Closing the Model
    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <Modal className="modalWidth80" show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Dispensary Item Dispatch Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col lg={6}>
                        <h7>
                            <b>Department Name: </b> {rowData.dispensary_name}
                        </h7>
                    </Col>
                    <Col>
                        <h7>
                            <b>Date: </b> {rowData.dispatch_date}
                        </h7>
                    </Col>
                    <Col>
                        <h7>
                            <b>Patient SL.No: </b> {rowData.serial_no}
                        </h7>
                    </Col>
                </Row>
                <ShowTable data={rowData.dispatch_item_info} />
            </Modal.Body>
        </Modal>
    );
}
