/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-constructed-context-values */
/*
 * Author M. Atoar Rahman
 * Title: Partner Stock Distribution Approval Context
 * Description: Partner Stock Distribution Approval Context
 * Date: 27/06/2022
 */

import { createContext, useContext, useState } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';

// Create Context
const ContextAPI = createContext();

export function useApproval() {
    return useContext(ContextAPI);
}

// Provider Component
export function PartnerStockDistributionApprovalProvider({ children }) {
    const [show, setShow] = useState(false);
    const [rowData, setRowData] = useState({});
    const [pendingData, setPendingData] = useState();
    const [approvedData, setApprovedData] = useState();
    const [receiveData, setReceiveData] = useState({});
    const [receiveList, setReceiveList] = useState({});
    const [showPdf, setShowPdf] = useState(false);
    const [pdfData, setPdfData] = useState();

    // Dependency
    const axiosPrivate = useAxiosPrivate();

    // API Function: Fatch All Data  All D2 Data
    const getPendingData = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getAllD1Url('d1_supplied', 1), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setPendingData(response?.data?.d1_preparation_info);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    const getApprovedData = async () => {
        try {
            const response = await axiosPrivate.get(
                UrlService.getAllD1Url('d1_supply_approved', 1),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            setApprovedData(response?.data?.d1_preparation_info);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    // API Function: Add Approval
    const addPartnerStockDistributionApproval = async (data) => {
        try {
            await axiosPrivate.post(UrlService.approvalPartnerStockOut(), JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setShow(false);
            getPendingData();
            getApprovedData();
            ToastifyService.updateNotify('Approve Successfull');
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Insert Failed!');
            }
        }
    };

    // API Function: Fatch All PDF Data
    const pdfHandler = async (master_id) => {
        const form = 'partner';
        try {
            const response = await axiosPrivate.get(
                UrlService.waybillPartnerDistributionUrl(form, master_id),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            setPdfData(response?.data?.waybill_info);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    return (
        <ContextAPI.Provider
            value={{
                show,
                setShow,
                rowData,
                setRowData,
                pendingData,
                getPendingData,
                approvedData,
                getApprovedData,
                addPartnerStockDistributionApproval,
                receiveList,
                setReceiveList,
                receiveData,
                setReceiveData,
                showPdf,
                setShowPdf,
                pdfData,
                pdfHandler,
            }}
        >
            {children}
        </ContextAPI.Provider>
    );
}

export default ContextAPI;
