/* eslint-disable eqeqeq */
/*
 * Author M. Atoar Rahman
 * Title: Login
 * Description: Page for Login Form
 * Date: 15/03/2022
 */

import cookie from 'js-cookie';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import axios from '../../api/axios';
import refresh from '../../assets/refresh.svg';
import scratch from '../../assets/scratch.png';
import logoWhite from '../../assets/unhcr-logo-white.png';
import { useAuth } from '../../contexts/AuthContext';
import UrlService from '../../services/UrlService';
import styles from '../../styles/LoginForm.module.css';

const LOGIN_URL = UrlService.loginUrl();

// In Progress

export default function LoginForm() {
    const { persist } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || '/';

    const userRef = useRef();

    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [errMsg2, setErrMsg2] = useState('');
    const [fristNumber, setFristNumber] = useState(Math.floor(Math.random() * 20) + 1);
    const [secondNumber, setSecondNumber] = useState(Math.floor(Math.random() * 20) + 1);
    const [result, setResult] = useState();
    const [captchaResult, setCaptchaResult] = useState();
    const [isSubmit, setIsSubmit] = useState(false);

    const captchaNum = useCallback(() => {
        setCaptchaResult('');
        setErrMsg2('');
        setFristNumber(Math.floor(Math.random() * 20) + 1);
        setSecondNumber(Math.floor(Math.random() * 20) + 1);
    }, []);

    useEffect(() => {
        setResult(fristNumber + secondNumber);
    }, [fristNumber, secondNumber, captchaNum]);

    useEffect(() => {
        userRef.current.focus();
    }, []);

    useEffect(() => {
        setErrMsg('');
    }, [user, password]);

    // debounce handler
    const debounce = (fn) => {
        let timer;
        // eslint-disable-next-line func-names
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                fn.apply(context, args);
            }, 800);
        };
    };

    const handleChange = (val, res) => {
        if (res != val) {
            setErrMsg2('Wrong captcha!');
            setIsSubmit(false);
        }
        if (res == val) {
            setIsSubmit(true);
            setErrMsg2('');
        }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleCaptcha = useCallback(debounce(handleChange), []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(
                LOGIN_URL,
                JSON.stringify({ email: user, password }),
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            );
            // console.log(JSON.stringify(response?.data.data));

            const accessToken = response?.data?.data.access_token;
            // const roles = response?.data?.data.role_info.map((el) => el.role_id);

            // setAuth({ user, roles, accessToken });
            cookie.set('token', accessToken);

            setUser('');
            setPassword('');
            navigate(from, { replace: true });
        } catch (err) {
            if (!err?.response) {
                setErrMsg('Username or Password Not Found!');
            } else if (err.response?.status === 400) {
                setErrMsg('Username or Password Not Found!');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized!');
            } else {
                setErrMsg('Login Failed!');
            }
        }
    };

    // const togglePersist = () => {
    //     setPersist((prev) => !prev);
    // };

    useEffect(() => {
        localStorage.setItem('persist', persist);
    }, [persist]);

    return (
        <div className={styles.login}>
            <div className={styles.logo}>
                <img src={logoWhite} alt="" height={50} />
            </div>

            <Form onSubmit={handleSubmit}>
                <h4>Sign In To PMS</h4>
                <span>Enter your details to login to your account</span>
                <Form.Group className="mb-3 formGroup">
                    <Form.Control
                        required
                        type="email"
                        placeholder="Enter email"
                        id="username"
                        ref={userRef}
                        autoComplete="off"
                        onChange={(e) => setUser(e.target.value)}
                        value={user}
                    />
                </Form.Group>

                <Form.Group className="mb-3 formGroup">
                    <Form.Control
                        required
                        type="password"
                        id="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Form.Group>
                {/* <Row className="mb-3">
                    <Col lg={6}>
                        <Form.Check
                            type="switch"
                            className="persist"
                            onChange={togglePersist}
                            checked={persist}
                            label="Remember me"
                        />
                    </Col>
                    <Col lg={6}>
                    <NavLink exact="true" to="/forget-password" className={styles.forgetPassword}>
                        Forget Password
                    </NavLink>
                    </Col>
                </Row> */}

                {errMsg2 && (
                    <p
                        style={{
                            textAlign: 'right',
                            marginBottom: '2px',
                            color: 'red',
                        }}
                    >
                        {errMsg2}
                    </p>
                )}

                <div style={{ width: '100%', textAlign: 'left' }}>
                    <h6
                        style={{
                            textAlign: 'left',
                            background: `url(${scratch})`,
                            backgroundSize: 'cover',
                            display: 'inline-block',
                            padding: '0px 15px',
                            width: '40%',
                            float: 'left',
                            marginTop: '3px',
                            fontSize: '30px',
                            fontWeight: 'bold',
                            fontFamily: 'Blaka Ink, cursive',
                        }}
                    >
                        {fristNumber} + {secondNumber} = ?
                    </h6>
                    <Button
                        type="button"
                        onClick={() => captchaNum()}
                        variant="light"
                        style={{
                            display: 'inline-block',
                            width: '10%',
                            float: 'left',
                            lineHeight: '24px',
                            marginTop: '3px',
                            marginLeft: '0px',
                            borderRadius: '0',
                            background: 'rgb(225 211 211)',
                            padding: '5px',
                        }}
                    >
                        <img src={refresh} alt="" />
                    </Button>

                    <Form.Group className="mb-3 formGroup">
                        <Form.Control
                            type="text"
                            placeholder="Result"
                            style={{ width: '48%', marginTop: '1px', float: 'right' }}
                            autoComplete="off"
                            onChange={(e) => {
                                setCaptchaResult(e.target.value);
                                handleCaptcha(Number(e.target.value), result);
                            }}
                            value={captchaResult}
                        />
                    </Form.Group>
                </div>

                <Button
                    type="submit"
                    disabled={!!(!user || !password || !isSubmit)}
                    className="w-100 mt-2"
                    variant="primary"
                >
                    Login
                </Button>

                <p style={{ textAlign: 'center', marginTop: '15px', marginBottom: '0px' }}>
                    <NavLink exact="true" to="/forget-password" className={styles.forgetPassword}>
                        Forget Password
                    </NavLink>
                </p>
                {errMsg && <p className="error mt-3 mb-0">{errMsg}</p>}
            </Form>

            <p>Developed &amp; Maintenance By Nanosoft</p>
        </div>
    );
}
