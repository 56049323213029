/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-constructed-context-values */
/*
 * Author M. Atoar Rahman
 * Title: D2 Partner Approval Context
 * Description: D2 Partner Approval Context
 * Date: 20/06/2022
 */

import { createContext, useContext, useReducer, useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';

// Initial State
const initialState = {
    d2Approval: [],
};

// Reducer Function
const reducer = (state, action) => {
    switch (action.type) {
        case 'LIST_DATA':
            return {
                ...state,
                d2Approval: action.payload,
            };
        default:
            return state;
    }
};

// Create Context
const ContextAPI = createContext(initialState);

export function useD2Approval() {
    return useContext(ContextAPI);
}

// Provider Component
export function D2ApprovalProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [show, setShow] = useState(false);
    const [rowData, setRowData] = useState({});
    const [receiveData, setReceiveData] = useState({});
    const [receiveList, setReceiveList] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    const { auth } = useAuth();
    const partnerId = auth.employee_info?.partner_id;

    // Dependency
    const axiosPrivate = useAxiosPrivate();

    // API Function: Fatch All Data
    const getAllData = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getAllD2FormUrl(partnerId), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            dispatch({
                type: 'LIST_DATA',
                payload: response?.data?.d2_preparation_info,
            });
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    // API Function: Add Approval
    const addD2Approval = async (data) => {
        try {
            await axiosPrivate.post(UrlService.D2CheckedUrl(), JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setShow(false);
            getAllData();
            ToastifyService.updateNotify('Approve Successfull');
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Insert Failed!');
            }
        }
    };

    return (
        <ContextAPI.Provider
            value={{
                d2Approval: state.d2Approval,
                getAllData,
                addD2Approval,
                show,
                setShow,
                rowData,
                setRowData,
                receiveData,
                setReceiveData,
                receiveList,
                setReceiveList,
                isSubmitted,
                setIsSubmitted,
            }}
        >
            {children}
        </ContextAPI.Provider>
    );
}

export default ContextAPI;
