/* eslint-disable eqeqeq */
/*
 * Author M. Atoar Rahman
 * Title: D4 Report DataTable
 * Description: D4 Report DataTable
 * Date: 15/03/2022
 */

import { isEmpty } from 'lodash';
import { Fragment } from 'react';
import useDataTable from '../../../hooks/useDataTable';

export default function DataTable({ medicinData, partner, dateOrMonthRange }) {
    const tableStyle = {
        paddingLeft: '5px',
        paddingRight: '5px',
        lineHeight: '33px',
    };

    console.log({ medicinData });

    function ItemTitle() {
        return <span className="ps-2">Item Name</span>;
    }

    const partnerLength = partner?.length;

    function Title() {
        return (
            <table className="table table-bordered mb-0 text-center" style={tableStyle}>
                <thead>
                    <tr>
                        <th colSpan={partnerLength * 2} className="m-0">
                            {dateOrMonthRange}
                        </th>
                    </tr>
                    <tr>
                        {partner?.map((el) => (
                            <th
                                style={{ minWidth: '110px', maxWidth: '110px' }}
                                key={Math.random() * 1000}
                                className="m-0"
                            >
                                {el.name}
                            </th>
                        ))}
                    </tr>
                </thead>
            </table>
        );
    }

    const columns = [
        {
            name: <ItemTitle />,
            cell: (row) => <span className="ps-2">{row.drug_name}</span>,
            sortable: true,
            wrap: true,
            width: '250px',
            fixed: true,
        },
        {
            name: 'Opening Balance',
            selector: (row) => row.opening_balance,
            width: '110px',
            sortable: true,
        },
        {
            name: <Title />,
            cell: (row) => (
                <table className="table table-bordered mb-0 text-center" style={tableStyle}>
                    <tbody>
                        <tr style={{ borderTopWidth: '0px' }}>
                            {partner?.map((ele) => {
                                if (isEmpty(row.partner_summary_data)) {
                                    return (
                                        <Fragment key={Math.random() * 1000}>
                                            <td style={{ minWidth: '110px', maxWidth: '110px' }}>
                                                0
                                            </td>
                                        </Fragment>
                                    );
                                }
                                return row?.partner_summary_data?.map((item) =>
                                    ele.id == item.partner_id ? (
                                        <Fragment key={Math.random() * 1000}>
                                            <td style={{ minWidth: '110px', maxWidth: '110px' }}>
                                                {item.total_dispatch}
                                            </td>
                                        </Fragment>
                                    ) : (
                                        <Fragment key={Math.random() * 1000}>
                                            <td style={{ minWidth: '110px', maxWidth: '110px' }}>
                                                0
                                            </td>
                                        </Fragment>
                                    )
                                );
                            })}
                        </tr>
                    </tbody>
                </table>
            ),
        },
    ];

    const search_column = { name: 'Name' };
    return useDataTable({
        columns,
        data: medicinData,
        search_column,
        pagination: false,
        cellPadding: '0px',
        borderWidth: '0px',
    });
}
