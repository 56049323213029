import moment from 'moment';
import React, { Fragment } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import logo from '../../../../assets/unhcr-logo-blue.png';
import { useAuth } from '../../../../contexts/AuthContext';
import { useConsumptionReports } from '../ContextAPI';

function ComponentToPrint(props, ref) {
    const { data } = useConsumptionReports();
    const { auth } = useAuth();

    return (
        <fieldset ref={ref} className="customFieldset donation p-5">
            <div className="text-center mb-4">
                <img className="mb-3" width="200px" src={logo} alt="" />
                <br />
                <h5 className="d-block text-decoration-underline">Consumption Reports</h5>
            </div>

            {!auth?.employee_info?.partner_id && !auth?.employee_info?.facility_id && (
                <>
                    <Row>
                        <Col lg={6} />
                        <Col lg={6} className="text-end">
                            <p className="mb-0">
                                <b>Date From:</b>{' '}
                                {data?.start_date
                                    ? moment(data.start_date).format('DD MMM YYYY')
                                    : ''}
                            </p>
                            <p>
                                <b>Date TO:</b>{' '}
                                {data?.end_date ? moment(data.end_date).format('DD MMM YYYY') : ''}
                            </p>
                        </Col>
                    </Row>

                    <Row>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th width="300px">Drug Name</th>
                                    <th style={{ padding: '0px' }}>
                                        <table className="table table-bordered mb-0 text-center">
                                            <tbody>
                                                <tr
                                                    style={{
                                                        borderTop: '0px',
                                                        borderBottom: '0px',
                                                    }}
                                                >
                                                    <td width="250px">Partner</td>
                                                    <td width="150px">Consumption</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </th>
                                    <th width="150px">Total Consumtion</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.drug_list?.map((el) => (
                                    <tr key={Math.random()}>
                                        <td width="300px">{el?.drug_name}</td>
                                        <td style={{ padding: '0px' }}>
                                            <table className="table table-bordered mb-0">
                                                <tbody>
                                                    {el?.partners.map((dispatch) => (
                                                        <tr
                                                            style={{
                                                                borderBottom: '0px',
                                                            }}
                                                        >
                                                            <Fragment key={Math.random() * 1000}>
                                                                <td width="250px">
                                                                    {dispatch?.partner_name}
                                                                </td>
                                                                <td
                                                                    className="text-center"
                                                                    width="150px"
                                                                >
                                                                    {dispatch?.total_qty}
                                                                </td>
                                                            </Fragment>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </td>
                                        <td className="text-center" width="150px">
                                            {el?.total_qty}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Row>
                </>
            )}

            {auth?.employee_info?.partner_id && !auth?.employee_info?.facility_id && (
                <>
                    <Row>
                        <Col lg={6}>
                            <p className="mb-0">
                                <b>Partner:</b> {data.partner_name}
                            </p>
                        </Col>
                        <Col lg={6} className="text-end">
                            <p className="mb-0">
                                <b>Date From:</b>{' '}
                                {data?.start_date
                                    ? moment(data.start_date).format('DD MMM YYYY')
                                    : ''}
                            </p>
                            <p>
                                <b>Date TO:</b>{' '}
                                {data?.end_date ? moment(data.end_date).format('DD MMM YYYY') : ''}
                            </p>
                        </Col>
                    </Row>

                    <Row>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th width="300px">Drug Name</th>
                                    <th style={{ padding: '0px' }}>
                                        <table className="table table-bordered mb-0 text-center">
                                            <tbody>
                                                <tr
                                                    style={{
                                                        borderTop: '0px',
                                                        borderBottom: '0px',
                                                    }}
                                                >
                                                    <td width="250px">Facility</td>
                                                    <td width="150px">Consumption</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </th>
                                    <th width="150px">Total Consumtion</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.drug_list?.map((el) => (
                                    <tr key={Math.random()}>
                                        <td width="300px">{el?.drug_name}</td>
                                        <td style={{ padding: '0px' }}>
                                            <table className="table table-bordered mb-0 text-center">
                                                <tbody>
                                                    {el?.facilities.map((dispatch) => (
                                                        <tr
                                                            style={{
                                                                borderBottom: '0px',
                                                            }}
                                                        >
                                                            <Fragment key={Math.random() * 1000}>
                                                                <td width="250px">
                                                                    {dispatch?.facility_name}
                                                                </td>
                                                                <td
                                                                    className="text-center"
                                                                    width="150px"
                                                                >
                                                                    {dispatch?.total_qty}
                                                                </td>
                                                            </Fragment>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </td>
                                        <td className="text-center" width="150px">
                                            {el?.total_qty}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Row>
                </>
            )}

            {auth?.employee_info?.partner_id && auth?.employee_info?.facility_id && (
                <>
                    <Row>
                        <Col lg={6}>
                            <p className="mb-0">
                                <b>Partner:</b> {data.partner_name}
                            </p>
                            <p>
                                <b>Facility:</b> {data.facility_name}
                            </p>
                        </Col>
                        <Col lg={6} className="text-end">
                            <p className="mb-0">
                                <b>Date From:</b>{' '}
                                {data?.start_date
                                    ? moment(data.start_date).format('DD MMM YYYY')
                                    : ''}
                            </p>
                            <p>
                                <b>Date TO:</b>{' '}
                                {data?.end_date ? moment(data.end_date).format('DD MMM YYYY') : ''}
                            </p>
                        </Col>
                    </Row>

                    <Row>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th width="300px">Drug Name</th>
                                    <th style={{ padding: '0px' }}>
                                        <table className="table table-bordered mb-0 text-center">
                                            <tbody>
                                                <tr
                                                    style={{
                                                        borderTop: '0px',
                                                        borderBottom: '0px',
                                                    }}
                                                >
                                                    <td width="250px">Department</td>
                                                    <td width="150px">Consumption</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </th>
                                    <th width="150px">Total Consumtion</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.drug_list?.map((el) => (
                                    <tr key={Math.random()}>
                                        <td width="300px">{el?.drug_name}</td>
                                        <td style={{ padding: '0px' }}>
                                            <table className="table table-bordered mb-0 text-center">
                                                <tbody>
                                                    {el?.departments.map((dispatch) => (
                                                        <tr
                                                            style={{
                                                                borderBottom: '0px',
                                                            }}
                                                        >
                                                            <Fragment key={Math.random() * 1000}>
                                                                <td width="250px">
                                                                    {dispatch?.department_name}
                                                                </td>
                                                                <td
                                                                    className="text-center"
                                                                    width="150px"
                                                                >
                                                                    {dispatch?.total_qty}
                                                                </td>
                                                            </Fragment>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </td>
                                        <td className="text-center" width="150px">
                                            {el?.total_qty}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Row>
                </>
            )}
        </fieldset>
    );
}

const forwardedInput = React.forwardRef(ComponentToPrint);

export default forwardedInput;
