/*
 * Author M. Atoar Rahman
 * Title: D2 Partner Approval
 * Description: Index for D2 Partner Approval
 * Date: 20/06/2022
 */

import { Card } from 'react-bootstrap';
import DataTable from './DataTable';
import ShowModel from './ShowModel';

export default function Index() {
    return (
        <Card>
            <Card.Header className="cardHeader">
                <h3>D2 Approval</h3>
            </Card.Header>
            <Card.Body>
                <DataTable />
            </Card.Body>
            <ShowModel />
        </Card>
    );
}
