/*
 * Author M. Atoar Rahman
 * Title: Center Stock Loss
 * Description: Page for Center Stock Loss
 * Date: 15/06/2022
 */

import { useAuth } from '../../contexts/AuthContext';
import { CenterStockLossProvider } from '../centerWarehouse/stockLoss/ContextAPI';
import Index from '../centerWarehouse/stockLoss/Index';
import PermissionDenied from './PermissionDenied';

export default function CenterStockLoss() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['center-stock-loss']) return <PermissionDenied />;

    return (
        <CenterStockLossProvider>
            <Index />
        </CenterStockLossProvider>
    );
}
