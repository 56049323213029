/*
 * Author M. Atoar Rahman
 * Title: Hook: useLogout
 * Description: Hook: useLogout
 * Date: 15/03/2022
 */

import cookie from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import UrlService from '../services/UrlService';
import useAxiosPrivate from './useAxiosPrivate';

const LOGOUT_URL = UrlService.logoutUrl();

const useLogout = () => {
    const { setAuth, setPersist } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();

    const logout = async () => {
        setAuth({});
        setPersist(true);
        try {
            await navigate('/login', { replace: true });
            await cookie.remove('token');
            await axiosPrivate.post(LOGOUT_URL, {
                credentials: true,
            });
        } catch (err) {
            console.error(err);
        }
    };

    return logout;
};

export default useLogout;
