/*
 * Author M. Atoar Rahman
 * Title: Warehouse Information
 * Description: Page for Warehouse Information
 * Date: 15/03/2022
 */

import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';
import { DataProvider } from '../centerWarehouse/warehouse/ContextAPI';
import FormInfo from '../centerWarehouse/warehouse/FormInfo';
import List from '../centerWarehouse/warehouse/List';
import PermissionDenied from './PermissionDenied';

export default function WarehouseInfo() {
    const { auth } = useAuth();
    const { route_permissions } = auth;
    const [show, setShow] = useState(false);
    const [rowData, setRowData] = useState('');
    const handleShow = () => {
        setShow(true);
    };
    const hideFunction = (show_status) => {
        setShow(show_status);
        setRowData('');
    };
    const editHander = (row) => {
        setShow(true);
        setRowData(row);
    };

    if (!route_permissions['warehouse-info']) return <PermissionDenied />;
    return (
        <DataProvider>
            <Card>
                <Card.Header className="cardHeader">
                    <h3>Warehouse Info</h3>
                    <div>
                        <Button variant="primary" onClick={handleShow}>
                            Create New
                        </Button>
                        {show && <FormInfo editData={rowData} hideFunction={hideFunction} />}
                    </div>
                </Card.Header>
                <Card.Body>
                    <List editHander={editHander} />
                </Card.Body>
            </Card>
        </DataProvider>
    );
}
