/* eslint-disable react-hooks/exhaustive-deps */
import useDataTable from '../../../hooks/useDataTable';

export default function ShowTable({ data }) {
    const columns = [
        {
            name: 'Item Name',
            selector: (row) => row.drug_name,
            sortable: true,
            wrap: true,
            grow: 3,
        },
        {
            name: 'Purchase Qty',
            selector: (row) => row.purchase_qty,
            center: true,
            sortable: true,
        },
        {
            name: 'Received Qty',
            selector: (row) => row.received_qty,
            center: true,
            sortable: true,
        },
        {
            name: 'Rejected Qty',
            selector: (row) => row.rejected_qty,
            center: true,
            sortable: true,
        },
    ];

    const search_column = {
        drug_name: 'Name',
    };
    return useDataTable({ columns, data, search_column });
}
