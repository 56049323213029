/*
 * Author M. Atoar Rahman
 * Title: Center Current Stock
 * Description: Page for Center Current Stock
 * Date: 13/07/2022
 */

import { useAuth } from '../../contexts/AuthContext';
import { CurrentStockProvider } from '../centerWarehouse/currentStock/ContextAPI';
import Index from '../centerWarehouse/currentStock/Index';
import PermissionDenied from './PermissionDenied';

export default function CenterCurrentStock() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['center-current-stock']) return <PermissionDenied />;

    return (
        <CurrentStockProvider>
            <Index />
        </CurrentStockProvider>
    );
}
