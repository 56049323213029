/*
 * Author M. Atoar Rahman
 * Title: D2 Distribution Approval
 * Description: Index for D2 Distribution Approval
 * Date: 05/06/2022
 */

import { Card, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import ApprovedD2Table from './ApprovedD2Table';
import { useD2Approval } from './ContextAPI';
import PrintIndex from './pdf/PrintIndex';
import PendingD2Table from './PendingD2Table';
import ShowModel from './ShowModel';

export default function Index() {
    const { show, showPdf } = useD2Approval();
    return showPdf ? (
        <PrintIndex />
    ) : (
        <>
            <Card>
                <Card.Header className="cardHeader">
                    <h3>D2 Distribution Approval</h3>
                </Card.Header>
                <Card.Body>
                    <Form>
                        <Row className="requisition_table">
                            <Col>
                                <Tabs
                                    defaultActiveKey="pending"
                                    transition={false}
                                    id="noanim-tab-example"
                                    className="mb-3"
                                >
                                    <Tab eventKey="pending" title="Pending">
                                        <PendingD2Table />
                                    </Tab>
                                    <Tab eventKey="approved" title="Supply Approved">
                                        <ApprovedD2Table />
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
            {show && <ShowModel />}
        </>
    );
}
