/*
 * Author M. Atoar Rahman
 * Title: D1 Form: Medicines Requisition for Facility
 * Description: Index for D1 Form: Medicines Requisition for Facility
 * Date: 30/04/2022
 */

import { Button, Card } from 'react-bootstrap';
import { useD1Form } from './ContextAPI';
import DataTable from './DataTable';
import FormInfo from './FormInfo';
import ShowModel from './ShowModel';

export default function Index() {
    const { show, setShow, setRequisitionList } = useD1Form();
    const createHander = () => {
        setShow(true);
        setRequisitionList([]);
    };
    const backHander = () => {
        setShow(false);
    };
    return (
        <Card>
            <Card.Header className="cardHeader">
                <h3>D1 Form: Medicines Requisition for Facility</h3>
                {show ? (
                    <div>
                        <Button onClick={() => backHander()} className="btnInfo" variant="primary">
                            Back to List
                        </Button>
                    </div>
                ) : (
                    <div>
                        <Button
                            onClick={() => createHander()}
                            className="btnInfo"
                            variant="primary"
                        >
                            Create New
                        </Button>
                    </div>
                )}
            </Card.Header>
            <Card.Body>{show ? <FormInfo /> : <DataTable />}</Card.Body>
            <ShowModel />
        </Card>
    );
}
