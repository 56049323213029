import React from 'react';
import logo from '../../../../assets/unhcr-logo-blue.png';
import DataTable from '../DataTable';

function ComponentToPrint(props, ref) {
    return (
        <fieldset ref={ref} className="customFieldset dataTableHeaderNone p-5">
            <div className="text-center mb-4">
                <img className="mb-3" width="200px" src={logo} alt="" />
                <br />
                <h5 className="d-block text-decoration-underline">Nearly Expired Medicine</h5>
            </div>
            <DataTable />
        </fieldset>
    );
}

const forwardedInput = React.forwardRef(ComponentToPrint);

export default forwardedInput;
