/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import useDataTable from '../../../hooks/useDataTable';

export default function ShowTable({ data }) {
    const columns = [
        {
            name: 'Item Name',
            selector: (row) => row.drug_name,
            sortable: true,
            wrap: true,
            grow: 3,
        },
        {
            name: 'Mfg Date',
            selector: (row) => row.mfg_date,
            format: (row) => moment(row.mfg_date).format('DD MMM YYYY'),
            sortable: true,
        },
        {
            name: 'Exp Date',
            selector: (row) => row.expire_date,
            format: (row) => moment(row.expire_date).format('DD MMM YYYY'),
            sortable: true,
        },
        {
            name: 'Dispatch Qty',
            selector: (row) => row.dispatch_qty,
            sortable: true,
        },
    ];

    const search_column = {
        drug_name: 'Name',
    };
    return useDataTable({ columns, data, search_column });
}
