/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*
 * Author M. Atoar Rahman
 * Title: User Dropdown
 * Description: User Dropdown in Admin Header
 * Date: 15/03/2022
 */

import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import blankImg from '../../../assets/user_01.jpg';
import { useAuth } from '../../../contexts/AuthContext';
import useLogout from '../../../hooks/useLogout';
import UrlService from '../../../services/UrlService';
import { useMenu } from '../menuSidebar/ContextAPI';
import Dropdown from '../menuSidebar/Dropdown';

const PUBLIC_URL = UrlService.publicUrl();

export default function UserDropdown() {
    const { setShow } = useMenu();

    const navigate = useNavigate();
    const logout = useLogout();
    const { auth } = useAuth();

    const signOut = async () => {
        await logout();
        navigate('/login');
    };

    const handelChangePassword = useCallback(() => {
        setShow(false);
        navigate('/change-password');
    }, []);

    return (
        <Dropdown
            className="navUserInfo"
            menuContainerTag="ul"
            buttonTemplate={
                <img
                    src={
                        auth.employee_info?.photo
                            ? `${PUBLIC_URL}/upload/${auth.employee_info?.photo}`
                            : blankImg
                    }
                    alt="User"
                />
            }
            menuTemplate={
                <>
                    <li className="userContent text-center">
                        <img
                            src={
                                auth.employee_info?.photo
                                    ? `${PUBLIC_URL}/upload/${auth.employee_info?.photo}`
                                    : blankImg
                            }
                            alt="User"
                        />
                        <p>
                            {auth.employee_info
                                ? `${auth.employee_info.first_name} ${auth.employee_info.last_name}`
                                : auth.users?.username}
                        </p>
                        <p>{auth.users?.email}</p>
                        <p>{auth.employee_info && auth.employee_info.designation}</p>
                    </li>
                    <li className="d-flex flex-column p-3">
                        <button
                            type="button"
                            onClick={handelChangePassword}
                            className="btn btnSuccess mb-1"
                        >
                            Change Password
                        </button>

                        <button type="button" onClick={signOut} className="btn btnInfo float-right">
                            Signout
                        </button>
                    </li>
                </>
            }
        />
    );
}
