/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-constructed-context-values */
/*
 * Author M. Atoar Rahman
 * Title: Contex API
 * Description: Contex API
 * Date: 15/07/2022
 */

import { createContext, useContext, useState } from 'react';

// Create Context
const ContextAPI = createContext();

export function useStockReturnRequest() {
    return useContext(ContextAPI);
}

// Provider Component
export function StockReturnRequestProvider({ children }) {
    const [show, setShow] = useState(false);
    const [rowData, setRowData] = useState();
    const [returnData, setReturnData] = useState({});
    const [returnList, setReturnList] = useState({});

    return (
        <ContextAPI.Provider
            value={{
                show,
                setShow,
                rowData,
                setRowData,
                returnData,
                setReturnData,
                returnList,
                setReturnList,
            }}
        >
            {children}
        </ContextAPI.Provider>
    );
}

export default ContextAPI;
