import React from 'react';
import { Col, Row } from 'react-bootstrap';
import svg from '../assets/loading.svg';

export default function Loader() {
    return (
        <Row>
            <Col className="text-center">
                <img width={40} src={svg} alt="Loading..." />
            </Col>
        </Row>
    );
}
