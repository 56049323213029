/* eslint-disable no-return-assign */
/* eslint-disable no-unused-expressions */
/*
 * Author M. Atoar Rahman
 * Title: Public Route
 * Description: Public Route
 * Date: 15/03/2022
 */

import cookie from 'js-cookie';
import { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import useRefreshToken from '../hooks/useRefreshToken';

function PublicRoute() {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const { auth, persist } = useAuth();
    const location = useLocation();
    const token = cookie.get('token');

    useEffect(() => {
        let isMounted = true;

        const verifyRefreshToken = async () => {
            try {
                auth || (await refresh());
            } catch (err) {
                console.error(err);
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        // Avoids unwanted call to verifyRefreshToken
        !auth?.accessToken && persist ? verifyRefreshToken() : setIsLoading(false);

        return () => (isMounted = false);
    }, [auth?.accessToken, refresh, persist, auth]);

    return !persist || !token ? (
        <Outlet />
    ) : isLoading ? null : (
        <Navigate to="/" state={{ from: location }} replace />
    );
}

export default PublicRoute;
