/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Pending D2 Table
 * Description: Pending D2 Table
 * Date: 21/06/2022
 */
import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { Badge, Button } from 'react-bootstrap';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useDataTable from '../../../hooks/useDataTable';
import UrlService from '../../../services/UrlService';
import { useD2Distribution } from './ContextAPI';

export default function PendingD2Table() {
    const { setFormShow, setRowData, d2CheckedData, getD2CheckedData } = useD2Distribution();
    const axiosPrivate = useAxiosPrivate();

    const approveHandler = async (row) => {
        try {
            const response = await axiosPrivate.get(UrlService.getCenterD2RecordByIdUrl(row.id), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const row_info = {
                d2_master_id: row.id,
                partner_id: row.partner_id,
                partner_name: row.partner_name,
                central_warehouse_id: row.central_warehouse_id,
                month: row.month,
                year: row.year,
                records: response?.data?.d2_record_list,
            };
            setRowData(row_info);
            setFormShow(true);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    const dataList = useCallback(() => {
        getD2CheckedData();
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    const columns = [
        {
            name: 'Partner',
            selector: (row) => row.partner_name,
            sortable: true,
            grow: 3,
        },
        {
            name: 'Month',
            selector: (row) => row.month,
            sortable: true,
            cell: (row) =>
                row.month == 1
                    ? 'January'
                    : row.month == 2
                    ? 'February'
                    : row.month == 3
                    ? 'March'
                    : row.month == 4
                    ? 'April'
                    : row.month == 5
                    ? 'May'
                    : row.month == 6
                    ? 'June'
                    : row.month == 7
                    ? 'July'
                    : row.month == 8
                    ? 'August'
                    : row.month == 9
                    ? 'September'
                    : row.month == 10
                    ? 'October'
                    : row.month == 11
                    ? 'November'
                    : row.month == 12
                    ? 'December'
                    : '',
        },
        {
            name: 'Year',
            selector: (row) => row.year,
            sortable: true,
        },
        {
            name: 'Submitted Date',
            selector: (row) => row.submitted_date,
            format: (row) => moment(row.submitted_date).format('DD MMM YYYY'),
            sortable: true,
        },
        {
            name: 'Status',
            sortable: true,
            cell: () => (
                <Badge className="badgeInactive" bg="success">
                    Pending
                </Badge>
            ),
        },
        {
            name: 'Action',
            selector: (row) => row.action,
            cell: (row) => (
                <Button
                    onClick={() => approveHandler(row)}
                    className="btnSuccess"
                    type="button"
                    variant="success"
                >
                    Supply
                </Button>
            ),
        },
    ];

    const search_column = {
        partner_name: 'Partner',
        submited_date: 'Submited Date',
    };
    return useDataTable({ columns, data: d2CheckedData, search_column, pagination: false });
}
