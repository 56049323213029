/*
 * Author M. Atoar Rahman
 * Title: Nearly Expired Medicine
 * Description: Page for Nearly Expired Medicine
 * Date: 15/03/2022
 */

import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { NearlyExpiredMedicineProvider } from '../misReports/nearlyExpiredMedicine/ContextAPI';
import Index from '../misReports/nearlyExpiredMedicine/Index';
import PermissionDenied from './PermissionDenied';

export default function NearlyExpiredMedicine() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['nearly-expired-medicine']) return <PermissionDenied />;

    return (
        <NearlyExpiredMedicineProvider>
            <Index />
        </NearlyExpiredMedicineProvider>
    );
}
