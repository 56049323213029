/*
 * Author M. Atoar Rahman
 * Title: Url Service
 * Description: Url Service
 * Date: 15/03/2022
 */

const serverUrl = process.env.REACT_APP_API_URL;
const serverPublicUrl = process.env.REACT_APP_ASSETS_URL;

class UrlService {
    static baseUrl() {
        return `${serverUrl}`;
    }

    static publicUrl() {
        return `${serverPublicUrl}`;
    }

    static loginUrl() {
        return `${serverUrl}/login`;
    }

    static logoutUrl() {
        return `${serverUrl}/logout`;
    }

    static changePasswordUrl() {
        return `${serverUrl}/auth/change-password`;
    }

    static refreshTokenUrl() {
        return `${serverUrl}/refresh`;
    }

    // Partner  API
    static getAllPartnerUrl() {
        return `${serverUrl}/partner/list`;
    }

    static getPartnerByIdUrl(id) {
        return `${serverUrl}/partner/list/${id}`;
    }

    static savePartnerUrl() {
        return `${serverUrl}/partner/savedata`;
    }

    static updatePartnerUrl(id) {
        return `${serverUrl}/partner/savedata/${id}`;
    }

    static deletePartnerUrl() {
        return `${serverUrl}/partner/removedata`;
    }

    // Facility API
    static getAllFacilityUrl() {
        return `${serverUrl}/facility/list`;
    }

    static getFacilityByPartnerIdUrl(id) {
        return `${serverUrl}/facility/list/${id}`;
    }

    static saveFacilityUrl() {
        return `${serverUrl}/facility/savedata`;
    }

    static updateFacilityUrl(id) {
        return `${serverUrl}/facility/savedata/${id}`;
    }

    static deleteFacilityUrl() {
        return `${serverUrl}/facility/removedata`;
    }

    // Dispensary API
    static getAllDispensaryUrl() {
        return `${serverUrl}/dispensary/list`;
    }

    static getDispensaryByFacilityIdUrl(id) {
        return `${serverUrl}/dispensary/list/${id}`;
    }

    static getDispensaryByPartnerIdUrl(id) {
        return `${serverUrl}/dispensary/list/partner/${id}`;
    }

    static saveDispensaryUrl() {
        return `${serverUrl}/dispensary/savedata`;
    }

    static updateDispensaryUrl(id) {
        return `${serverUrl}/dispensary/savedata/${id}`;
    }

    static deleteDispensaryUrl() {
        return `${serverUrl}/dispensary/removedata`;
    }

    // Therapeutic Action Class API
    static getAllTherapeuticUrl() {
        return `${serverUrl}/therapeutic/list`;
    }

    static saveTherapeuticUrl() {
        return `${serverUrl}/therapeutic/savedata`;
    }

    static updateTherapeuticUrl(id) {
        return `${serverUrl}/therapeutic/savedata/${id}`;
    }

    static deleteTherapeuticUrl() {
        return `${serverUrl}/therapeutic/removedata`;
    }

    // Generic API
    static getAllDrugGenericUrl() {
        return `${serverUrl}/generic/list`;
    }

    static saveDrugGenericUrl() {
        return `${serverUrl}/generic/savedata`;
    }

    static updateDrugGenericUrl(id) {
        return `${serverUrl}/generic/savedata/${id}`;
    }

    static deleteDrugGenericUrl() {
        return `${serverUrl}/generic/removedata`;
    }

    // Drug Group API
    static getAllDrugGroupUrl() {
        return `${serverUrl}/drug-group/list`;
    }

    static saveDrugGroupUrl() {
        return `${serverUrl}/drug-group/savedata`;
    }

    static updateDrugGroupUrl(id) {
        return `${serverUrl}/drug-group/savedata/${id}`;
    }

    static deleteDrugGroupUrl() {
        return `${serverUrl}/drug-group/removedata`;
    }

    // Presentation Type API
    static getAllPresentationTypeUrl() {
        return `${serverUrl}/presentation-type/list`;
    }

    static savePresentationTypeUrl() {
        return `${serverUrl}/presentation-type/savedata`;
    }

    static updatePresentationTypeUrl(id) {
        return `${serverUrl}/presentation-type/savedata/${id}`;
    }

    static deletePresentationTypeUrl() {
        return `${serverUrl}/presentation-type/removedata`;
    }

    // Presentation Strength API
    static getAllPresentationStrengthUrl() {
        return `${serverUrl}/presentation-strength/list`;
    }

    static savePresentationStrengthUrl() {
        return `${serverUrl}/presentation-strength/savedata`;
    }

    static updatePresentationStrengthUrl(id) {
        return `${serverUrl}/presentation-strength/savedata/${id}`;
    }

    static deletePresentationStrengthUrl() {
        return `${serverUrl}/presentation-strength/removedata`;
    }

    // Role API
    static getAllRoleUrl() {
        return `${serverUrl}/role/list`;
    }

    static saveRoleUrl() {
        return `${serverUrl}/role/savedata`;
    }

    static updateRoleUrl(id) {
        return `${serverUrl}/role/savedata/${id}`;
    }

    static deleteRoleUrl() {
        return `${serverUrl}/role/destroy`;
    }

    // Drug Category API
    static getAllDrugCategoryUrl() {
        return `${serverUrl}/drug-category/list`;
    }

    static saveDrugCategoryUrl() {
        return `${serverUrl}/drug-category/savedata`;
    }

    static updateDrugCategoryUrl(id) {
        return `${serverUrl}/drug-category/savedata/${id}`;
    }

    static deleteDrugCategoryUrl() {
        return `${serverUrl}/drug-category/removedata`;
    }

    // User Reg API
    static getAllUserUrl() {
        return `${serverUrl}/user/list`;
    }

    static getUserByPartnerUrl(id) {
        return `${serverUrl}/user/list/${id}`;
    }

    static saveUserUrl() {
        return `${serverUrl}/user/savedata`;
    }

    static updateUserUrl(id) {
        return `${serverUrl}/user/savedata/${id}`;
    }

    static deleteUserUrl() {
        return `${serverUrl}/user/removedata`;
    }

    static isExistUserNameUrl(name) {
        return `${serverUrl}/user/isexist_username/${name}`;
    }

    static isExistUserEmailUrl(email) {
        return `${serverUrl}/user/isexist_user_email/${email}`;
    }

    // Employee API
    static getAllEmployeeUrl() {
        return `${serverUrl}/employee/list`;
    }

    static getEmployeeByPartnerIdUrl(id) {
        return `${serverUrl}/employee/list/${id}`;
    }

    static saveEmployeeUrl() {
        return `${serverUrl}/employee/savedata`;
    }

    static updateEmployeeUrl(id) {
        return `${serverUrl}/employee/savedata/${id}`;
    }

    static deleteEmployeeUrl() {
        return `${serverUrl}/employee/removedata`;
    }

    static getAllMenu() {
        return `${serverUrl}/menu/index`;
    }

    static getSubMenu() {
        return `${serverUrl}/menu/submenu`;
    }

    static saveMenuUrl() {
        return `${serverUrl}/menu/store`;
    }

    static updateMenuUrl(id) {
        return `${serverUrl}/menu/store/${id}`;
    }

    // Menu Permission API
    static getAllPermissionUrl() {
        return `${serverUrl}/menu-permission/index`;
    }

    static getAllPermissionMenuList() {
        return `${serverUrl}/menu-permission/menulist`;
    }

    static permissionMenuStore() {
        return `${serverUrl}/menu-permission/store`;
    }

    // Supplier API
    static getAllSupplierUrl() {
        return `${serverUrl}/supplier/list`;
    }

    static saveSupplierUrl() {
        return `${serverUrl}/supplier/savedata`;
    }

    static updateSupplierUrl(id) {
        return `${serverUrl}/supplier/savedata/${id}`;
    }

    static deleteSupplierUrl() {
        return `${serverUrl}/supplier/removedata`;
    }

    // Manufacturer API
    static getAllManufacturerUrl() {
        return `${serverUrl}/manufacturer/list`;
    }

    static saveManufacturerUrl() {
        return `${serverUrl}/manufacturer/savedata`;
    }

    static updateManufacturerUrl(id) {
        return `${serverUrl}/manufacturer/savedata/${id}`;
    }

    static deleteManufacturerUrl() {
        return `${serverUrl}/manufacturer/removedata`;
    }

    // Center Stock In API
    static getAllCenterStockInUrl() {
        return `${serverUrl}/centerstockin/list`;
    }

    static saveCenterStockInUrl() {
        return `${serverUrl}/centerstockin/savedata`;
    }

    static getEditDataCenterStockInUrl(master_id) {
        return `${serverUrl}/centerstockin/editdata/${master_id}`;
    }

    static updateCenterStockInUrl(id) {
        return `${serverUrl}/centerstockin/savedata/${id}`;
    }

    static deleteCenterStockInUrl() {
        return `${serverUrl}/centerstockin/removedata`;
    }

    // Center Warehouse API
    static getAllCenterWarehouseUrl() {
        return `${serverUrl}/centerwarehouse/list`;
    }

    static saveCenterWarehouseUrl() {
        return `${serverUrl}/centerwarehouse/savedata`;
    }

    static updateCenterWarehouseUrl(id) {
        return `${serverUrl}/centerwarehouse/savedata/${id}`;
    }

    static deleteCenterWarehouseUrl() {
        return `${serverUrl}/centerwarehouse/removedata`;
    }

    // Drug API
    static getAllDrugUrl() {
        return `${serverUrl}/drug/list`;
    }

    static saveDrugUrl() {
        return `${serverUrl}/drug/savedata`;
    }

    static updateDrugUrl(id) {
        return `${serverUrl}/drug/savedata/${id}`;
    }

    static deleteDrugUrl() {
        return `${serverUrl}/drug/removedata`;
    }

    static getAllDrugInfoUrl(catid) {
        if (catid) {
            return `${serverUrl}/drug/view_list/${catid}`;
        }
        return `${serverUrl}/drug/view_list`;
    }

    // Center Warehouse API
    static getAllWarehouseInfoUrl() {
        return `${serverUrl}/warehouse-info/list`;
    }

    static saveWarehouseInfoUrl() {
        return `${serverUrl}/warehouse-info/savedata`;
    }

    static updateWarehouseInfoUrl(id) {
        return `${serverUrl}/warehouse-info/savedata/${id}`;
    }

    static deleteWarehouseInfoUrl() {
        return `${serverUrl}/warehouse-info/removedata`;
    }

    // D1 Form API
    static getAllD1FormUrl(id) {
        return `${serverUrl}/d1preparation/list/${id}`;
    }

    static getAllD1Url(status, user_layer = null) {
        return `${serverUrl}/d1preparation/all-d1/${status}/${user_layer}`;
    }

    static saveD1FormUrl() {
        return `${serverUrl}/d1preparation/savedata`;
    }

    static updateD1FormUrl(id) {
        return `${serverUrl}/d1preparation/savedata/${id}`;
    }

    static deleteD1FormUrl() {
        return `${serverUrl}/d1preparation/removedata`;
    }

    static getMedicineListByIdUrl(categoty_id) {
        return `${serverUrl}/d1preparation/medicine_list/${categoty_id}`;
    }

    static getD1RecordByIdUrl(d1_master_id) {
        return `${serverUrl}/d1preparation/d1_records/${d1_master_id}`;
    }

    static D1CheckedUrl() {
        return `${serverUrl}/d1preparation/d1_checked`;
    }

    // Waybill
    static waybillCenterDistributionUrl(form, master_id) {
        return `${serverUrl}/d2preparation/waybill/${form}/${master_id}`;
    }

    static waybillPartnerDistributionUrl(form, master_id) {
        return `${serverUrl}/d2preparation/waybill/${form}/${master_id}`;
    }

    static waybillFacilityDistributionUrl(form, master_id) {
        return `${serverUrl}/d2preparation/waybill/${form}/${master_id}`;
    }

    // GRN
    static getPdfDataCenterStockInUrl(master_id) {
        return `${serverUrl}/d2preparation/center_grn_report/${master_id}`;
    }

    static grnPartnerUrl(master_id) {
        return `${serverUrl}/d2preparation/partner_grn_report/${master_id}`;
    }

    static grnFacilityUrl(master_id) {
        return `${serverUrl}/d2preparation/facility_grn_report/${master_id}`;
    }

    // D2 Form API
    static getAllD2FormUrl(id) {
        return `${serverUrl}/d2preparation/list/${id}`;
    }

    static saveD2FormUrl() {
        return `${serverUrl}/d2preparation/savedata`;
    }

    static updateD2FormUrl(id) {
        return `${serverUrl}/d2preparation/savedata/${id}`;
    }

    static deleteD2FormUrl() {
        return `${serverUrl}/d2preparation/removedata`;
    }

    // D2Form: Facility List
    static getD2FormFacilityListUrl(partner, year, month) {
        return `${serverUrl}/d2preparation/facility_list/${partner}/${year}/${month}`;
    }

    // D2Form: D1 List of Facility
    static getD2FormFacilityD1ListUrl(facility, year, month) {
        return `${serverUrl}/d2preparation/d1_list/${facility}/${year}/${month}`;
    }

    // D2 Record By Id
    static getD2RecordByIdUrl(d2_master_id) {
        return `${serverUrl}/d2preparation/d2_records/${d2_master_id}`;
    }

    static getPartnerD2RecordByIdUrl(d2_master_id) {
        return `${serverUrl}/d2preparation/d2_records_partner/${d2_master_id}`;
    }

    static getD2RecordByWarehouseIdUrl(d2_master_id, warehouse_id) {
        return `${serverUrl}/d2preparation/d2_records/${d2_master_id}/${warehouse_id}`;
    }

    static getCenterD2RecordByIdUrl(d2_master_id) {
        return `${serverUrl}/d2preparation/d2_records_center/${d2_master_id}`;
    }

    static D2CheckedUrl() {
        return `${serverUrl}/d2preparation/d2_checked`;
    }

    static getAllD2Url(status, user_layer = null) {
        return `${serverUrl}/d2preparation/all-d2/${status}/${user_layer}`;
    }

    static unhcrD2ApprovedUrl() {
        return `${serverUrl}/d2preparation/d2_approved`;
    }

    static D2FinalApprovedUrl() {
        return `${serverUrl}/d2preparation/d2_final_approved`;
    }

    static D2SaveCentralStockOut() {
        return `${serverUrl}/d2preparation/save_central_stock_out`;
    }

    static D2CentralStockOutApproval() {
        return `${serverUrl}/d2preparation/central_stockout_approved`;
    }

    // Partner Stock In
    static getD2StockReceiveByIdUrl(id) {
        return `${serverUrl}/stockin/medicine_list/${id}`;
    }

    static saveD2StockReceiveUrl() {
        return `${serverUrl}/stockin/savedata`;
    }

    static approvalD2StockReceiveUrl() {
        return `${serverUrl}/stockin/receive_approval`;
    }

    static getPartnerStockInDetailsByIdUrl(master_id) {
        return `${serverUrl}/stockin/partner_stock_in/${master_id}`;
    }

    // Partner Stock Out
    static partnerStockOut() {
        return `${serverUrl}/stockout/partner_stockout`;
    }

    static approvalPartnerStockOut() {
        return `${serverUrl}/stockout/partner_stockout_approval`;
    }

    // Facility Receive
    static getD1StockReceiveByIdUrl(d1_master_id) {
        return `${serverUrl}/stockin/facility_medicine_list/${d1_master_id}`;
    }

    static getFacilityStockInDetailsByIdUrl(master_id) {
        return `${serverUrl}/stockin/details/${master_id}`;
    }

    static saveD1StockReceiveUrl() {
        return `${serverUrl}/stockin/facility_stockin`;
    }

    static approvalD1StockReceiveUrl() {
        return `${serverUrl}/stockin/facility_receive_approval`;
    }

    // Facility Stock Out
    static getStockOutByStatusUrl(status) {
        return `${serverUrl}/stockout/list/${status}`;
    }

    static getStockOutDetailsByIdUrl(master_id) {
        return `${serverUrl}/stockout/details/${master_id}`;
    }

    static facilityStockOutMedicineListUrl(category_id) {
        return `${serverUrl}/stockout/facility_medicine_list/${category_id}`;
    }

    static facilityStockOut() {
        return `${serverUrl}/stockout/facility_stockout`;
    }

    static approvalFacilityStockOut() {
        return `${serverUrl}/stockout/facility_stockout_approval`;
    }

    // Dispensary Stock Receive
    static getFacilityStockOutDataByDispensaryIdUrl(status) {
        return `${serverUrl}/stockout/facility_stockout_data/${status}`;
    }

    static dispensaryStockMedicineListUrl(master_id) {
        return `${serverUrl}/stockin/dispensary_medicine_list/${master_id}`;
    }

    static dispensaryStockReceiveMedicineListUrl(master_id) {
        return `${serverUrl}/stockin/dispensary_received_details/${master_id}`;
    }

    static dispensaryStockReceiveUrl() {
        return `${serverUrl}/stockin/dispensary_stockin`;
    }

    // Dispatch
    static getAllDispatchUrl() {
        return `${serverUrl}/dispatch/list`;
    }

    static getDispatchItemDetailsUrl(id) {
        return `${serverUrl}/dispatch/details/${id}`;
    }

    static saveDispatchUrl() {
        return `${serverUrl}/dispatch/savedata`;
    }

    static updateDispatchUrl(id) {
        return `${serverUrl}/dispatch/savedata/${id}`;
    }

    static deleteDispatchUrl() {
        return `${serverUrl}/dispatch/removedata`;
    }

    static getDispatchItemsByIdUrl(dispensary_id) {
        return `${serverUrl}/dispatch/item_list/${dispensary_id}`;
    }

    // Partner Internal Request
    static getAllPartnerInternalRequestUrl() {
        return `${serverUrl}/partner/internal/request/list`;
    }

    static savePartnerInternalRequestUrl() {
        return `${serverUrl}/partner/internal/request/savedata`;
    }

    static updatePartnerInternalRequestUrl(id) {
        return `${serverUrl}/partner/internal/request/savedata/${id}`;
    }

    static deletePartnerInternalRequestUrl() {
        return `${serverUrl}/partner/internal/request/removedata`;
    }

    // Facility Internal Request
    static getAllFacilityInternalRequestUrl() {
        return `${serverUrl}/facility/internal/request/list`;
    }

    static saveFacilityInternalRequestUrl() {
        return `${serverUrl}/facility/internal/request/savedata`;
    }

    static updateFacilityInternalRequestUrl(id) {
        return `${serverUrl}/facility/internal/request/savedata/${id}`;
    }

    static deleteFacilityInternalRequestUrl() {
        return `${serverUrl}/facility/internal/request/removedata`;
    }

    static approveFacilityInternalRequestUrl() {
        return `${serverUrl}/facility/internal/request/approval`;
    }

    // Dispensary Internal Request
    static getAllDispensaryInternalRequestUrl() {
        return `${serverUrl}/dispensary/internal/request/list`;
    }

    static saveDispensaryInternalRequestUrl() {
        return `${serverUrl}/dispensary/internal/request/savedata`;
    }

    static updateDispensaryInternalRequestUrl(id) {
        return `${serverUrl}/dispensary/internal/request/savedata/${id}`;
    }

    static deleteDispensaryInternalRequestUrl() {
        return `${serverUrl}/dispensary/internal/request/removedata`;
    }

    static approveDispensaryInternalRequestUrl() {
        return `${serverUrl}/dispensary/internal/request/approval`;
    }

    // Center Stock Loss
    static getAllCenterStockLossUrl() {
        return `${serverUrl}/center/loss/request/list`;
    }

    static saveCenterStockLossUrl() {
        return `${serverUrl}/center/loss/request/savedata`;
    }

    static updateCenterStockLossUrl(id) {
        return `${serverUrl}/center/loss/request/savedata/${id}`;
    }

    static deleteCenterStockLossUrl() {
        return `${serverUrl}/center/loss/request/removedata`;
    }

    // Partner Stock Loss
    static getAllPartnerStockLossUrl() {
        return `${serverUrl}/partner/loss/request/list`;
    }

    static savePartnerStockLossUrl() {
        return `${serverUrl}/partner/loss/request/savedata`;
    }

    static updatePartnerStockLossUrl(id) {
        return `${serverUrl}/partner/loss/request/savedata/${id}`;
    }

    static deletePartnerStockLossUrl() {
        return `${serverUrl}/partner/loss/request/removedata`;
    }

    // Facility Stock Loss
    static getAllFacilityStockLossUrl() {
        return `${serverUrl}/facility/loss/request/list`;
    }

    static saveFacilityStockLossUrl() {
        return `${serverUrl}/facility/loss/request/savedata`;
    }

    static updateFacilityStockLossUrl(id) {
        return `${serverUrl}/facility/loss/request/savedata/${id}`;
    }

    static deleteFacilityStockLossUrl() {
        return `${serverUrl}/facility/loss/request/removedata`;
    }

    // Dispensary Stock Loss
    static getAllDispensaryStockLossUrl() {
        return `${serverUrl}/dispensary/loss/request/list`;
    }

    static saveDispensaryStockLossUrl() {
        return `${serverUrl}/dispensary/loss/request/savedata`;
    }

    static updateDispensaryStockLossUrl(id) {
        return `${serverUrl}/dispensary/loss/request/savedata/${id}`;
    }

    static deleteDispensaryStockLossUrl() {
        return `${serverUrl}/dispensary/loss/request/removedata`;
    }

    // UNHCR Stock Return Request
    static getFacilityMedicineListForReturnRequest() {
        return `${serverUrl}/stock/return/request/facility_medicine_list`;
    }

    // Partner Stock Return
    static getAllPartnerStockReturnUrl() {
        return `${serverUrl}/partner/stock/return/list`;
    }

    static savePartnerStockReturnUrl() {
        return `${serverUrl}/partner/stock/return/savedata`;
    }

    static updatePartnerStockReturnUrl(id) {
        return `${serverUrl}/partner/stock/return/savedata/${id}`;
    }

    static deletePartnerStockReturnUrl() {
        return `${serverUrl}/partner/stock/return/removedata`;
    }

    // Facility Stock Return
    static getAllFacilityStockReturnUrl() {
        return `${serverUrl}/facility/stock/return/list`;
    }

    static saveFacilityStockReturnUrl() {
        return `${serverUrl}/facility/stock/return/savedata`;
    }

    static updateFacilityStockReturnUrl(id) {
        return `${serverUrl}/facility/stock/return/savedata/${id}`;
    }

    static deleteFacilityStockReturnUrl() {
        return `${serverUrl}/facility/stock/return/removedata`;
    }

    // Dispensary Stock Return
    static getAllDispensaryStockReturnUrl() {
        return `${serverUrl}/dispensary/stock/return/list`;
    }

    static saveDispensaryStockReturnUrl() {
        return `${serverUrl}/dispensary/stock/return/savedata`;
    }

    static updateDispensaryStockReturnUrl(id) {
        return `${serverUrl}/dispensary/stock/return/savedata/${id}`;
    }

    static deleteDispensaryStockReturnUrl() {
        return `${serverUrl}/dispensary/stock/return/removedata`;
    }

    // Utility
    static lossReasonUtilityUrl() {
        return `${serverUrl}/utility/lossreason`;
    }

    static batchNoListUtilityUrl() {
        return `${serverUrl}/utility/batchnolist`;
    }

    static facilityBatchNoListUtilityUrl() {
        return `${serverUrl}/utility/facilitybatchnolist`;
    }

    static partnerBatchNoListUtilityUrl() {
        return `${serverUrl}/utility/partnerbatchnolist`;
    }

    static centerBatchNoListUtilityUrl() {
        return `${serverUrl}/utility/centerbatchnolist`;
    }

    // Layer Chaining API
    static getAllLayerChainingUrl() {
        return `${serverUrl}/layerchain/list`;
    }

    static saveLayerChainingUrl() {
        return `${serverUrl}/layerchain/savedata`;
    }

    static updateLayerChainingUrl(id) {
        return `${serverUrl}/layerchain/savedata/${id}`;
    }

    static deleteLayerChainingUrl() {
        return `${serverUrl}/layerchain/removedata`;
    }

    // Current Stock
    static getCurrentStockUrl() {
        return `${serverUrl}/currentstock/medicineList`;
    }

    static getCenterCurrentStockUrl() {
        return `${serverUrl}/currentstock/center`;
    }

    static getPartnerCurrentStockUrl() {
        return `${serverUrl}/currentstock/partner`;
    }

    static getFacilityCurrentStockUrl() {
        return `${serverUrl}/currentstock/facility`;
    }

    static getDispensaryCurrentStockUrl() {
        return `${serverUrl}/currentstock/dispensary`;
    }

    // Stock Register and BIN Card
    static centerBatchNoByIdUrl(drug_id) {
        return `${serverUrl}/centerstockin/batch_list/${drug_id}`;
    }

    static centerStockRegBinCardUrl() {
        return `${serverUrl}/centerstockin/center_stock_record`;
    }

    static partnerBatchNoByIdUrl(drug_id) {
        return `${serverUrl}/stockin/partner_batch_list/${drug_id}`;
    }

    static partnerStockRegBinCardUrl() {
        return `${serverUrl}/stockin/partner_stock_record`;
    }

    static facilityBatchNoByIdUrl(drug_id) {
        return `${serverUrl}/stockin/facility_batch_list/${drug_id}`;
    }

    static facilityStockRegBinCardUrl() {
        return `${serverUrl}/stockin/facility_stock_record`;
    }

    static d3ReportsUrl() {
        return `${serverUrl}/dispatch/d3_report`;
    }

    static d4ReportsUrl() {
        return `${serverUrl}/dispatch/d4_report`;
    }

    // OuterAgency API
    static getAllOuterAgencyUrl() {
        return `${serverUrl}/agency/list`;
    }

    static saveOuterAgencyUrl() {
        return `${serverUrl}/agency/savedata`;
    }

    static updateOuterAgencyUrl(id) {
        return `${serverUrl}/agency/savedata/${id}`;
    }

    static deleteOuterAgencyUrl() {
        return `${serverUrl}/agency/removedata`;
    }

    // ConsumptionTally API
    static saveConsumptionTallyUrl() {
        return `${serverUrl}/dispatch/consumption-tally`;
    }

    // ConsumptionTally API
    static saveConsumptionReportsUrl() {
        return `${serverUrl}/dispatch/consumption-summery-report`;
    }

    // PO Reports API
    static savePoReportsUrl() {
        return `${serverUrl}/purchase/order/supplier-wise-report`;
    }

    static getPoReportsByPoIdUrl() {
        return `${serverUrl}/purchase/order/singlebypurchaseorderno`;
    }

    // Expired Medicine API
    static getAllExpiredMedicineUrl(layer) {
        return `${serverUrl}/common/already-expired/${layer}`;
    }

    static getAllNearlyExpiredMedicineUrl(layer) {
        return `${serverUrl}/common/nearly-expire/${layer}`;
    }

    static getDashboardAllNearlyExpiredMedicineUrl() {
        return `${serverUrl}/common/nearly-expire-dashboard`;
    }

    static getAllSleepingStockUrl(layer) {
        return `${serverUrl}/common/sleeping-stock/${layer}`;
    }

    // Donation API
    static getAllDonationUrl() {
        return `${serverUrl}/donation/list`;
    }

    static getDonationDetailsUrl(id) {
        return `${serverUrl}/donation/details/${id}`;
    }

    static getDonationCertificateUrl(id) {
        return `${serverUrl}/donation/certificate/${id}`;
    }

    static getDonationDrugListUrl() {
        return `${serverUrl}/donation/drug_list`;
    }

    static saveDonationUrl() {
        return `${serverUrl}/donation/savedata`;
    }

    static updateDonationUrl(id) {
        return `${serverUrl}/donation/savedata/${id}`;
    }

    static deleteDonationUrl() {
        return `${serverUrl}/donation/removedata`;
    }

    // Donation API
    static getAllPurchaseOrderUrl() {
        return `${serverUrl}/purchase/order/list`;
    }

    static getPurchaseOrderDetailsUrl() {
        return `${serverUrl}/purchase/order/single`;
    }

    static savePurchaseOrderUrl() {
        return `${serverUrl}/purchase/order/savedata`;
    }

    static updatePurchaseOrderUrl(id) {
        return `${serverUrl}/purchase/order/savedata/${id}`;
    }

    static deletePurchaseOrderUrl() {
        return `${serverUrl}/purchase/order/removedata`;
    }

    // Stock Return Request API
    static getAllStockReturnRequestUrl() {
        return `${serverUrl}/stock-return-request/list`;
    }
}

export default UrlService;
