/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: User Reg. DataTable
 * Description: User Reg. DataTable
 * Date: 15/03/2022
 */

import { useCallback, useEffect } from 'react';
import { Badge, Button } from 'react-bootstrap';
import useDataTable from '../../../hooks/useDataTable';
import SweetAlertService from '../../../services/SweetAlertService';
import UrlService from '../../../services/UrlService';
import { useUser } from './ContexAPI';
import FormInfo from './FormInfo';

export default function DataTable() {
    const { data, getAllData } = useUser();
    const { deleteSwal } = SweetAlertService();

    const dataList = useCallback(() => {
        getAllData();
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    const deleteHandler = (row) => {
        deleteSwal({
            id: row.id,
            url: UrlService.deleteUserUrl(),
            refreshList: getAllData,
            beforeTitle: 'Are you sure?',
            beforeText: "You won't be able to revert this!",
            afterTitle: 'Deleted!',
            afterText: 'Your file has been deleted.',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        });
    };

    const columns = [
        {
            name: 'EMP ID',
            selector: (row) => row.employee?.code_no,
            sortable: true,
        },
        {
            name: 'Username',
            selector: (row) => row.username,
            sortable: true,
        },
        {
            name: 'Full Name',
            selector: (row) => (
                <>
                    {row.employee?.first_name} {row.employee?.last_name}
                </>
            ),
            sortable: true,
        },
        {
            name: 'Email',
            selector: (row) => row.employee?.email,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Designation',
            selector: (row) => row.employee?.designation,
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row) => row.status,
            cell: (row) =>
                row.status == 1 ? (
                    <Badge className="badgeActive" bg="success">
                        Active
                    </Badge>
                ) : (
                    <Badge className="badgeInactive" bg="success">
                        Inactive
                    </Badge>
                ),
        },
        {
            name: 'Action',
            selector: (row) => row.action,
            cell: (row) => (
                <>
                    {/* <button data-id={row.id} type="button" className="btn btn-light actionBtn">
                        {layout}
                    </button> */}

                    <FormInfo editData={row} />

                    <Button
                        onClick={() => {
                            deleteHandler(row);
                        }}
                        type="button"
                        className="btn btn-light actionBtn"
                    >
                        <i className="fas fa-trash-alt" />
                    </Button>
                </>
            ),
        },
    ];

    const search_column = {
        'employee.code_no': 'Code No',
        username: 'Username',
        'employee.first_name': 'First Name',
        'employee.last_name': 'Last Name',
        'employee.email': 'Email',
        'employee.designation': 'Designation',
    };
    return useDataTable({ columns, data, search_column });
}
