/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Requisition DataTable
 * Description: Requisition DataTable
 * Date: 05/06/2022
 */

import { toArray } from 'lodash';
import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import useDataTable from '../../../hooks/useDataTable';
import { useStockReturnRequest } from './ContextAPI';

export default function ReturnTable({ data }) {
    const { setReturnList, returnData, setReturnData } = useStockReturnRequest();

    const requisitionRowHandler = (value, item) => {
        const itemdata = item;
        itemdata.approved_qty = value;
        const list = { ...returnData };
        list[itemdata.drug_id] = itemdata;
        if (value === '') {
            delete list[itemdata.drug_id];
        }
        setReturnData(list);
    };

    // Object to Array Convart
    useEffect(() => {
        const arrOfObj = toArray(returnData);
        setReturnList(arrOfObj);
    }, [returnData]);

    const columns = [
        {
            name: 'Item Names',
            selector: (row) => row.drug_name,
            sortable: true,
            wrap: true,
            width: '250px',
            fixed: true,
        },
        {
            name: 'Return Qty',
            selector: (row) => row.approved_qty,
            cell: (row) => (
                <Form.Control
                    size="sm"
                    type="number"
                    onBlur={(e) => {
                        requisitionRowHandler(e.target.value, row);
                    }}
                    defaultValue={returnData?.[row.drug_id]?.approved_qty}
                />
            ),
            reorder: true,
            width: '150px',
        },
    ];

    const search_column = {
        drug_name: 'Name',
    };
    return useDataTable({ columns, data, search_column, pagination: false });
}
