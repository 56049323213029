/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Center Stockin DataTable
 * Description: Center Stockin DataTable
 * Date: 26/04/2022
 */

import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useDataTable from '../../../hooks/useDataTable';
import { useCenterStockIn } from './ContextAPI';
// import SweetAlertService from '../../../services/SweetAlertService';
import UrlService from '../../../services/UrlService';

export default function DataTable() {
    const { centerStockIns, getAllData, setShow, setRowData, setShowPdf, setPdfData } =
        useCenterStockIn();
    const axiosPrivate = useAxiosPrivate();
    // const { deleteSwal } = SweetAlertService();

    const dataList = useCallback(() => {
        getAllData();
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    const editHander = async (row) => {
        try {
            const response = await axiosPrivate.get(
                UrlService.getEditDataCenterStockInUrl(row.id),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            const stock_in_info = response?.data?.stock_in_info[0];

            setShow(true);
            setRowData({ ...stock_in_info });
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };
    const handlePrint = async (row) => {
        try {
            const response = await axiosPrivate.get(
                UrlService.getEditDataCenterStockInUrl(row.id),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            const stock_in_info = response?.data?.stock_in_info[0];

            setShowPdf(true);
            setPdfData({ ...stock_in_info });
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    // const handlePrint = useCallback(
    //     async (row) => {
    //         setShowPdf(true);
    //         pdfHandler(row.id);
    //     },
    //     [pdfHandler, setShowPdf]
    // );

    // const deleteHandler = (row) => {
    //     deleteSwal({
    //         id: row.id,
    //         url: UrlService.deleteCenterStockInUrl(),
    //         refreshList: getAllData,
    //         beforeTitle: 'Are you sure?',
    //         beforeText: "You won't be able to revert this!",
    //         afterTitle: 'Deleted!',
    //         afterText: 'Your file has been deleted.',
    //         confirmButtonText: 'Yes, delete it!',
    //         cancelButtonText: 'No, cancel!',
    //     });
    // };

    const columns = [
        {
            name: 'GRN No',
            selector: (row) => row.grn_no,
            sortable: true,
        },
        {
            name: 'Entry Date',
            selector: (row) => row.entry_date,
            format: (row) => moment(row.entry_date).format('DD MMM YYYY'),
            sortable: true,
        },
        {
            name: 'PO No',
            selector: (row) => row.po_no,
            sortable: true,
        },
        {
            name: 'PO Date',
            selector: (row) => row.po_date,
            format: (row) => moment(row.po_date).format('DD MMM YYYY'),
            sortable: true,
        },
        {
            name: 'Invoice No',
            selector: (row) => row.invoice_no,
            sortable: true,
        },

        {
            name: 'Action',
            selector: (row) => row.action,
            cell: (row) => (
                <>
                    {/* <Button data-id={row.id} type="button" className="btn btn-light actionBtn">
                        {layout}
                    </Button> */}

                    {/* <FormInfo editData={rowData} /> */}

                    <Button
                        className="btn btn-light actionBtn"
                        variant="primary"
                        onClick={() => handlePrint(row)}
                    >
                        <i className="fa fa-file-pdf" /> GRN
                    </Button>

                    <Button
                        className="btn btn-light actionBtn"
                        variant="primary"
                        onClick={() => editHander(row)}
                    >
                        <i className="fa fa-edit" />
                    </Button>

                    {/* <Button
                        onClick={() => {
                            deleteHandler(row);
                        }}
                        type="button"
                        className="btn btn-light actionBtn"
                    >
                        <i className="fas fa-trash-alt" />
                    </Button> */}
                </>
            ),
        },
    ];

    const search_column = {
        grn_no: 'GRN No',
        entry_date: 'Entry Date',
        po_no: 'PO No',
        po_date: 'PO Date',
        invoice_no: 'Invoice No',
    };
    return useDataTable({ columns, data: centerStockIns, search_column });
}
