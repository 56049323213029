/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Requisition DataTable
 * Description: Requisition DataTable
 * Date: 05/06/2022
 */

import { toArray } from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import useDataTable from '../../../hooks/useDataTable';
import { useD2Approval } from './ContextAPI';

export default function ShowTable({ data }) {
    const { setReceiveList, receiveData, setReceiveData } = useD2Approval();

    const requisitionRowHandler = (value, item) => {
        const itemdata = item;
        itemdata.final_approved_qty = value;
        const list = { ...receiveData };
        list[itemdata.drug_id] = itemdata;
        if (value === '') {
            delete list[itemdata.drug_id];
        }
        setReceiveData(list);
    };

    // Object to Array Convart
    useEffect(() => {
        const arrOfObj = toArray(receiveData);
        setReceiveList(arrOfObj);
    }, [receiveData]);

    const conditionalCellStyles = [
        {
            when: (row) => Number(row.central_warehouse_qty) <= 0,
            style: {
                backgroundColor: '#ffb4c1',
            },
        },
        {
            when: (row) =>
                Number(row.central_warehouse_qty) > 0 &&
                Number(row.central_warehouse_qty) < Number(row.requested_qty),
            style: {
                backgroundColor: '#ffb4c1',
            },
        },
        {
            when: (row) =>
                Number(row.central_warehouse_qty) > 0 &&
                Math.floor(Number(row.avg_monthly_consumption)) < Number(row.requested_qty),
            style: {
                backgroundColor: '#fcff3c',
            },
        },
    ];

    const columns = [
        {
            name: 'Item Names',
            selector: (row) => row.drug_name,
            sortable: true,
            conditionalCellStyles,
            wrap: true,
            width: '250px',
            fixed: true,
        },
        {
            name: 'Opening Balance',
            selector: (row) => row.opening_balance,
            sortable: true,
            conditionalCellStyles,
        },
        {
            name: 'Total Quantity Received During The Month',
            selector: (row) => row.received_during_month,
            sortable: true,
            conditionalCellStyles,
        },
        {
            name: 'Total UNHCR Stock quantity Consumed By the Facility (OPD, IPD, Emergency, any other dept)',
            selector: (row) => row.consumed_by_facility,
            sortable: true,
            conditionalCellStyles,
            width: '150px',
        },
        {
            name: 'Total Non-UNHCR Stock Quantity Consumed By The Agency',
            cell: () => '0',
            sortable: true,
            conditionalCellStyles,
            width: '150px',
        },
        {
            name: 'Number Of Days Out Of Stock In The Pharmacy',
            selector: (row) => row.stock_out_days,
            sortable: true,
            conditionalCellStyles,
        },
        {
            name: 'Average Monthly Consumption',
            selector: (row) => row.avg_monthly_consumption,
            sortable: true,
            conditionalCellStyles,
        },
        {
            name: 'Total Quantities Returned To Main Agency Warehouse (Due To over stock/ For re-distribution)',
            selector: (row) => row.overstock_return,
            sortable: true,
            conditionalCellStyles,
            width: '150px',
        },
        {
            name: 'Total Quanties Expired or damaged',
            selector: (row) => row.expired_qty,
            sortable: true,
            conditionalCellStyles,
        },
        {
            name: 'Earliest date of Expiry of physical stocks',
            selector: (row) => row.earliest_expire_date,
            format: (row) => moment(row.earliest_expire_date).format('DD MMM YYYY'),
            sortable: true,
            conditionalCellStyles,
            width: '150px',
        },
        {
            name: 'Closing Balance (Physical Stock)',
            selector: (row) => row.closing_balance,
            sortable: true,
            conditionalCellStyles,
        },
        {
            name: 'Theoretical Stock',
            selector: (row) => row.theoritical_stock,
            sortable: true,
            conditionalCellStyles,
        },
        {
            name: 'Number of days of physical stock available',
            selector: (row) => row.days_physical_stock_available,
            sortable: true,
            conditionalCellStyles,
        },
        {
            name: 'Request Quantity',
            selector: (row) => row.requested_qty,
            sortable: true,
            conditionalCellStyles,
        },
        {
            name: 'Approve Request Quantity',
            selector: (row) => row.approved_request_qty,
            sortable: true,
            conditionalCellStyles,
        },
        {
            name: 'Availability in Central Warehouse',
            selector: (row) => row.central_warehouse_qty,
            sortable: true,
            conditionalCellStyles,
        },
        {
            name: 'Approved Quantity',
            selector: (row) => row.approved_qty,
            sortable: true,
            conditionalCellStyles,
        },
        {
            name: 'Final Approve Quantity',
            selector: (row) => row.final_approved_qty,
            cell: (row) => (
                <Form.Control
                    size="sm"
                    type="number"
                    onBlur={(e) => {
                        requisitionRowHandler(e.target.value, row);
                    }}
                    defaultValue={
                        row.final_approved_qty > 0
                            ? row.final_approved_qty
                            : receiveData?.[row.drug_id]?.final_approved_qty
                    }
                />
            ),
            reorder: true,
            width: '150px',
            conditionalCellStyles,
        },
    ];

    const search_column = {
        drug_name: 'Name',
    };
    return useDataTable({ columns, data, search_column, pagination: false });
}
