/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Drug Generic
 * Description: Master Setup of Drug Generic Form: Create and Update
 * Date: 15/03/2022
 */

import { useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import TherapeuticActionForm from '../therapeuticAction/FormInfo';
import { useDrugGeneric } from './ContextAPI';

export default function FormInfo({ editData = null, instantCreate = false }) {
    // Local State
    const [therapeuticAllData, setTherapeuticAllData] = useState();
    const [therapeutics, setTherapeutics] = useState([]);
    const [selectTherapeutic, setSelectTherapeutic] = useState();
    const [therapeuticId, setTherapeuticId] = useState('');
    const [errMsg, setErrMsg] = useState('');

    const {
        register,
        formState: { errors },
        reset,
        handleSubmit,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
        defaultValues: useMemo(
            () => ({
                ...editData,
                // eslint-disable-next-line eqeqeq
                status: editData && editData?.status == '1' ? 1 : 0,
                tac_id: editData && therapeuticId,
            }),
            [editData]
        ),
    });

    // Reset default values
    useEffect(() => {
        reset({
            ...editData,
            // eslint-disable-next-line eqeqeq
            status: editData && editData?.status == '1' ? 1 : 0,
            tac_id: therapeuticId,
        });
    }, [editData, therapeuticId]);

    // API URL
    let SAVE_URL = '';
    if (editData == null) {
        SAVE_URL = UrlService.saveDrugGenericUrl();
    } else {
        SAVE_URL = UrlService.updateDrugGenericUrl(editData.id);
    }

    // Dependency
    const axiosPrivate = useAxiosPrivate();
    const { getAllData, isSubmitted, setIsSubmitted } = useDrugGeneric();

    // Status Check Handling
    let checkeStatus;
    // eslint-disable-next-line eqeqeq
    if (editData != null && editData.status == 1) {
        checkeStatus = 'checked';
    } else if (editData === null) {
        checkeStatus = 'checked';
    } else {
        checkeStatus = '';
    }

    // State for Model
    const [show, setShow] = useState(false);
    const [checked, setChecked] = useState(checkeStatus);

    // Get All Therapeutic Action Class Table
    const therapeuticList = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getAllTherapeuticUrl(), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const partnerInfo = response?.data?.therapeutic_info?.map((el) => ({
                value: el.id,
                label: el.name,
            }));
            setTherapeuticAllData(partnerInfo);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding !!!!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    // Closing the Model
    const handleClose = () => {
        setShow(false);
    };
    // Show the Model
    const handleShow = () => {
        setShow(true);
        setIsSubmitted(false);
    };

    const therapeuticAppendHandler = () => {
        setTherapeutics([...therapeutics, selectTherapeutic]);
    };
    const therapeuticRemoveHandler = (value) => {
        const updatedTherapeutic = therapeutics && therapeutics.filter((m) => m.value !== value);
        setTherapeutics(updatedTherapeutic);
    };
    useEffect(() => {
        setTherapeuticId(therapeutics.map((el) => el.value).toString());
    }, [therapeutics]);

    const handleChecked = () => {
        if (checked === 'checked') {
            setChecked('');
        } else {
            setChecked('checked');
        }
    };

    // Edit Date set on state
    useEffect(() => {
        if (editData != null) {
            const therapeuticRowList = editData.therapeutic_list?.map((el) => ({
                value: el.id,
                label: el.name,
            }));
            setTherapeutics([...therapeuticRowList]);
        }
    }, [editData]);

    register('tac_id', { required: 'Therapeutic action class is required!' });

    const formSubmitHandler = async (data) => {
        setIsSubmitted(true);

        const formData = {
            ...data,
            status: data.status === true ? 1 : 0,
        };
        try {
            const response = await axiosPrivate.post(SAVE_URL, formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!instantCreate) {
                getAllData();
            }

            if (response?.data?.status === 'error') {
                const message = Object.keys(response.data.message);
                message.forEach((key) => {
                    response.data.message[key].map((el) => ToastifyService.dbErrorNotify(el));
                });
                ToastifyService.dbErrorNotify(response?.data?.message);
                setIsSubmitted(false);
            } else if (editData != null) {
                ToastifyService.updateNotify('Data Update Successfull');
                handleClose();
                reset();
            } else {
                ToastifyService.saveNotify('Data Create Successfull');
                handleClose();
                reset();
            }
        } catch (err) {
            if (!err?.response) {
                setErrMsg('Server Not Responding!');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized!');
            } else {
                setErrMsg('Data Inserted Failed!');
            }
            ToastifyService.errorNotify(errMsg);
            setIsSubmitted(false);
        }
    };

    return (
        <>
            {editData != null ? (
                <Button className="btn btn-light actionBtn" variant="primary" onClick={handleShow}>
                    <i className="fas fa-edit" />
                </Button>
            ) : instantCreate ? (
                <Button
                    className="btnBgNonePrimary float-start"
                    variant="light"
                    onClick={handleShow}
                >
                    New
                </Button>
            ) : (
                <Button className="btnInfo" variant="primary" onClick={handleShow}>
                    Create New
                </Button>
            )}

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add/Edit Generic Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Row>
                                <Col lg={3}>
                                    <Form.Label>
                                        Generic Name<span className="invalid">*</span>
                                    </Form.Label>
                                </Col>
                                <Col lg={9}>
                                    <Form.Control
                                        name="name"
                                        id="genericName"
                                        type="text"
                                        placeholder=""
                                        autoComplete="off"
                                        {...register('name', {
                                            required: 'Please enter your name.',
                                        })}
                                        className={errors?.name && 'invalidInput'}
                                    />
                                    {errors.name && (
                                        <span className="invalid">{errors.name?.message}</span>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group>

                        <fieldset className="customFieldset p-2 mb-3">
                            <Row>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <div className="labelRightButton">
                                            <Form.Label className="float-start pe-5">
                                                Therapeutic Action Class
                                            </Form.Label>
                                            <TherapeuticActionForm instantCreate />
                                        </div>
                                        <Row>
                                            <Col lg={10} className="pe-1">
                                                <Select
                                                    options={therapeuticAllData}
                                                    onChange={(e) => setSelectTherapeutic(e)}
                                                    onFocus={() => therapeuticList()}
                                                />
                                            </Col>
                                            <Col lg={2} className="p-0">
                                                <Button
                                                    className="btnSteelBlue"
                                                    variant="primary"
                                                    onClick={() => therapeuticAppendHandler()}
                                                    disabled={!selectTherapeutic}
                                                >
                                                    Add
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Table className="borderDashed" responsive="sm">
                                        <tbody>
                                            {therapeutics.map((item) => (
                                                <tr key={Math.random()}>
                                                    <td>{item.label}</td>
                                                    <td className="text-end">
                                                        <Button
                                                            className="btn btn-light actionBtn"
                                                            variant="primary"
                                                            onClick={() =>
                                                                therapeuticRemoveHandler(item.value)
                                                            }
                                                        >
                                                            <i className="fas fa-trash-alt" />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    {errors.tac_id && (
                                        <span className="invalid">{errors.tac_id?.message}</span>
                                    )}
                                </Col>
                            </Row>
                        </fieldset>

                        <Form.Group className="mb-3">
                            <Row>
                                <Col lg={3}>
                                    <Form.Label>Status</Form.Label>
                                </Col>
                                <Col lg={9}>
                                    <Form.Check
                                        name="status"
                                        id="user-status-switch"
                                        type="switch"
                                        checked={checked}
                                        onClick={handleChecked}
                                        onChange={handleChecked}
                                        {...register('status')}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>

                        <Modal.Footer className="borderTop0 pe-0">
                            <Button className="btnCancel" variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button
                                onClick={handleSubmit(formSubmitHandler)}
                                className="btnSuccess"
                                type="button"
                                variant="success"
                                disabled={isSubmitted}
                            >
                                {editData != null ? 'Update' : 'Create New'}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}
