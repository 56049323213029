/*
 * Author M. Atoar Rahman
 * Title: Layer Chaining
 * Description: Page for Layer Chaining
 * Date: 20/06/2022
 */

import { Button, Card } from 'react-bootstrap';
import { useLayerChaining } from './ContextAPI';
import DataTable from './DataTable';
import FormInfo from './FormInfo';

export default function Index() {
    const { rowData, show, setShow, setRowData, setIsSubmitted } = useLayerChaining();
    const createHander = () => {
        setShow(true);
        setIsSubmitted(false);
        setRowData('');
    };
    const backHander = () => {
        setShow(false);
        setRowData('');
    };
    return (
        <Card>
            <Card.Header className="cardHeader">
                <h3>Layer Chaining</h3>
                {show ? (
                    <div>
                        <Button onClick={() => backHander()} className="btnInfo" variant="primary">
                            Back to List
                        </Button>
                    </div>
                ) : (
                    <div>
                        <Button
                            onClick={() => createHander()}
                            className="btnInfo"
                            variant="primary"
                        >
                            Create New
                        </Button>
                    </div>
                )}
            </Card.Header>
            <Card.Body>
                <DataTable />
                {show && <FormInfo editData={rowData} />}
            </Card.Body>
        </Card>
    );
}
