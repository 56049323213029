import Swal from 'sweetalert2';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

export default function SweetAlertService() {
    const axiosPrivate = useAxiosPrivate();

    const deleteSwal = (props) => {
        const {
            id,
            url,
            beforeTitle,
            beforeText,
            confirmButtonText,
            cancelButtonText,
            afterTitle,
            afterText,
            refreshList,
        } = props;
        Swal.fire({
            title: beforeTitle || 'Are you sure?',
            text: beforeText || "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#950404',
            confirmButtonText: confirmButtonText || 'Yes, delete it!',
            cancelButtonText: cancelButtonText || 'No, cancel!',
            reverseButtons: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await axiosPrivate.post(url, JSON.stringify({ id }), {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });

                    if (response?.data?.status === 'success') {
                        Swal.fire(
                            afterTitle || 'Deleted!',
                            response?.data?.message || afterText || 'Your file has been deleted.',
                            'success'
                        );
                        refreshList();
                    } else {
                        console.log(response);
                        Swal.fire(response?.data?.message, '', 'error');
                    }
                } catch (err) {
                    console.log(err?.response);
                    Swal.fire(err?.response?.statusText, '', 'error');
                }
            }
        });
    };

    return { deleteSwal };
}
