/*
 * Author M. Atoar Rahman
 * Title: Drug Group
 * Description: Page for Drug Group
 * Date: 15/03/2022
 */

import React from 'react';
import { Card } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';
import { DrugGroupProvider } from '../masterSetup/drugGroup/ContextAPI';
import DataTable from '../masterSetup/drugGroup/DataTable';
import FormInfo from '../masterSetup/drugGroup/FormInfo';
import PermissionDenied from './PermissionDenied';

export default function DrugGroup() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['drug-group']) return <PermissionDenied />;

    return (
        <DrugGroupProvider>
            <Card>
                <Card.Header className="cardHeader">
                    {/* Change Name by shariful Hasan 07/06/2022 */}
                    <h3>Administration route</h3>
                    <div>
                        <FormInfo />
                    </div>
                </Card.Header>
                <Card.Body>
                    <DataTable />
                </Card.Body>
            </Card>
        </DrugGroupProvider>
    );
}
