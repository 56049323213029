/*
 * Author M. Atoar Rahman
 * Title: Consumption Report
 * Description: Index for Consumption Report
 * Date: 05/06/2022
 */

import { Card } from 'react-bootstrap';
import { usePoReports } from './ContextAPI';
import FormInfo from './FormInfo';
import PrintIndex from './pdf/PrintIndex';

export default function Index() {
    const { showPdf } = usePoReports();

    return showPdf ? (
        <PrintIndex />
    ) : (
        <Card>
            <Card.Header className="cardHeader">
                <h3>Purchase Order Reports</h3>
            </Card.Header>
            <Card.Body>
                <FormInfo />
            </Card.Body>
        </Card>
    );
}
