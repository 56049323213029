/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Dispensary Internal Request DataTable
 * Description: Dispensary Internal Request DataTable
 * Date: 09/06/2022
 */

import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import useDataTable from '../../../hooks/useDataTable';
import { useInternalRequest } from './ContextAPI';
// import SweetAlertService from '../../../services/SweetAlertService';
// import UrlService from '../../../services/UrlService';
import { layout } from '../../SvgIcon';

export default function DataTable() {
    const { internalRequests, getAllData, setShowModal, setRowData } = useInternalRequest();
    // const { deleteSwal } = SweetAlertService();

    const dataList = useCallback(() => {
        getAllData();
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    // const deleteHandler = (row) => {
    //     deleteSwal({
    //         id: row.id,
    //         url: UrlService.deleteDispensaryInternalRequestUrl(),
    //         refreshList: getAllData,
    //         beforeTitle: 'Are you sure?',
    //         beforeText: "You won't be able to revert this!",
    //         afterTitle: 'Deleted!',
    //         afterText: 'Your file has been deleted.',
    //         confirmButtonText: 'Yes, delete it!',
    //         cancelButtonText: 'No, cancel!',
    //     });
    // };

    const showHander = (row) => {
        setShowModal(true);
        setRowData(row);
    };

    const columns = [
        {
            name: 'Partner',
            selector: (row) => row.partner_name,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Facility',
            selector: (row) => row.facility_name,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Date',
            selector: (row) => row.date,
            format: (row) => moment(row.date).format('DD MMM YYYY'),
            sortable: true,
        },
        {
            name: 'Action',
            selector: (row) => row.action,
            cell: (row) => (
                <>
                    <Button
                        className="btn btn-light actionBtn"
                        variant="primary"
                        onClick={() => showHander(row)}
                    >
                        {layout}
                    </Button>

                    {/* <Button
                        onClick={() => {
                            deleteHandler(row);
                        }}
                        type="button"
                        className="btn btn-light actionBtn"
                    >
                        <i className="fas fa-trash-alt" />
                    </Button> */}
                </>
            ),
        },
    ];

    return useDataTable({ columns, data: internalRequests });
}
