/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-constructed-context-values */
/*
 * Author M. Atoar Rahman
 * Title: Donation Context Context
 * Description: Donation Context Context
 * Date: 15/07/2022
 */

import { createContext, useContext, useState } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import UrlService from '../../../services/UrlService';

// Create Context
const ContextAPI = createContext();

export function useDonation() {
    return useContext(ContextAPI);
}

// Provider Component
export function DonationProvider({ children }) {
    // Dependency
    const axiosPrivate = useAxiosPrivate();

    const [show, setShow] = useState(false);
    const [data, setData] = useState();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [donateData, setDonateData] = useState({});
    const [donateList, setDonateList] = useState({});
    const [rowData, setRowData] = useState({});
    const [rowDataList, setRowDataList] = useState({});
    const [showPdf, setShowPdf] = useState(false);

    const getAllData = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getAllDonationUrl(), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setData(response?.data?.donation_list);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    return (
        <ContextAPI.Provider
            value={{
                show,
                setShow,
                data,
                setData,
                getAllData,
                donateData,
                setDonateData,
                donateList,
                setDonateList,
                isSubmitted,
                setIsSubmitted,
                rowData,
                setRowData,
                rowDataList,
                setRowDataList,
                showPdf,
                setShowPdf,
            }}
        >
            {children}
        </ContextAPI.Provider>
    );
}

export default ContextAPI;
