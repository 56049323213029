/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-constructed-context-values */
/*
 * Author M. Atoar Rahman
 * Title: Dispensary Internal Request Approve Context
 * Description: Dispensary Internal Request Approve Context
 * Date: 12/06/2022
 */

import { createContext, useContext, useReducer, useState } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';

// Initial State
const initialState = {
    internalRequests: [],
};

// Reducer Function
const reducer = (state, action) => {
    switch (action.type) {
        case 'INT_REQ_LIST':
            return {
                ...state,
                internalRequests: action.payload,
            };
        case 'ADD_INT_REQ':
            return {
                ...state,
                internalRequests: [action.payload, ...state.internalRequests],
            };
        case 'EDIT_INT_REQ':
            const updateInternalRequest = action.payload;

            const updateInternalRequests = state.internalRequests.map((internalRequest) => {
                if (internalRequest.id === updateInternalRequest.id) {
                    return updateInternalRequest;
                }
                return internalRequest;
            });
            return {
                ...state,
                internalRequests: updateInternalRequests,
            };

        default:
            return state;
    }
};

// Create Context
const ContextAPI = createContext(initialState);

export function useInternalRequest() {
    return useContext(ContextAPI);
}

// Provider Component
export function DispensaryInternalRequestApproveProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [rowData, setRowData] = useState({});
    const [receiveData, setReceiveData] = useState({});
    const [approveList, setApproveList] = useState({});

    // Dependency
    const axiosPrivate = useAxiosPrivate();

    // API Function: Fatch All Data
    const getAllData = async () => {
        try {
            const response = await axiosPrivate.get(
                UrlService.getAllDispensaryInternalRequestUrl(),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            dispatch({
                type: 'INT_REQ_LIST',
                payload: response?.data?.request_info,
            });
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    // API Function: Add/Edit Data
    const addOrUodateData = async (data, id) => {
        try {
            await axiosPrivate.post(
                id
                    ? UrlService.approveDispensaryInternalRequestUrl(id)
                    : UrlService.approveDispensaryInternalRequestUrl(),
                JSON.stringify(data),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (id != null) {
                ToastifyService.updateNotify('Data Update Successfull');
            } else {
                ToastifyService.saveNotify('Data Create Successfull');
            }
            await getAllData();
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else if (id) {
                console.log('Data Update Failed!');
            } else {
                console.log('Data Insert Failed!');
            }
        }
    };

    // Add Internal Requests
    const addInternalRequest = (internalRequest) => {
        addOrUodateData(internalRequest);
        dispatch({
            type: 'ADD_INT_REQ',
            payload: internalRequest,
        });
    };

    // Edit Internal Requests
    const editInternalRequest = (internalRequest, id) => {
        addOrUodateData(internalRequest, id);
        dispatch({
            type: 'EDIT_INT_REQ',
            payload: internalRequest,
        });
    };

    return (
        <ContextAPI.Provider
            value={{
                internalRequests: state.internalRequests,
                getAllData,
                addInternalRequest,
                editInternalRequest,
                show,
                setShow,
                showModal,
                setShowModal,
                rowData,
                setRowData,
                receiveData,
                setReceiveData,
                approveList,
                setApproveList,
            }}
        >
            {children}
        </ContextAPI.Provider>
    );
}

export default ContextAPI;
