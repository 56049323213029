/*
 * Author M. Atoar Rahman
 * Date: 15/03/2022
 * Time: 11:40 AM
 */

import React from 'react';
import { Card } from 'react-bootstrap';
import { useAuth } from '../../../contexts/AuthContext';
import NotFound from '../../pages/NotFound';
import { DataProvider } from './ContextAPI';
import FormInfo from './FormInfo';
import List from './List';

export default function Index() {
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['permission-management/role-info']) return <NotFound />;
    return (
        <DataProvider>
            <Card>
                <Card.Header className="cardHeader">
                    <h3>Role Information</h3>
                    <div>
                        {route_permissions['permission-management/role-info'].add && <FormInfo />}
                    </div>
                </Card.Header>
                <Card.Body>
                    <List />
                </Card.Body>
            </Card>
        </DataProvider>
    );
}
