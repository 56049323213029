/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { toArray } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Tab, Table, Tabs } from 'react-bootstrap';
import CommonApiService from '../../../services/CommonApiService';
import { useApproval } from './ContextAPI';
import ShowTable from './ShowTable';

export default function ShowModel() {
    const {
        show,
        setShow,
        rowData,
        addPartnerStockDistributionApproval,
        receiveData,
        setReceiveData,
        receiveList,
        setReceiveList,
    } = useApproval();
    const { drugCategory, getDrugCategory } = CommonApiService();
    const [page, setPage] = useState(1);
    const [pageLength, setPageLength] = useState(0);
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        rowData?.records?.map((el) => {
            const itemdata = {};
            itemdata.drug_id = el.drug_id;
            itemdata.supplied_qty = el.supplied_qty;
            itemdata.available_qty = el.available_qty;
            itemdata.approved_supply_qty =
                Number(el.available_qty) > Number(el.supplied_qty)
                    ? el.supplied_qty
                    : el.available_qty;
            // itemdata.approved_supply_qty = el.approved_supply_qty > 0 ? el.approved_supply_qty : el.supplied_qty;

            const list = receiveData;
            list[itemdata.drug_id] = itemdata;
            if (itemdata.drug_id === '') {
                delete list[itemdata.drug_id];
            }

            setReceiveData(list);

            const arrOfObj = toArray(list);
            setReceiveList(arrOfObj);
            return receiveData;
        });
    }, []);

    // console.log({ rowData });

    const dataList = useCallback(() => {
        getDrugCategory(); //  Drug Category
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    useEffect(() => {
        setPageLength(drugCategory?.length);
    }, [drugCategory]);

    // Closing the Model
    const handleClose = () => {
        setShow(false);
    };

    const tableDataDisplay = () =>
        drugCategory?.map((item) => (
            <Tab key={item.id} eventKey={item.id} title={item.name}>
                <ShowTable data={rowData?.records?.filter((el) => el.category_id == item.id)} />
            </Tab>
        ));

    const submitHandler = () => {
        setIsSubmitted(true);
        const data = {
            d1_master_id: rowData.d1_master_id,
            distribution_type: 'd1',
            distribution_type_id: rowData.d1_master_id,
            requisition: receiveList,
        };
        addPartnerStockDistributionApproval(data);
    };

    return (
        <Modal className="modalWidth80" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Partner Stock Distribution Approval</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col>
                            <Table bordered size="sm">
                                <thead className="table-light">
                                    <tr>
                                        <th>Facility Name</th>
                                        <th>Month</th>
                                        <th>Year</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{rowData?.facility_name}</td>
                                        <td>{rowData?.month}</td>
                                        <td>{rowData?.year}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Tabs
                            activeKey={page}
                            transition={false}
                            id="noanim-tab-example"
                            className="mb-3 ps-2"
                            // eslint-disable-next-line radix
                            onSelect={(k) => setPage(parseInt(k))}
                        >
                            {tableDataDisplay()}
                        </Tabs>
                    </Row>

                    <Modal.Footer className="borderTop0 pe-0">
                        <Button className="btnCancel" variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            className="btnInfo me-2 ms-2"
                            variant="primary"
                            style={{ minWidth: '6rem' }}
                            disabled={page === 1}
                            onClick={() => {
                                setPage((currPage) => currPage - 1);
                            }}
                        >
                            Prev
                        </Button>
                        <Button
                            type="button"
                            className={`btnSuccess ${page === pageLength && 'd-none'}`}
                            variant="success"
                            style={{ minWidth: '6rem' }}
                            onClick={() => setPage((currPage) => currPage + 1)}
                        >
                            Next
                        </Button>
                        {rowData.status === 'd1_supplied' && (
                            <Button
                                onClick={submitHandler}
                                className={`btnSuccess ${page !== pageLength && 'd-none'}`}
                                type="button"
                                variant="success"
                                style={{ minWidth: '6rem' }}
                                disabled={isSubmitted}
                            >
                                Submit
                            </Button>
                        )}
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
