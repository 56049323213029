/* eslint-disable eqeqeq */
/*
 * Author M. Atoar Rahman
 * Title: PO Report DataTable
 * Description: PO Report DataTable
 * Date: 15/03/2022
 */

import moment from 'moment';
import useDataTable from '../../../hooks/useDataTable';

export default function PoTableData({ data }) {
    const tableStyle = {
        paddingLeft: '5px',
        paddingRight: '5px',
        lineHeight: '33px',
    };

    console.log({ data });

    function ItemTitle() {
        return <span className="ps-2">Supplier Name</span>;
    }

    function Title() {
        return (
            <table className="table table-bordered mb-0 text-center" style={tableStyle}>
                <thead>
                    <tr>
                        <th
                            style={{
                                minWidth: '120px',
                                maxWidth: '120px',
                                lineHeight: '33px',
                            }}
                            className="m-0"
                        >
                            Supplied Date
                        </th>
                        <th
                            style={{
                                minWidth: '120px',
                                maxWidth: '120px',
                            }}
                            className="m-0"
                        >
                            PO No.
                        </th>
                        <th
                            style={{
                                minWidth: '120px',
                                maxWidth: '120px',
                            }}
                            className="m-0"
                        >
                            PO Date
                        </th>
                        <th
                            style={{
                                minWidth: '220px',
                                maxWidth: '220px',
                            }}
                            className="m-0"
                        >
                            Drug Name
                        </th>
                        <th
                            style={{
                                minWidth: '120px',
                                maxWidth: '120px',
                            }}
                            className="m-0"
                        >
                            Qty
                        </th>
                    </tr>
                </thead>
            </table>
        );
    }

    const columns = [
        {
            name: <ItemTitle />,
            cell: (row) => <span className="ps-2">{row.supplier_name}</span>,
            sortable: true,
            wrap: true,
            width: '220px',
            fixed: true,
        },
        {
            name: <Title />,
            cell: (row) => (
                <table className="table table-bordered mb-0 text-center" style={tableStyle}>
                    <tbody>
                        {row.purchase_orders.length > 0 &&
                            row.purchase_orders?.map((item) => (
                                <tr key={item.id} style={{ borderTopWidth: '0px' }}>
                                    <td
                                        style={{
                                            minWidth: '120px',
                                            maxWidth: '120px',
                                            verticalAlign: 'middle',
                                        }}
                                    >
                                        {moment(item.purchase_order_supplied_date).format(
                                            'DD MMM YYYY'
                                        )}
                                    </td>
                                    <td
                                        style={{
                                            minWidth: '120px',
                                            maxWidth: '120px',
                                            verticalAlign: 'middle',
                                        }}
                                    >
                                        {moment(item.purshase_order_date).format('DD MMM YYYY')}
                                    </td>
                                    <td
                                        style={{
                                            minWidth: '120px',
                                            maxWidth: '120px',
                                            verticalAlign: 'middle',
                                        }}
                                    >
                                        {item.purshase_order_no}
                                    </td>
                                    <td
                                        style={{
                                            paddingTop: '0px',
                                            paddingRight: '0px',
                                            paddingBottom: '0px',
                                            paddingLeft: '0px',
                                        }}
                                    >
                                        {item.purchased_items.length > 0 &&
                                            item.purchased_items.map((el) => (
                                                <table
                                                    key={el.id}
                                                    className="table table-bordered mb-0 text-center"
                                                    style={tableStyle}
                                                >
                                                    <tbody style={{ borderWidth: '0px' }}>
                                                        <tr style={{ borderBottomWidth: '1px' }}>
                                                            <td
                                                                style={{
                                                                    minWidth: '220px',
                                                                    maxWidth: '220px',
                                                                    textAlign: 'left',
                                                                    verticalAlign: 'middle',
                                                                }}
                                                            >
                                                                {el.drug_name}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    minWidth: '120px',
                                                                    maxWidth: '120px',
                                                                    verticalAlign: 'middle',
                                                                }}
                                                            >
                                                                {el.purchase_qty}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            ))}
                                        {item.purchased_items.length === 0 && (
                                            <table
                                                className="table table-bordered mb-0 text-center"
                                                style={tableStyle}
                                            >
                                                <tbody style={{ borderWidth: '0px' }}>
                                                    <tr style={{ borderTopWidth: '0px' }}>
                                                        <td
                                                            style={{
                                                                minWidth: '220px',
                                                                maxWidth: '220px',
                                                            }}
                                                        >
                                                            0
                                                        </td>
                                                        <td
                                                            style={{
                                                                wminWidth: '120px',
                                                                maxWidth: '120px',
                                                            }}
                                                        >
                                                            0
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        {row.purchase_orders.length === 0 && (
                            <tr style={{ borderTopWidth: '0px' }}>
                                <td
                                    style={{
                                        minWidth: '120px',
                                        maxWidth: '120px',
                                    }}
                                >
                                    0
                                </td>
                                <td
                                    style={{
                                        minWidth: '120px',
                                        maxWidth: '120px',
                                    }}
                                >
                                    0
                                </td>
                                <td
                                    style={{
                                        minWidth: '120px',
                                        maxWidth: '120px',
                                    }}
                                >
                                    0
                                </td>
                                <td
                                    style={{
                                        paddingTop: '0px',
                                        paddingRight: '0px',
                                        paddingBottom: '0px',
                                        paddingLeft: '0px',
                                    }}
                                >
                                    <table
                                        className="table table-bordered mb-0 text-center"
                                        style={tableStyle}
                                    >
                                        <tbody style={{ borderWidth: '0px' }}>
                                            <tr style={{ borderTopWidth: '0px' }}>
                                                <td
                                                    style={{
                                                        minWidth: '220px',
                                                        maxWidth: '220px',
                                                    }}
                                                >
                                                    0
                                                </td>
                                                <td
                                                    style={{
                                                        minWidth: '120px',
                                                        maxWidth: '120px',
                                                    }}
                                                >
                                                    0
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            ),
        },
    ];

    const search_column = { name: 'Name' };
    return useDataTable({
        columns,
        data,
        search_column,
        pagination: false,
        cellPadding: '0px',
        borderWidth: '0px',
    });
}
