/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Date: 15/03/2022
 * Time: 11:40 AM
 */

import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useAuth } from '../../../contexts/AuthContext';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { useDispensary } from './ContextAPI';

export default function FormInfo({ editData = null, instantCreate = false }) {
    // Local State
    const [partnerData, setPartnerData] = useState([]);
    const [facilityData, setFacilityData] = useState([]);
    const [errMsg, setErrMsg] = useState('');
    const [show, setShow] = useState(false);

    const { auth } = useAuth();
    const partnerId = auth?.employee_info?.partner_id ? auth?.employee_info?.partner_id : null;
    const partnerName = auth?.employee_info?.partner_name
        ? auth?.employee_info?.partner_name
        : null;

    const {
        register,
        formState: { errors },
        reset,
        handleSubmit,
        control,
        watch,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
        defaultValues: useMemo(
            () => ({
                ...editData,
                // eslint-disable-next-line eqeqeq
                status: editData && editData?.status == '1' ? 1 : 0,
                name: editData && {
                    label: editData?.name,
                    value: editData?.name,
                },
                partner_id: partnerId
                    ? {
                          label: partnerName,
                          value: partnerId,
                      }
                    : editData
                    ? {
                          label: editData?.partner?.name,
                          value: editData?.partner_id,
                      }
                    : partnerData,
                facility_id: editData && {
                    label: editData?.facility?.name,
                    value: editData?.facility_id,
                },
            }),
            [editData]
        ),
    });

    // Reset default values
    useEffect(() => {
        reset({
            ...editData,
            // eslint-disable-next-line eqeqeq
            status: editData && editData?.status == '1' ? 1 : 0,
            name: editData && {
                label: editData?.name,
                value: editData?.name,
            },
            partner_id: partnerId
                ? {
                      label: partnerName,
                      value: partnerId,
                  }
                : editData
                ? {
                      label: editData?.partner?.name,
                      value: editData?.partner_id,
                  }
                : partnerData,
            facility_id: editData && {
                label: editData?.facility?.name,
                value: editData?.facility_id,
            },
        });
    }, [editData]);

    React.useEffect(() => {
        const subscription = watch((value, { name, type }) => console.log(value, name, type));
        return () => subscription.unsubscribe();
    }, [watch]);

    // API URL
    let SAVE_URL = '';
    if (!editData) {
        SAVE_URL = UrlService.saveDispensaryUrl();
    } else {
        SAVE_URL = UrlService.updateDispensaryUrl(editData.id);
    }
    // Dependency
    const axiosPrivate = useAxiosPrivate();
    const { getAllData, isSubmitted, setIsSubmitted } = useDispensary();

    // Status Check Handling
    let checkeStatus;
    // eslint-disable-next-line eqeqeq
    if (editData != null && editData.status == '1') {
        checkeStatus = 'checked';
    } else if (editData === null) {
        checkeStatus = 'checked';
    } else {
        checkeStatus = '';
    }

    const [checked, setChecked] = useState(checkeStatus);

    // Get All Partner From Partner Table
    const partnerList = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getAllPartnerUrl(), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const partnerInfo = response?.data?.partner_info.map((el) => ({
                value: el.id,
                label: el.name,
            }));
            setPartnerData(partnerInfo);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding !!!!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };
    // Get All Facility From Facility Table
    const facilityList = async (id) => {
        try {
            const response = await axiosPrivate.get(UrlService.getFacilityByPartnerIdUrl(id), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const facilityInfo = response?.data?.facility_info.map((el) => ({
                value: el.id,
                label: el.name,
            }));
            setFacilityData(facilityInfo);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding !!!!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    // For Partner Login
    useEffect(async () => {
        try {
            const response = await axiosPrivate.get(
                UrlService.getFacilityByPartnerIdUrl(partnerId),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            const facilityInfo = response?.data?.facility_info.map((el) => ({
                value: el.id,
                label: el.name,
            }));
            setFacilityData(facilityInfo);
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding !!!!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    }, [partnerId]);

    // Closing the Model
    const handleClose = () => {
        getAllData();
        setShow(false);
    };

    // Show the Model
    const handleShow = () => {
        setShow(true);
        partnerList();
        setIsSubmitted(false);
    };

    // Show the Model
    const handleChecked = () => {
        if (checked === 'checked') {
            setChecked('');
        } else {
            setChecked('checked');
        }
    };

    const dptNameData = [
        { label: 'OPDOut-patient dispensary', value: 'OPDOut-patient dispensary' },
        { label: 'OPDEmergency', value: 'OPDEmergency' },
        { label: 'OPDANC clinic', value: 'OPDANC clinic' },
        { label: 'OPDPNC clinic', value: 'OPDPNC clinic' },
        { label: 'OPDFP clinic', value: 'OPDFP clinic' },
        { label: 'OPDGBV', value: 'OPDGBV' },
        { label: 'OPDLaboratory', value: 'OPDLaboratory' },
        { label: 'OPDMale ward', value: 'OPDMale ward' },
        { label: 'OPDFemale ward', value: 'OPDFemale ward' },
        { label: 'OPDPaediatrics', value: 'OPDPaediatrics' },
        { label: 'OPDStabilization center', value: 'OPDStabilization center' },
        { label: 'OPDDelivery, postnatal ward', value: 'OPDDelivery, postnatal ward' },
        { label: 'OPDIsolation ward', value: 'OPOPDIsolation wardD' },
    ];

    const formSubmitHandler = async (data) => {
        setIsSubmitted(true);

        const formData = {
            name: editData ? data.name.value : data.name,
            partner_id: data.partner_id.value,
            facility_id: data.facility_id.value,
            status: data.status === true ? 1 : 0,
        };
        try {
            const response = await axiosPrivate.post(SAVE_URL, formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response?.data?.status === 'error') {
                const message = Object.keys(response.data.message);
                message.forEach((key) => {
                    response.data.message[key].map((el) => ToastifyService.dbErrorNotify(el));
                });
                ToastifyService.dbErrorNotify(response?.data?.message);
                setIsSubmitted(false);
            } else if (editData != null) {
                ToastifyService.updateNotify('Data Update Successfull');
                handleClose();
                reset();
            } else {
                ToastifyService.saveNotify('Data Create Successfull');
                handleClose();
                reset();
            }
        } catch (err) {
            if (!err?.response) {
                setErrMsg('Server Not Responding!');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized!');
            } else {
                setErrMsg('Save Failed!');
            }
            setIsSubmitted(false);
        }
    };

    return (
        <>
            {editData != null ? (
                <Button className="btn btn-light actionBtn" variant="primary" onClick={handleShow}>
                    <i className="fas fa-edit" />
                </Button>
            ) : instantCreate ? (
                <Button className="btnBgNonePrimary" variant="light" onClick={handleShow}>
                    New
                </Button>
            ) : (
                <Button className="btnInfo" variant="primary" onClick={handleShow}>
                    Create New
                </Button>
            )}

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add/Edit Dispensary Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            {!editData && (
                                <Row>
                                    <Col lg={3}>
                                        <Form.Label>
                                            Dept. Name<span className="required">*</span>
                                        </Form.Label>
                                    </Col>
                                    <Col lg={9}>
                                        <Form.Check
                                            name="name"
                                            inline
                                            label="OPDOut-patient dispensary"
                                            value="OPDOut-patient dispensary"
                                            {...register('name', {
                                                required: 'Please select your name.',
                                            })}
                                            defaultChecked={
                                                editData && !!editData.name.includes('OPD')
                                            }
                                        />
                                        <Form.Check
                                            name="name"
                                            inline
                                            label="OPDEmergency"
                                            value="OPDEmergency"
                                            {...register('name', {
                                                required: 'Please select your name.',
                                            })}
                                            defaultChecked={
                                                editData && !!editData.name.includes('IPD')
                                            }
                                        />
                                        <Form.Check
                                            name="name"
                                            inline
                                            label="OPDANC clinic"
                                            value="OPDANC clinic"
                                            {...register('name', {
                                                required: 'Please select your name.',
                                            })}
                                            defaultChecked={
                                                editData && !!editData.name.includes('ER')
                                            }
                                        />

                                        <Form.Check
                                            name="name"
                                            inline
                                            label="OPDFP clinic"
                                            value="OPDFP clinic"
                                            {...register('name', {
                                                required: 'Please select your name.',
                                            })}
                                            defaultChecked={
                                                editData && !!editData.name.includes('QC')
                                            }
                                        />
                                        <Form.Check
                                            name="name"
                                            inline
                                            label="OPDGBV"
                                            value="OPDGBV"
                                            {...register('name', {
                                                required: 'Please select your name.',
                                            })}
                                            defaultChecked={
                                                editData && !!editData.name.includes('QC')
                                            }
                                        />
                                        <Form.Check
                                            name="name"
                                            inline
                                            label="OPDLaboratory"
                                            value="OPDLaboratory"
                                            {...register('name', {
                                                required: 'Please select your name.',
                                            })}
                                            defaultChecked={
                                                editData && !!editData.name.includes('QC')
                                            }
                                        />
                                        <Form.Check
                                            name="name"
                                            inline
                                            label="OPDMale ward"
                                            value="OPDMale ward"
                                            {...register('name', {
                                                required: 'Please select your name.',
                                            })}
                                            defaultChecked={
                                                editData && !!editData.name.includes('QC')
                                            }
                                        />

                                        <Form.Check
                                            name="name"
                                            inline
                                            label="OPDFemale ward"
                                            value="OPDFemale ward"
                                            {...register('name', {
                                                required: 'Please select your name.',
                                            })}
                                            defaultChecked={
                                                editData && !!editData.name.includes('QC')
                                            }
                                        />
                                        <Form.Check
                                            name="name"
                                            inline
                                            label="OPDPaediatrics"
                                            value="OPDPaediatrics"
                                            {...register('name', {
                                                required: 'Please select your name.',
                                            })}
                                            defaultChecked={
                                                editData && !!editData.name.includes('QC')
                                            }
                                        />
                                        <Form.Check
                                            name="name"
                                            inline
                                            label="OPDStabilization center"
                                            value="OPDStabilization center"
                                            {...register('name', {
                                                required: 'Please select your name.',
                                            })}
                                            defaultChecked={
                                                editData && !!editData.name.includes('QC')
                                            }
                                        />
                                        <Form.Check
                                            name="name"
                                            inline
                                            label="OPDDelivery, postnatal ward"
                                            value="OPDDelivery, postnatal ward"
                                            {...register('name', {
                                                required: 'Please select your name.',
                                            })}
                                            defaultChecked={
                                                editData && !!editData.name.includes('QC')
                                            }
                                        />
                                        <Form.Check
                                            name="name"
                                            inline
                                            label="OPDIsolation ward"
                                            value="OPOPDIsolation ward"
                                            {...register('name', {
                                                required: 'Please select your name.',
                                            })}
                                            defaultChecked={
                                                editData && !!editData.name.includes('QC')
                                            }
                                        />

                                        <div className="w-100">
                                            {errors.name && (
                                                <span className="invalid">
                                                    {errors.name?.message}
                                                </span>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            )}
                            {editData && (
                                <Row>
                                    <Col lg={3}>
                                        <Form.Label>
                                            Name<span className="required">*</span>
                                        </Form.Label>
                                    </Col>
                                    <Col lg={9}>
                                        <Controller
                                            name="name"
                                            id="name"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    classNamePrefix={errors?.name && 'invalidInput'}
                                                    options={dptNameData}
                                                />
                                            )}
                                            rules={{ required: 'Please select your partner.' }}
                                        />

                                        {errors.name && (
                                            <span className="invalid">{errors.name?.message}</span>
                                        )}
                                    </Col>
                                </Row>
                            )}
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Row>
                                <Col lg={3}>
                                    <Form.Label>
                                        Partner<span className="required">*</span>
                                    </Form.Label>
                                </Col>
                                <Col lg={9}>
                                    <Controller
                                        name="partner_id"
                                        id="partnerId"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                classNamePrefix={
                                                    errors?.partner_id && 'invalidInput'
                                                }
                                                options={partnerData}
                                                onBlur={() => facilityList(field?.value?.value)}
                                                isDisabled={partnerId}
                                            />
                                        )}
                                        rules={{ required: 'Please select your partner.' }}
                                    />

                                    {errors.partner_id && (
                                        <span className="invalid">
                                            {errors.partner_id?.message}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Row>
                                <Col lg={3}>
                                    <Form.Label>
                                        Facility<span className="required">*</span>
                                    </Form.Label>
                                </Col>
                                <Col lg={9}>
                                    <Controller
                                        name="facility_id"
                                        id="facilityId"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                classNamePrefix={
                                                    errors?.facility_id && 'invalidInput'
                                                }
                                                options={facilityData}
                                            />
                                        )}
                                        rules={{ required: 'Please select your facility.' }}
                                    />

                                    {errors.facility_id && (
                                        <span className="invalid">
                                            {errors.facility_id?.message}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group>

                        {/* <Form.Group className="mb-3">
                            <Row>
                                <Col lg={3}>
                                    <Form.Label>
                                        Latitude<span className="required">*</span>
                                    </Form.Label>
                                </Col>
                                <Col lg={9}>
                                    <Form.Control
                                        name="lat"
                                        id="dispensaryLat"
                                        type="text"
                                        placeholder=""
                                        autoComplete="off"
                                        {...register('lat', {
                                            required: 'Please enter your latitude.',
                                        })}
                                        className={errors?.lat && 'invalidInput'}
                                    />
                                    {errors.lat && (
                                        <span className="invalid">{errors.lat?.message}</span>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group> */}

                        {/* <Form.Group className="mb-3">
                            <Row>
                                <Col lg={3}>
                                    <Form.Label>
                                        Longitude<span className="required">*</span>
                                    </Form.Label>
                                </Col>
                                <Col lg={9}>
                                    <Form.Control
                                        name="lng"
                                        id="dispensaryLng"
                                        type="text"
                                        placeholder=""
                                        autoComplete="off"
                                        {...register('lng', {
                                            required: 'Please enter your longitude.',
                                        })}
                                        className={errors?.lng && 'invalidInput'}
                                    />
                                    {errors.lng && (
                                        <span className="invalid">{errors.lng?.message}</span>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group> */}

                        <Form.Group className="mb-3">
                            <Row>
                                <Col lg={3}>
                                    <Form.Label>Status</Form.Label>
                                </Col>
                                <Col lg={9}>
                                    <Form.Check
                                        name="status"
                                        id="user-status-switch"
                                        type="switch"
                                        checked={checked}
                                        onClick={handleChecked}
                                        onChange={handleChecked}
                                        {...register('status')}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>

                        <Modal.Footer className="borderTop0 pe-0">
                            <Button className="btnCancel" variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button
                                onClick={handleSubmit(formSubmitHandler)}
                                className="btnSuccess"
                                type="button"
                                variant="success"
                                disabled={isSubmitted}
                            >
                                {editData != null ? 'Update' : 'Create New'}
                            </Button>
                        </Modal.Footer>
                        {errMsg && <p className="error">{errMsg}</p>}
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}
