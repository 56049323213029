/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author: M. K. Tanjin Sarker
 * Date: 23/04/2022
 * Time: 2:40 PM
 */

import { useCallback, useEffect } from 'react';
import { Badge, Button } from 'react-bootstrap';
import useDataTable from '../../../hooks/useDataTable';
import { useData } from './ContextAPI';

export default function List({ editHander }) {
    const { data, getAllData } = useData();
    const dataList = useCallback(() => {
        getAllData();
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    const columns = [
        {
            name: 'Name',
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: 'Parent Menu',
            selector: (row) => row.parent_menu?.name ?? '#',
            sortable: true,
        },
        {
            name: 'Route',
            selector: (row) => row.route,
            sortable: true,
        },
        {
            name: 'Icon',
            selector: (row) => row.icon,
            sortable: true,
        },
        {
            name: 'Other Access',
            selector: (row) =>
                row.extra_route.map((el) => (
                    <p key={el.id} style={{ marginBottom: '0rem' }}>
                        {el.name}
                    </p>
                )),
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row) => row.status,
            cell: (row) =>
                row.status == 1 ? (
                    <Badge className="badgeActive" bg="success">
                        Active
                    </Badge>
                ) : (
                    <Badge className="badgeInactive" bg="success">
                        Inactive
                    </Badge>
                ),
        },
        {
            name: 'Action',
            selector: (row) => row.action,
            cell: (row) => (
                <Button
                    className="btn btn-light actionBtn"
                    variant="primary"
                    onClick={() => editHander(row)}
                >
                    <i className="fas fa-edit" />
                </Button>
            ),
        },
    ];
    const search_column = {
        name: 'Name',
        'parent_menu.name ': 'Parent Menu',
        route: 'Route',
    };
    return useDataTable({ columns, data, search_column });
}
