/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import useDataTable from '../../../hooks/useDataTable';

export default function ShowTable({ data }) {
    const columns = [
        {
            name: 'Item Name',
            selector: (row) => row.drug_name,
            sortable: true,
            wrap: true,
            grow: 3,
        },
        {
            name: 'Earliest date of Expiry of physical stocks',
            selector: (row) => row.earliest_expire_date,
            format: (row) => moment(row.earliest_expire_date).format('DD MMM YYYY'),
            sortable: true,
            grow: 2,
        },
        {
            name: 'Requested Qty',
            selector: (row) => row.approved_request_qty,
            sortable: true,
        },
        {
            name: 'Supplied Qty',
            selector: (row) => row.approved_supply_qty,
            sortable: true,
        },
        {
            name: 'Received Qty',
            selector: (row) => row.receive_qty,
            sortable: true,
        },
        {
            name: 'Rejected Qty',
            selector: (row) => row.rejected_qty,
            sortable: true,
        },
        {
            name: 'Rejected Reason',
            selector: (row) => row.rejected_reason,
            sortable: true,
        },
    ];

    const search_column = {
        drug_name: 'Name',
    };
    return useDataTable({ columns, data, search_column, pagination: false });
}
