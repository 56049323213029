/*
 * Author M. Atoar Rahman
 * Title: Dispensary Consumption Tally
 * Description: Page for Dispensary Consumption Tally
 * Date: 15/03/2022
 */

import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { ConsumptionTallyProvider } from '../misReports/consumptionTally/ContextAPI';
import Index from '../misReports/consumptionTally/Index';
import PermissionDenied from './PermissionDenied';

export default function ConsumptionTally() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['consumption-tally']) return <PermissionDenied />;

    return (
        <ConsumptionTallyProvider>
            <Index />
        </ConsumptionTallyProvider>
    );
}
