/*
 * Author M. Atoar Rahman
 * Title: Dispensary Info
 * Description: Page for Dispensary Info
 * Date: 15/03/2022
 */

import { Card } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';
import { DispensaryProvider } from '../masterSetup/dispensaryInfo/ContextAPI';
import DataTable from '../masterSetup/dispensaryInfo/DataTable';
import FormInfo from '../masterSetup/dispensaryInfo/FormInfo';
import PermissionDenied from './PermissionDenied';

export default function DispensaryInfo() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions.dispensary) return <PermissionDenied />;

    return (
        <DispensaryProvider>
            <Card>
                <Card.Header className="cardHeader">
                    {/* Change Name by shariful Hasan 12/06/2022 */}
                    <h3>Facilities Department Information</h3>
                    <div>
                        <FormInfo />
                    </div>
                </Card.Header>
                <Card.Body>
                    <DataTable />
                </Card.Body>
            </Card>
        </DispensaryProvider>
    );
}
