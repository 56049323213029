import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { StockRegisterProvider } from '../partner/stockRegister/ContextAPI';
import Index from '../partner/stockRegister/Index';
import PermissionDenied from './PermissionDenied';

export default function PartnerStockRegister() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['partner-stock-register']) return <PermissionDenied />;

    return (
        <StockRegisterProvider>
            <Index />
        </StockRegisterProvider>
    );
}
