/*
 * Author M. Atoar Rahman
 * Title: Purchase Order
 * Description: Index for Purchase Order
 * Date: 30/04/2022
 */

import { Button, Card } from 'react-bootstrap';
import { usePurchaseOrder } from './ContextAPI';
import DataTable from './DataTable';
import FormInfo from './FormInfo';
import ShowModel from './ShowModel';

export default function Index() {
    const { show, setShow, rowData, setRowData } = usePurchaseOrder();
    const createHander = () => {
        setShow(true);
        setRowData('');
    };
    const backHander = () => {
        setShow(false);
        setRowData('');
    };
    return (
        <>
            <Card>
                <Card.Header className="cardHeader">
                    <h3>Purchase Order</h3>
                    {show ? (
                        <div>
                            <Button
                                onClick={() => backHander()}
                                className="btnInfo"
                                variant="primary"
                            >
                                Back to List
                            </Button>
                        </div>
                    ) : (
                        <div>
                            <Button
                                onClick={() => createHander()}
                                className="btnInfo"
                                variant="primary"
                            >
                                New Purchase
                            </Button>
                        </div>
                    )}
                </Card.Header>
                <Card.Body>{show ? <FormInfo editData={rowData} /> : <DataTable />}</Card.Body>
            </Card>
            <ShowModel />
        </>
    );
}
