/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Dispensary Consumption Tally
 * Description: Dispensary Consumption Tally Form: Create and Update
 * Date: 26/04/2022
 */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useAuth } from '../../../contexts/AuthContext';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import CommonApiService from '../../../services/CommonApiService';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { useConsumptionTally } from './ContextAPI';

export default function FormInfo({ editData = null }) {
    const { setShow, setData, setShowPdf } = useConsumptionTally();
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    const partnerId = auth?.employee_info?.partner_id;
    const partnerName = auth?.employee_info?.partner_name;
    const facilityId = auth?.employee_info?.facility_id;
    const facilityName = auth?.employee_info?.facility_name;
    const dispensaryId = auth?.employee_info?.dispensary_id;
    const dispensaryName = auth?.employee_info?.dispensary_name;

    // Local State
    const [partnerData, setPartnerData] = useState();
    const [facilityData, setFacilityData] = useState();
    const [dispensaryData, setDispensaryData] = useState();
    // const [isSubmitted, setIsSubmitted] = useState(false);

    const {
        // register,
        formState: { errors },
        reset,
        resetField,
        handleSubmit,
        control,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
        defaultValues: useMemo(
            () => ({
                partner_id: partnerId && {
                    label: partnerName,
                    value: partnerId,
                },
                facility_id: facilityId && {
                    label: facilityName,
                    value: facilityId,
                },
                dispensary_id: dispensaryId && {
                    label: dispensaryName,
                    value: dispensaryId,
                },
            }),
            []
        ),
    });

    const {
        partner,
        getPartner,
        facilityByPartner,
        getFacilityByPartner,
        dispensaryByFacility,
        getDispensaryByFacility,
    } = CommonApiService();

    const dataList = useCallback(() => {
        getPartner(); // Partner
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList, editData]);

    useEffect(() => {
        if (partnerId) {
            getFacilityByPartner(partnerId);
        }
        if (facilityId) {
            getDispensaryByFacility(facilityId);
        }
    }, []);

    useEffect(() => {
        // Partner
        setPartnerData(
            partner?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
        // Facility
        setFacilityData(
            facilityByPartner?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
        // Dispensary
        setDispensaryData(
            dispensaryByFacility?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
    }, [partner, facilityByPartner, dispensaryByFacility]);

    // const handleClose = () => {
    //     setShow(false);
    //     setData('');
    // };

    const formSubmitHandler = async (data) => {
        const formData = {
            ...data,
            partner_id: data.partner_id.value,
            dispensary_id: data.dispensary_id.value,
            facility_id: data.facility_id.value,
            date: data.date.toLocaleDateString('sv-SE'),
        };

        // console.log({ formData });

        try {
            const response = await axiosPrivate.post(
                UrlService.saveConsumptionTallyUrl(),
                JSON.stringify(formData),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            setData(response.data);
            setShowPdf(true);
            if (response?.data?.status === 'error') {
                const message = Object.keys(response.data.msg);
                message.forEach((key) => {
                    response.data.message[key].map((el) => ToastifyService.dbErrorNotify(el));
                });
                ToastifyService.dbErrorNotify(response?.data?.message);
            } else {
                setShow(false);
                reset();
            }
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Insert Failed!');
            }
        }
    };

    return (
        <Form>
            <Row>
                <Col lg={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>
                            Partner Agency<span className="invalid">*</span>
                        </Form.Label>
                        <Controller
                            name="partner_id"
                            id="partner_id"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    classNamePrefix={errors?.partner_id && 'invalidInput'}
                                    options={partnerData}
                                    onBlur={async () => {
                                        await resetField('facility_id');
                                        await resetField('dispensary_id');
                                        await getFacilityByPartner(field?.value?.value);
                                    }}
                                    isDisabled={partnerId}
                                />
                            )}
                            rules={{ required: 'Please select your partner.' }}
                        />
                        {errors?.partner_id && (
                            <span className="invalid">{errors?.partner_id?.message}</span>
                        )}
                    </Form.Group>
                </Col>

                <Col lg={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>Facility Name</Form.Label>
                        <Controller
                            name="facility_id"
                            id="facility_id"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    classNamePrefix={errors?.facility_id && 'invalidInput'}
                                    options={facilityData}
                                    onBlur={async () => {
                                        await resetField('dispensary_id');
                                        await getDispensaryByFacility(field?.value?.value);
                                    }}
                                    isDisabled={facilityId}
                                />
                            )}
                            // rules={{ required: 'Please select your facility.' }}
                        />
                        {errors?.facility_id && (
                            <span className="invalid">{errors?.facility_id?.message}</span>
                        )}
                    </Form.Group>
                </Col>

                <Col lg={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>Department Name</Form.Label>
                        <Controller
                            name="dispensary_id"
                            id="dispensary_id"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    classNamePrefix={errors?.dispensary_id && 'invalidInput'}
                                    options={dispensaryData}
                                    isDisabled={dispensaryId}
                                />
                            )}
                            // rules={{ required: 'Please select your dispensary.' }}
                        />
                        {errors?.dispensary_id && (
                            <span className="invalid">{errors?.dispensary_id?.message}</span>
                        )}
                    </Form.Group>
                </Col>

                <Col lg={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>Date</Form.Label>
                        <Controller
                            control={control}
                            name="date"
                            render={({ field }) => (
                                <DatePicker
                                    {...field}
                                    closeOnScroll
                                    placeholderText="Select date"
                                    dateFormat="dd-MM-yyyy"
                                    onChange={(date) => field.onChange(date)}
                                    selected={field.value}
                                    className="form-control"
                                    popperPlacement="top-end"
                                />
                            )}
                            rules={{
                                required: 'Please enter your date.',
                                valueAsDate: true,
                            }}
                        />
                        {errors.date && <span className="invalid">{errors.date?.message}</span>}
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Form.Group className="mb-3">
                    <Row className="text-end">
                        <Col lg={12}>
                            {/* <Button
                                className="btnCancel me-2"
                                variant="secondary"
                                onClick={handleClose}
                            >
                                Close
                            </Button> */}
                            <Button
                                className="btnSuccess"
                                onClick={handleSubmit(formSubmitHandler)}
                                type="button"
                                variant="success"
                                // disabled={isSubmitted}
                            >
                                View Report
                            </Button>
                        </Col>
                    </Row>
                </Form.Group>
            </Row>
        </Form>
    );
}
