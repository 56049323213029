/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-constructed-context-values */
/*
 * Author M. Atoar Rahman
 * Title: D3 Report Context
 * Description: D3 Report Context
 * Date: 15/07/2022
 */

import { createContext, useContext, useState } from 'react';

// Create Context
const ContextAPI = createContext();

export function useD3Report() {
    return useContext(ContextAPI);
}

// Provider Component
export function D3ReportProvider({ children }) {
    const [show, setShow] = useState(false);
    const [data, setData] = useState();

    return (
        <ContextAPI.Provider
            value={{
                show,
                setShow,
                data,
                setData,
            }}
        >
            {children}
        </ContextAPI.Provider>
    );
}

export default ContextAPI;
