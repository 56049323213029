/* Author M. Atoar Rahman
 * Title: Dispensary Stock Receive
 * Description: Page for Dispensary Stock Receive
 * Date: 27/06/2022
 */

import { useAuth } from '../../contexts/AuthContext';
import { DispensaryStockReceiveProvider } from '../dispensary/stockReceive/ContextAPI';
import Index from '../dispensary/stockReceive/Index';
import PermissionDenied from './PermissionDenied';

export default function DispensaryStockReceive() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['dispensary-stock-receive']) return <PermissionDenied />;

    return (
        <DispensaryStockReceiveProvider>
            <Index />
        </DispensaryStockReceiveProvider>
    );
}
