/*
 * Author M. Atoar Rahman
 * Title: Drug Generic
 * Description: Page for Drug Generic
 * Date: 15/03/2022
 */

import React from 'react';
import { Card } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';
import { DrugGenericProvider } from '../masterSetup/generic/ContextAPI';
import DataTable from '../masterSetup/generic/DataTable';
import FormInfo from '../masterSetup/generic/FormInfo';
import { TherapeuticProvider } from '../masterSetup/therapeuticAction/ContextAPI';
import PermissionDenied from './PermissionDenied';

export default function DrugGeneric() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['drug-generic']) return <PermissionDenied />;

    return (
        <DrugGenericProvider>
            <TherapeuticProvider>
                <Card>
                    <Card.Header className="cardHeader">
                        <h3>Drug Generic</h3>
                        <div>
                            <FormInfo />
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <DataTable />
                    </Card.Body>
                </Card>
            </TherapeuticProvider>
        </DrugGenericProvider>
    );
}
