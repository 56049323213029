/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author: M. K. Tanjin Sarker
 * Date: 23/04/2022
 * Time: 2:40 PM
 */

import { useCallback, useEffect } from 'react';
import { Badge, Button } from 'react-bootstrap';
import { useAuth } from '../../../contexts/AuthContext';
import useDataTable from '../../../hooks/useDataTable';
import SweetAlertService from '../../../services/SweetAlertService';
import UrlService from '../../../services/UrlService';
import { useData } from './ContextAPI';
import FormInfo from './FormInfo';

export default function List() {
    const { auth } = useAuth();
    const { route_permissions } = auth;
    const { data, getAllData } = useData();

    const { deleteSwal } = SweetAlertService();

    const dataList = useCallback(() => {
        getAllData();
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    const deleteHandler = (row) => {
        deleteSwal({
            id: row.id,
            url: UrlService.deleteRoleUrl(),
            refreshList: getAllData,
            beforeTitle: 'Are you sure?',
            beforeText: "You won't be able to revert this!",
            afterTitle: 'Deleted!',
            afterText: 'Your file has been deleted.',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        });
    };

    const columns = [
        {
            name: 'Name',
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: 'Description',
            selector: (row) => row.description,
            sortable: true,
        },
        // {
        //     name: 'OTP Status',
        //     selector: (row) => row.otp_status,
        //     cell: (row) =>
        //         row.otp_status == 1 ? (
        //             <Badge className="badgeActive" bg="success">
        //                 Active
        //             </Badge>
        //         ) : (
        //             <Badge className="badgeInactive" bg="success">
        //                 Inactive
        //             </Badge>
        //         ),
        // },
        // {
        //     name: 'Email Status',
        //     selector: (row) => row.email_status,
        //     cell: (row) =>
        //         row.email_status == 1 ? (
        //             <Badge className="badgeActive" bg="success">
        //                 Active
        //             </Badge>
        //         ) : (
        //             <Badge className="badgeInactive" bg="success">
        //                 Inactive
        //             </Badge>
        //         ),
        // },
        {
            name: 'Status',
            selector: (row) => row.status,
            center: true,
            cell: (row) =>
                row.status == 1 ? (
                    <Badge className="badgeActive" bg="success">
                        Active
                    </Badge>
                ) : (
                    <Badge className="badgeInactive" bg="success">
                        Inactive
                    </Badge>
                ),
        },
        {
            name: 'Action',
            selector: (row) => row.action,
            center: true,
            cell: (row) => (
                <>
                    {route_permissions['permission-management/role-info'].edit && (
                        <FormInfo editData={row} />
                    )}
                    {route_permissions['permission-management/role-info'].delete && (
                        <Button
                            onClick={() => {
                                deleteHandler(row);
                            }}
                            type="button"
                            className="btn btn-light actionBtn"
                        >
                            <i className="fas fa-trash-alt" />
                        </Button>
                    )}
                </>
            ),
        },
    ];

    const search_column = {
        name: 'Name',
        description: 'Description',
    };
    return useDataTable({ columns, data, search_column });
}
