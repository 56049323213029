/* Author M. Atoar Rahman
 * Title: Partner Stock Receive Approval
 * Description: Page for Partner Stock Receive Approval
 * Date: 27/06/2022
 */

import { useAuth } from '../../contexts/AuthContext';
import { PartnerStockReceiveApprovalProvider } from '../partner/stockReceiveApproval/ContextAPI';
import Index from '../partner/stockReceiveApproval/Index';
import PermissionDenied from './PermissionDenied';

export default function PartnerStockReceiveApproval() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['partner-stock-receive-approval']) return <PermissionDenied />;

    return (
        <PartnerStockReceiveApprovalProvider>
            <Index />
        </PartnerStockReceiveApprovalProvider>
    );
}
