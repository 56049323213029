/* eslint-disable react/jsx-no-constructed-context-values */
/*
 * Author M. Atoar Rahman
 * Title: Center Current Stock Context
 * Description: Center Current Stock Context
 * Date: 15/03/2022
 */

import { createContext, useContext, useState } from 'react';

const ContextAPI = createContext({});

export function useCurrentStock() {
    return useContext(ContextAPI);
}

export function CurrentStockProvider({ children }) {
    const [data, setData] = useState();
    const [showPdf, setShowPdf] = useState(false);
    const [showForm, setShowForm] = useState(true);
    const [responseLayer, setResponseLayer] = useState();

    return (
        <ContextAPI.Provider
            value={{
                data,
                setData,
                showPdf,
                setShowPdf,
                showForm,
                setShowForm,
                responseLayer,
                setResponseLayer,
            }}
        >
            {children}
        </ContextAPI.Provider>
    );
}

export default ContextAPI;
