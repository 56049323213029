import moment from 'moment/moment';
import React from 'react';
import { Row, Table } from 'react-bootstrap';
import logo from '../../../../assets/unhcr-logo-blue.png';
import { useConsumptionTally } from '../ContextAPI';

function ComponentToPrint(props, ref) {
    const { data } = useConsumptionTally();

    return (
        <fieldset ref={ref} className="customFieldset donation p-5">
            <div className="text-center mb-4">
                <img className="mb-3" width="200px" src={logo} alt="" />
                <br />
                <h5 className="d-block text-decoration-underline">Consumption Tally</h5>
            </div>
            <Row>
                <div className="col-md-8">
                    <p className="mb-0">
                        <b>Pertner: </b> {data?.partner_name}
                    </p>
                    <p className="mb-0">
                        <b>Facility: </b> {data?.facility_name}
                    </p>
                    <p>
                        <b>Department:</b> {data?.dispensary_name}
                    </p>
                </div>
                <div className="col-md-4 text-end">
                    <p className="mb-0">
                        <b>Date: </b> {moment(data.date).format('DD MMM YYYY')}
                    </p>
                </div>
            </Row>

            <Row>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Drug Name</th>
                            <th>Patient</th>
                            <th>Total Quantity</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.drug_list?.map((el) => (
                            <tr key={Math.random()}>
                                <td>{el.drug_name}</td>
                                <td>
                                    {el.quantity_info.map(
                                        (patient, index) =>
                                            `P${patient.serial_no}: ${patient.dispatch_qty} 
                                            ${!index ? '+' : ''}`
                                    )}
                                </td>
                                <td>{el.total_quantity}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Row>
        </fieldset>
    );
}

const forwardedInput = React.forwardRef(ComponentToPrint);

export default forwardedInput;
