/*
 * Author M. Atoar Rahman
 * Title: Expired Medicine
 * Description: Page for Expired Medicine
 * Date: 15/03/2022
 */

import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { StockReturnRequestProvider } from '../unhcr/stockReturnRequest/ContextAPI';
import Index from '../unhcr/stockReturnRequest/Index';
import PermissionDenied from './PermissionDenied';

export default function StockReturnRequest() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['stock-return-request']) return <PermissionDenied />;

    return (
        <StockReturnRequestProvider>
            <Index />
        </StockReturnRequestProvider>
    );
}
