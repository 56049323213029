/*
 * Author M. Atoar Rahman
 * Title: Dashboard
 * Description: Page for Dashboard
 * Date: 15/03/2022
 */
import { useState } from 'react';
import ItemWithLowStock from '../dashboard/itemsWithLowStock/Index';
import NearlyExpiredMedicine from '../dashboard/nearlyExpiredMedicine/Index';

export default function Dashboard() {
    const [key, setKey] = useState('nearExpiryDrug');
    return (
        <div id="tab-dashboard">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button
                        className="nav-link active"
                        id="summary-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#summary"
                        type="button"
                        role="tab"
                        aria-controls="summary"
                        aria-selected="true"
                        onClick={() => setKey('summary')}
                    >
                        <span style={{ fontSize: '36px', marginRight: '5px' }}>
                            <i className="fa-solid fa-helicopter-symbol" />
                        </span>
                        Summary
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className="nav-link"
                        id="nearExpiryDrug-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nearExpiryDrug"
                        type="button"
                        role="tab"
                        aria-controls="nearExpiryDrug"
                        aria-selected="false"
                        onClick={() => setKey('nearExpiryDrug')}
                    >
                        <span style={{ fontSize: '36px', marginRight: '5px' }}>
                            <i className="fa-solid fa-life-ring" />
                        </span>
                        Near expiry drug
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className="nav-link"
                        id="itemsWithLowStock-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#itemsWithLowStock"
                        type="button"
                        role="tab"
                        aria-controls="itemsWithLowStock"
                        aria-selected="false"
                        onClick={() => setKey('itemsWithLowStock')}
                    >
                        <span style={{ fontSize: '38px', marginRight: '5px' }}>
                            <i className="fa-solid fa-circle-radiation" />
                        </span>
                        Items with low stock
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className="nav-link"
                        id="stockReceived-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#stockReceived"
                        type="button"
                        role="tab"
                        aria-controls="stockReceived"
                        aria-selected="false"
                        onClick={() => setKey('stockReceived')}
                    >
                        <span style={{ fontSize: '36px', marginRight: '5px' }}>
                            <i className="fa-solid fa-arrows-spin" />
                        </span>
                        Stock Received (%) as per pipeline
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className="nav-link"
                        id="itemsPercentage-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#itemsPercentage"
                        type="button"
                        role="tab"
                        aria-controls="itemsPercentage"
                        aria-selected="false"
                        onClick={() => setKey('itemsPercentage')}
                    >
                        <span style={{ fontSize: '36px', marginRight: '5px' }}>
                            <i className="fa-solid fa-atom" />
                        </span>
                        Items in Stock (%)
                    </button>
                </li>
            </ul>
            <div className="tab-content" id="myTabContent">
                <div
                    className="tab-pane fade"
                    id="summary"
                    role="tabpanel"
                    aria-labelledby="summary-tab"
                >
                    summary
                </div>
                <div
                    className="tab-pane fade show active"
                    id="nearExpiryDrug"
                    role="tabpanel"
                    aria-labelledby="nearExpiryDrug-tab"
                >
                    {key === 'nearExpiryDrug' && <NearlyExpiredMedicine />}
                </div>
                <div
                    className="tab-pane fade"
                    id="itemsWithLowStock"
                    role="tabpanel"
                    aria-labelledby="itemsWithLowStock-tab"
                >
                    {key === 'itemsWithLowStock' && <ItemWithLowStock />}
                </div>
                <div
                    className="tab-pane fade"
                    id="stockReceived"
                    role="tabpanel"
                    aria-labelledby="stockReceived-tab"
                >
                    Stock Received (%) as per the pipeline
                </div>
                <div
                    className="tab-pane fade"
                    id="itemsPercentage"
                    role="tabpanel"
                    aria-labelledby="itemsPercentage-tab"
                >
                    Items in Stock (%)
                </div>
            </div>
        </div>
    );
}
