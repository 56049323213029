/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
/* eslint-disable prettier/prettier */
/*
 * Author M. Atoar Rahman
 * Title: Outer Agency
 * Description: Master Setup of Outer Agency Form: Create and Update
 * Date: 15/03/2022
 */

import { useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as pattern from '../../../services/Helpers';
import { useOuterAgency } from './ContextAPI';

export default function FormInfo({ editData = null, instantCreate = false }) {

    const {
        register,
        formState: { errors },
        reset,
        handleSubmit,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
        defaultValues: useMemo(()=>({
            ...editData,
            // eslint-disable-next-line eqeqeq
            status: editData && editData?.status == '1' ? 1 : 0,
        }),[editData])
    });

    // Reset default values
    useEffect(() => {
        reset({
            ...editData,
            // eslint-disable-next-line eqeqeq
            status: editData && editData?.status == '1' ? 1 : 0,
        });
    }, [editData]);

    // OuterAgency Context
    const { addOuterAgency, editOuterAgency, showModal, isSubmitted, setIsSubmitted } = useOuterAgency();


    // Status Check Handling
    let checkeStatus;
    // eslint-disable-next-line eqeqeq
    if (editData != null && editData.status == '1') {
        checkeStatus = 'checked';
    } else if (editData === null) {
        checkeStatus = 'checked';
    } else {
        checkeStatus = '';
    }

    // Local State
    const [checked, setChecked] = useState(checkeStatus);
    const [show, setShow] = useState(false);

    // Modal Show/Close
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setIsSubmitted(false);
    };

    // Check Data Handler
    const handleChecked = () => {
        if (checked === 'checked') {
            setChecked('');
        } else {
            setChecked('checked');
        }
    };
    

    // Form Submit Handelar
    const formSubmitHandler = async (data) => {
        setIsSubmitted(true);

        const formData = {
            ...data,
            status: data.status === true ? 1 : 0,
        };
        if (editData) {
            editOuterAgency(formData, editData.id); // Update Data
        } else {
            addOuterAgency(formData); // Create Data
        }
        setShow(false);
        reset();
    };

    // Modal close After Add/Edit Success
    useEffect(() => {
        if (showModal) {
            setShow(false);
        }
    }, [showModal]);

    return (
        <>
            {editData != null ? (
                <Button className="btn btn-light actionBtn" variant="primary" onClick={handleShow}>
                    <i className="fas fa-edit" />
                </Button>
            ) : instantCreate ? (
                <Button
                    className="btnBgNonePrimary float-start"
                    variant="light"
                    onClick={handleShow}
                >
                    New
                </Button>
            ) : (
                <Button className="btnInfo" variant="primary" onClick={handleShow}>
                    Create New
                </Button>
            )}

            <Modal className="modalWidth55" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add/Edit Outer Agency Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {editData && (
                            <Form.Group className="mb-2">
                                <Row>
                                    <Col lg={2}>
                                        <Form.Label>Code</Form.Label>
                                    </Col>
                                    <Col lg={4}>
                                        <Form.Control
                                            name="code_no"
                                            id="code_no"
                                            type="text"
                                            placeholder=""
                                            disabled
                                            autoComplete="off"
                                            {...register('code_no')}
                                            className={errors?.code_no && 'invalidInput'}
                                        />
                                        {errors.code_no && (
                                            <span className="invalid">{errors.code_no?.message}</span>
                                        )}
                                    </Col>
                                </Row>
                            </Form.Group>
                        )}
                        

                        <Form.Group className="mb-2">
                            <Row>
                                <Col lg={2}>
                                    <Form.Label>Name<span className="required">*</span></Form.Label>
                                </Col>
                                <Col lg={10}>
                                    <Form.Control
                                        name="name"
                                        id="name"
                                        type="text"
                                        placeholder=""
                                        autoComplete="off"
                                        {...register('name', {
                                            required: 'Please enter your name.',
                                        })}
                                        className={errors?.name && 'invalidInput'}
                                    />
                                    {errors.name && (
                                        <span className="invalid">{errors.name?.message}</span>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group className="mb-2">
                            <Row>
                                <Col lg={2}>
                                    <Form.Label>Address<span className="required">*</span></Form.Label>
                                </Col>
                                <Col lg={10}>
                                    <Form.Control
                                        name="address"
                                        id="address"
                                        type="text"
                                        placeholder=""
                                        autoComplete="off"
                                        {...register('address', {
                                            required: 'Please enter your address.',
                                            pattern: pattern.addressPattern,
                                        })}
                                        className={errors?.address && 'invalidInput'}
                                    />
                                    {errors.address && (
                                        <span className="invalid">{errors.address?.message}</span>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group>

                        <Row>
                            <Col lg={6}>
                                <Form.Group className="mb-2">
                                    <Row>
                                        <Col lg={4}>
                                            <Form.Label>Mobile<span className="required">*</span></Form.Label>
                                        </Col>
                                        <Col lg={8}>
                                            <Form.Control
                                                name="phone"
                                                id="phone"
                                                type="text"
                                                placeholder=""
                                                autoComplete="off"
                                                {...register('phone', {
                                                    required: 'Please enter your phone.',
                                                    pattern: pattern.mobilePattern,
                                                })}
                                                className={errors?.phone && 'invalidInput'}
                                            />
                                            {errors.phone && (
                                                <span className="invalid">
                                                    {errors.phone?.message}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            
                            <Col lg={6}>
                                <Form.Group className="mb-2">
                                    <Row>
                                        <Col lg={4}>
                                            <Form.Label>Email<span className="required">*</span></Form.Label>
                                        </Col>
                                        <Col lg={8}>
                                            <Form.Control
                                                name="email"
                                                id="email"
                                                type="text"
                                                placeholder=""
                                                autoComplete="off"
                                                {...register('email', {
                                                    required: 'Please enter your email.',
                                                    pattern: pattern.emailPattern,
                                                })}
                                                className={errors?.email && 'invalidInput'}
                                            />
                                            {errors.email && (
                                                <span className="invalid">
                                                    {errors.email?.message}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            
                            <Col lg={6}>
                                <Form.Group className="mb-2">
                                    <Row>
                                        <Col lg={4}>
                                            <Form.Label>Contact Person<span className="required">*</span></Form.Label>
                                        </Col>
                                        <Col lg={8}>
                                            <Form.Control
                                                name="contact_person"
                                                id="contact_person"
                                                type="text"
                                                placeholder=""
                                                autoComplete="off"
                                                {...register('contact_person', {
                                                    required: 'Please enter contact person name.',
                                                })}
                                                className={errors?.contact_person && 'invalidInput'}
                                            />
                                            {errors.contact_person && (
                                                <span className="invalid">
                                                    {errors.contact_person?.message}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>

                            <Col lg={6}>
                                <Form.Group className="mb-2">
                                    <Row>
                                        <Col lg={4}>
                                            <Form.Label>Designation</Form.Label>
                                        </Col>
                                        <Col lg={8}>
                                            <Form.Control
                                                name="designation"
                                                id="designation"
                                                type="text"
                                                placeholder=""
                                                autoComplete="off"
                                                {...register('designation', {
                                                    // required: 'Please enter your designation.',
                                                })}
                                                className={errors?.designation && 'invalidInput'}
                                            />
                                            {errors.designation && (
                                                <span className="invalid">
                                                    {errors.designation?.message}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Form.Group className="mb-2">
                            <Row>
                                <Col lg={2}>
                                    <Form.Label>Status</Form.Label>
                                </Col>
                                <Col lg={6}>
                                    <Form.Check
                                        name="status"
                                        id="user-status-switch"
                                        type="switch"
                                        checked={checked}
                                        onClick={handleChecked}
                                        onChange={handleChecked}
                                        {...register('status')}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>

                        <Modal.Footer className="borderTop0 pe-0">
                            <Button className="btnCancel" variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button
                                onClick={handleSubmit(formSubmitHandler)}
                                className="btnSuccess"
                                type="button"
                                variant="success"
                                disabled={isSubmitted}
                            >
                                {editData != null ? 'Update' : 'Create New'}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}
