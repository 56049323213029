/*
 * Author M. Atoar Rahman
 * Title: Drug Drug
 * Description: Page for Drug Drug
 * Date: 15/03/2022
 */

import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { DrugProvider } from '../masterSetup/drug/ContextAPI';
import Index from '../masterSetup/drug/Index';
import { DrugGroupProvider } from '../masterSetup/drugGroup/ContextAPI';
import { PresentationStrengthProvider } from '../masterSetup/presentationStrength/ContextAPI';
import { PresentationTypeProvider } from '../masterSetup/presentationType/ContextAPI';
import PermissionDenied from './PermissionDenied';

export default function Drug() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['drug-info']) return <PermissionDenied />;

    return (
        <DrugProvider>
            <DrugGroupProvider>
                <PresentationStrengthProvider>
                    <PresentationTypeProvider>
                        <Index />
                    </PresentationTypeProvider>
                </PresentationStrengthProvider>
            </DrugGroupProvider>
        </DrugProvider>
    );
}
