/* Author M. Atoar Rahman
 * Title: Facility Stock Receive Approval
 * Description: Page for Facility Stock Receive Approval
 * Date: 27/06/2022
 */

import { useAuth } from '../../contexts/AuthContext';
import { FacilityStockReceiveApprovalProvider } from '../facility/stockReceiveApproval/ContextAPI';
import Index from '../facility/stockReceiveApproval/Index';
import PermissionDenied from './PermissionDenied';

export default function FacilityStockReceiveApproval() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['facility-stock-receive-approval']) return <PermissionDenied />;

    return (
        <FacilityStockReceiveApprovalProvider>
            <Index />
        </FacilityStockReceiveApprovalProvider>
    );
}
