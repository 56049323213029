/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title:Purchase Order
 * Description:Purchase Order Form: Create and Update
 * Date: 26/04/2022
 */

import { isArray } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useAuth } from '../../../contexts/AuthContext';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import CommonApiService from '../../../services/CommonApiService';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { usePoReports } from './ContextAPI';

export default function FormInfo({ editData = null }) {
    const { setShow, setData, setShowPdf } = usePoReports();
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    // Local State
    const [drugData, setDrugData] = useState([]);
    const [drugCategoryData, setDrugCategoryData] = useState();
    const [supplierData, setSupplierData] = useState();
    const [drugList, setDrugList] = useState();
    const [supplierList, setSupplierList] = useState();
    // const [isSubmitted, setIsSubmitted] = useState(false);

    const {
        // register,
        formState: { errors },
        reset,
        handleSubmit,
        control,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
        defaultValues: useMemo(
            () => ({
                partner_id: auth?.employee_info?.partner_id && {
                    label: auth?.employee_info?.partner_name,
                    value: auth?.employee_info?.partner_id,
                },
                facility_id: auth?.employee_info?.facility_id && {
                    label: auth?.employee_info?.facility_name,
                    value: auth?.employee_info?.facility_id,
                },
                dispensary_id: auth?.employee_info?.dispensary_id && {
                    label: auth?.employee_info?.dispensary_name,
                    value: auth?.employee_info?.dispensary_id,
                },
            }),
            []
        ),
    });

    const { drugInfo, getDrugInfo, drugCategory, getDrugCategory, suppliers, getSuppliers } =
        CommonApiService();

    const dataList = useCallback(() => {
        getDrugCategory();
        getSuppliers(); // Partner
    }, []);

    const categoryHandler = (catid) => {
        getDrugInfo(catid);
    };

    useEffect(() => {
        dataList();
    }, [dataList, editData]);

    useEffect(() => {
        setDrugData(
            drugInfo?.map((el) => ({
                value: el.drug_id,
                label: el.drug_name,
            }))
        );
        setDrugCategoryData(
            drugCategory?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
        // Supplier
        setSupplierData(
            suppliers?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
    }, [drugInfo, drugCategory, suppliers]);

    useEffect(() => {
        if (drugData?.length > 0) {
            setDrugList([
                {
                    value: '-1',
                    label: 'All',
                },
                ...drugData,
            ]);
        }
        if (supplierData?.length > 0) {
            setSupplierList([
                {
                    value: '-1',
                    label: 'All',
                },
                ...supplierData,
            ]);
        }
    }, [drugData, supplierData]);

    // const handleClose = () => {
    //     setShow(false);
    //     setData('');
    // };

    // const type = '';

    const formSubmitHandler = async (data) => {
        const formData = {
            drug_ids: data.drug_id.map((el) => el.value * 1),
            category_id: data.category_id.value,
            start_date: data.date_from.toLocaleDateString('sv-SE'),
            end_date: data.date_to.toLocaleDateString('sv-SE'),
            supplier_ids: isArray(data?.supplier_id)
                ? data?.supplier_id.map((el) => el.value * 1)
                : data?.supplier_id?.value
                ? [data.supplier_id.value * 1]
                : [],
        };

        try {
            const response = await axiosPrivate.post(
                UrlService.savePoReportsUrl(),
                JSON.stringify(formData),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            setData(response.data);
            setShowPdf(true);
            if (response?.data?.status === 'success' && response.data.report_info.length === 0) {
                ToastifyService.dbErrorNotify(response?.data?.msg);
            }
            if (response?.data?.status === 'error') {
                ToastifyService.dbErrorNotify(response?.data?.msg);
                const message = Object.keys(response.data.msg);
                message.forEach((key) => {
                    response.data.message[key].map((el) => ToastifyService.dbErrorNotify(el));
                });
                ToastifyService.dbErrorNotify(response?.data?.msg);
            } else {
                setShow(false);
                reset();
            }
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Insert Failed!');
            }
        }
    };

    return (
        <Form>
            <Row>
                <Col lg={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>
                            Supplier Name<span className="invalid">*</span>
                        </Form.Label>
                        <Controller
                            name="supplier_id"
                            id="supplier_id"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    classNamePrefix={errors?.supplier_id && 'invalidInput'}
                                    options={supplierList}
                                />
                            )}
                            rules={{
                                required: 'Please select the supplier.',
                            }}
                        />
                        {errors.supplier_id && (
                            <span className="invalid">{errors.supplier_id?.message}</span>
                        )}
                    </Form.Group>
                </Col>

                <Col lg={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>Date From</Form.Label>

                        <Controller
                            control={control}
                            name="date_from"
                            render={({ field }) => (
                                <DatePicker
                                    {...field}
                                    closeOnScroll
                                    placeholderText="Select date"
                                    dateFormat="dd-MM-yyyy"
                                    onChange={(date) => field.onChange(date)}
                                    selected={field.value}
                                    className="form-control"
                                    popperPlacement="top-end"
                                    // minDate={new Date()}
                                />
                            )}
                            rules={{
                                required: 'Date is required.',
                                valueAsDate: true,
                            }}
                        />
                        {errors.date_from && (
                            <span className="invalid">{errors.date_from?.message}</span>
                        )}
                    </Form.Group>
                </Col>
                <Col lg={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>Date to</Form.Label>

                        <Controller
                            control={control}
                            name="date_to"
                            render={({ field }) => (
                                <DatePicker
                                    {...field}
                                    closeOnScroll
                                    placeholderText="Select date"
                                    dateFormat="dd-MM-yyyy"
                                    onChange={(date) => field.onChange(date)}
                                    selected={field.value}
                                    className="form-control"
                                    popperPlacement="top-end"
                                    // minDate={new Date()}
                                />
                            )}
                            rules={{
                                required: 'Date is required.',
                                valueAsDate: true,
                            }}
                        />
                        {errors.date_to && (
                            <span className="invalid">{errors.date_to?.message}</span>
                        )}
                    </Form.Group>
                </Col>

                <Col lg={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>Drug Category</Form.Label>
                        <Controller
                            name="category_id"
                            id="category_id"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    classNamePrefix={errors?.category_id && 'invalidInput'}
                                    options={drugCategoryData}
                                    onBlur={() => categoryHandler(field?.value?.value)}
                                />
                            )}
                            rules={{ required: 'Please select your item.' }}
                        />
                        {errors.category_id && (
                            <span className="invalid">{errors.category_id?.message}</span>
                        )}
                    </Form.Group>
                </Col>

                <Col lg={8}>
                    <Form.Group className="mb-3">
                        <Form.Label>Drug Name</Form.Label>
                        <Controller
                            name="drug_id"
                            id="drug_id"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    className="basic-multi-select"
                                    classNamePrefix={errors?.drug_id && 'invalidInput'}
                                    options={drugList}
                                    isMulti
                                />
                            )}
                            rules={{ required: 'Please select your item.' }}
                        />
                        {errors.drug_id && (
                            <span className="invalid">{errors.drug_id?.message}</span>
                        )}
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Form.Group className="mb-3">
                    <Row className="text-end">
                        <Col lg={12}>
                            {/* <Button
                                className="btnCancel me-2"
                                variant="secondary"
                                onClick={handleClose}
                            >
                                Close
                            </Button> */}
                            <Button
                                className="btnSuccess"
                                onClick={handleSubmit(formSubmitHandler)}
                                type="button"
                                variant="success"
                                // disabled={isSubmitted}
                            >
                                View Report
                            </Button>
                        </Col>
                    </Row>
                </Form.Group>
            </Row>
        </Form>
    );
}
