/*
 * Author M. Atoar Rahman
 * Title: Sleeping Stock
 * Description: Page for Sleeping Stock
 * Date: 15/03/2022
 */

import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { SleepingStockProvider } from '../misReports/sleepingStock/ContextAPI';
import Index from '../misReports/sleepingStock/Index';
import PermissionDenied from './PermissionDenied';

export default function SleepingStock() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['sleeping-stock']) return <PermissionDenied />;

    return (
        <SleepingStockProvider>
            <Index />
        </SleepingStockProvider>
    );
}
