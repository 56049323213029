/* eslint-disable react/jsx-no-constructed-context-values */
/*
 * Author M. Atoar Rahman
 * Title: ContextAPI
 * Description: ContextAPI
 * Date: 15/03/2022
 */

import { createContext, useContext, useState } from 'react';

const ContextAPI = createContext({});

export function useMenu() {
    return useContext(ContextAPI);
}

export function MenuProvider({ children }) {
    const [show, setShow] = useState(false);

    return (
        <ContextAPI.Provider
            value={{
                show,
                setShow,
            }}
        >
            {children}
        </ContextAPI.Provider>
    );
}

export default ContextAPI;
