/* eslint-disable radix */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Expired Medicine
 * Description: Expired Medicine Form: Create and Update
 * Date: 26/04/2022
 */

import { isArray } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useAuth } from '../../../contexts/AuthContext';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import CommonApiService from '../../../services/CommonApiService';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { useStockReturnRequest } from './ContextAPI';

export default function FormInfo({ editData = null }) {
    const { setShow, setRowData } = useStockReturnRequest();
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    // Local State
    const [partnerData, setPartnerData] = useState();
    const [facilityData, setFacilityData] = useState();
    const [partnerList, setPartnerList] = useState();
    const [facilityList, setFacilityList] = useState();
    // const [isSubmitted, setIsSubmitted] = useState(false);

    const {
        // register,
        formState: { errors },
        reset,
        resetField,
        handleSubmit,
        control,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
        defaultValues: useMemo(
            () => ({
                partner_id: auth?.employee_info?.partner_id && {
                    label: auth?.employee_info?.partner_name,
                    value: auth?.employee_info?.partner_id,
                },
                facility_id: auth?.employee_info?.facility_id && {
                    label: auth?.employee_info?.facility_name,
                    value: auth?.employee_info?.facility_id,
                },
                dispensary_id: auth?.employee_info?.dispensary_id && {
                    label: auth?.employee_info?.dispensary_name,
                    value: auth?.employee_info?.dispensary_id,
                },
            }),
            []
        ),
    });

    const {
        partner,
        getPartner,
        facilityByPartner,
        getFacilityByPartner,
        getDispensaryByFacility,
    } = CommonApiService();

    const dataList = useCallback(() => {
        getPartner(); // Partner
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList, editData]);

    useEffect(() => {
        // Partner
        setPartnerData(
            partner?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
        // Facility
        setFacilityData(
            facilityByPartner?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
    }, [partner, facilityByPartner]);

    // Get Facility by Partner login
    useEffect(() => {
        if (auth?.employee_info?.partner_id) {
            getFacilityByPartner(auth?.employee_info?.partner_id);
        }
    }, [auth?.employee_info?.partner_id]);

    useEffect(() => {
        if (partnerData?.length > 0) {
            setPartnerList([
                {
                    value: '-1',
                    label: 'All',
                },
                ...partnerData,
            ]);
        }
        if (facilityData?.length > 0) {
            setFacilityList([
                {
                    value: '-1',
                    label: 'All',
                },
                ...facilityData,
            ]);
        }
    }, [partnerData, facilityData]);

    // const handleClose = () => {
    //     setShow(false);
    //     setRowData('');
    // };

    const formSubmitHandler = async (data) => {
        console.log(data);
        const formData = {
            partner_ids: isArray(data?.partner_id)
                ? data?.partner_id.map((el) => el.value * 1)
                : data?.partner_id?.value
                ? [data.partner_id.value * 1]
                : [],
            facility_ids: isArray(data?.facility_id)
                ? data?.facility_id.map((el) => el.value * 1)
                : data?.facility_id?.value
                ? [data.facility_id.value * 1]
                : [],
        };

        try {
            const response = await axiosPrivate.post(
                UrlService.getFacilityMedicineListForReturnRequest(),
                JSON.stringify(formData),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            console.log(response.data.drug_list);

            setRowData(response.data.drug_list);
            // setShow(true);
            if (response?.data?.status === 'error') {
                ToastifyService.dbErrorNotify(response?.data?.msg);
                const message = Object.keys(response.data.msg);
                message.forEach((key) => {
                    response.data.message[key].map((el) => ToastifyService.dbErrorNotify(el));
                });
                ToastifyService.dbErrorNotify(response?.data?.message);
            } else {
                setShow(false);
                reset();
            }
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Insert Failed!');
            }
        }
    };

    return (
        <Card>
            <Card.Header className="cardHeader">
                <h3>Stock Return Request</h3>
            </Card.Header>
            <Card.Body>
                <Form>
                    <Row>
                        <Col lg={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Partner Agency<span className="invalid">*</span>
                                </Form.Label>
                                <Controller
                                    name="partner_id"
                                    id="partner_id"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            classNamePrefix={errors?.partner_id && 'invalidInput'}
                                            options={partnerList}
                                            isDisabled={auth?.employee_info?.partner_id}
                                            onBlur={async () => {
                                                await resetField('facility_id');
                                                await resetField('dispensary_id');
                                                await getFacilityByPartner(
                                                    field?.value?.length > 0
                                                        ? field?.value[0]?.value
                                                        : 0
                                                );
                                            }}
                                            isMulti
                                        />
                                    )}
                                    rules={{ required: 'Please select your partner.' }}
                                />
                                {errors?.partner_id && (
                                    <span className="invalid">{errors?.partner_id?.message}</span>
                                )}
                            </Form.Group>
                        </Col>

                        <Col lg={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Facility Name<span className="invalid">*</span>
                                </Form.Label>
                                <Controller
                                    name="facility_id"
                                    id="facility_id"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            classNamePrefix={errors?.facility_id && 'invalidInput'}
                                            options={facilityList}
                                            isDisabled={
                                                auth?.employee_info?.partner_id &&
                                                auth?.employee_info?.facility_id
                                            }
                                            onBlur={async () => {
                                                await resetField('dispensary_id');
                                                await getDispensaryByFacility(
                                                    field?.value?.length > 0
                                                        ? field?.value[0]?.value
                                                        : 0
                                                );
                                            }}
                                            isMulti
                                        />
                                    )}
                                    rules={{ required: 'Please select your facility.' }}
                                />
                                {errors?.facility_id && (
                                    <span className="invalid">{errors?.facility_id?.message}</span>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={4}>
                            <Form.Group className="mt-3 text-start">
                                <Button
                                    className="btnSuccess mt-1"
                                    onClick={handleSubmit(formSubmitHandler)}
                                    type="button"
                                    variant="success"
                                    // disabled={isSubmitted}
                                >
                                    Submit
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Card.Body>
        </Card>
    );
}
