/* eslint-disable no-unused-vars */
/*
 * Author M. Atoar Rahman
 * Title: Require Auth
 * Description: Require Auth
 * Date: 15/03/2022
 */

import { Navigate, Outlet, useLocation } from 'react-router-dom';
import ContentWrapper from '../components/layouts/ContentWrapper';
import { useAuth } from '../contexts/AuthContext';

function RequireAuth({ allowedRoles }) {
    const { auth } = useAuth();
    const location = useLocation();

    // return auth?.roles?.find((role) => allowedRoles?.includes(role)) ? (
    return auth ? (
        <ContentWrapper>
            <Outlet />
        </ContentWrapper>
    ) : auth?.accessToken ? ( // changed from user to accessToken to persist login after refresh
        <Navigate to="/unauthorized" state={{ from: location }} replace />
    ) : (
        <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;
