/*
 * Author M. Atoar Rahman
 * Title: Facility Internal Request
 * Description: Page for Facility Internal Request
 * Date: 15/03/2022
 */

import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { FacilityInternalRequestProvider } from '../facility/internalRequest/ContextAPI';
import Index from '../facility/internalRequest/Index';
import PermissionDenied from './PermissionDenied';

export default function FacilityInternalRequest() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['facility-internal-request']) return <PermissionDenied />;

    return (
        <FacilityInternalRequestProvider>
            <Index />
        </FacilityInternalRequestProvider>
    );
}
