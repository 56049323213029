/*
 * Author M. Atoar Rahman
 * Title: Country
 * Description: Page for Country
 * Date: 15/03/2022
 */

import React from 'react';
import { Card } from 'react-bootstrap';
import DataTable from '../country/DataTable';
// import FormInfo from '../country/FormInfo';

export default function Country() {
    return (
        <Card>
            <Card.Header className="cardHeader">
                <h3>Country</h3>
                <div>{/* <FormInfo /> */}</div>
            </Card.Header>
            <Card.Body>
                <DataTable />
            </Card.Body>
        </Card>
    );
}
