/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Drug Info
 * Description: Master Setup of Drug Info Form: Create and Update
 * Date: 15/03/2022
 */

import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import DrugGroupForm from '../drugGroup/FormInfo';
import PresentationStrengthForm from '../presentationStrength/FormInfo';
import PresentationTypeForm from '../presentationType/FormInfo';
import { useDrug } from './ContextAPI';

export default function FormInfo({ editData = null }) {
    const [genericData, setGenericData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [groupData, setGroupData] = useState([]);
    const [strengthData, setStrengthData] = useState([]);
    const [typeData, setTypeData] = useState([]);

    // const [manufacturerData, setManufacturerData] = useState([]);
    // const [manufacturers, setManufacturers] = useState([]);

    const {
        register,
        formState: { errors },
        reset,
        handleSubmit,
        control,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
        defaultValues: useMemo(
            () => ({
                ...editData,
                category_id: editData && {
                    label: editData?.category_name,
                    value: editData?.category_id,
                },
                generic_id: editData && {
                    label: editData?.generic_name,
                    value: editData?.generic_id,
                },
                group_id: editData && {
                    label: editData?.group_name,
                    value: editData?.group_id,
                },
                pstrength_id: editData && {
                    label: editData?.strength_name,
                    value: editData?.pstrength_id,
                },
                ptype_id: editData && {
                    label: editData?.ptype,
                    value: editData?.ptype_id,
                },
                manufacturer_id: editData && {
                    label: editData?.manufacturer_name,
                    value: editData?.manufacturer_id,
                },
            }),
            [editData]
        ),
    });

    // Reset default values
    useEffect(() => {
        reset({
            ...editData,
            category_id: editData && {
                label: editData?.category_name,
                value: editData?.category_id,
            },
            generic_id: editData && {
                label: editData?.generic_name,
                value: editData?.generic_id,
            },
            group_id: editData && {
                label: editData?.group_name,
                value: editData?.group_id,
            },
            pstrength_id: editData && {
                label: editData?.strength_name,
                value: editData?.pstrength_id,
            },
            ptype_id: editData && {
                label: editData?.ptype,
                value: editData?.ptype_id,
            },
            manufacturer_id: editData && {
                label: editData?.manufacturer_name,
                value: editData?.manufacturer_id,
            },
        });
    }, [editData]);

    // Dependency
    const axiosPrivate = useAxiosPrivate();
    const { getAllData, isSubmitted, setIsSubmitted } = useDrug();
    // Drug Context
    const { show, setShow } = useDrug();

    const GENERIC_URL = UrlService.getAllDrugGenericUrl();
    const CATEGORY_URL = UrlService.getAllDrugCategoryUrl();
    const GROUP_URL = UrlService.getAllDrugGroupUrl();
    const STRENGTH_URL = UrlService.getAllPresentationStrengthUrl();
    const TYPE_URL = UrlService.getAllPresentationTypeUrl();
    // const MANUFACTURER_URL = UrlService.getAllManufacturerUrl();

    // const manufacturerRemoveHandler = (value) => {
    //     const updatedManufacturer = manufacturers && manufacturers.filter((m) => m.value !== value);
    //     setManufacturers(updatedManufacturer);
    // };
    const [errMsg, setErrMsg] = useState('');

    const genericList = useCallback(async () => {
        try {
            const response = await axiosPrivate.get(GENERIC_URL, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const genericInfo = response?.data?.generic_info?.map((el) => ({
                value: el.id,
                label: el.name,
            }));
            setGenericData(genericInfo);
        } catch (err) {
            console.log(err);
            if (!err?.response) {
                console.log('Server Not Responding !!!!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    }, [axiosPrivate, GENERIC_URL]);

    const categoryList = useCallback(async () => {
        try {
            const response = await axiosPrivate.get(CATEGORY_URL, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const categoryInfo = response?.data?.drug_category_info?.map((el) => ({
                value: el.id,
                label: el.name,
            }));
            setCategoryData(categoryInfo);
        } catch (err) {
            console.log(err);
            if (!err?.response) {
                console.log('Server Not Responding !!!!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    }, [axiosPrivate, CATEGORY_URL]);

    const groupList = useCallback(async () => {
        try {
            const response = await axiosPrivate.get(GROUP_URL, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const groupInfo = response?.data?.drug_group_info?.map((el) => ({
                value: el.id,
                label: el.name,
            }));
            setGroupData(groupInfo);
        } catch (err) {
            console.log(err);
            if (!err?.response) {
                console.log('Server Not Responding !!!!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    }, [axiosPrivate, GROUP_URL]);

    const strengthList = useCallback(async () => {
        try {
            const response = await axiosPrivate.get(STRENGTH_URL, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const strengthInfo = response?.data?.presentation_strength_info?.map((el) => ({
                value: el.id,
                label: el.name,
            }));
            setStrengthData(strengthInfo);
        } catch (err) {
            console.log(err);
            if (!err?.response) {
                console.log('Server Not Responding !!!!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    }, [axiosPrivate, STRENGTH_URL]);

    const typeList = useCallback(async () => {
        try {
            const response = await axiosPrivate.get(TYPE_URL, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const typeInfo = response?.data?.presentation_type_info?.map((el) => ({
                value: el.id,
                label: el.name,
            }));
            setTypeData(typeInfo);
        } catch (err) {
            console.log(err);
            if (!err?.response) {
                console.log('Server Not Responding !!!!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    }, [axiosPrivate, TYPE_URL]);

    // const manufacturerList = useCallback(async () => {
    //     try {
    //         const response = await axiosPrivate.get(MANUFACTURER_URL, {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //         });

    //         const manufacturerInfo = response?.data?.manufacturer_info?.map((el) => ({
    //             value: el.id,
    //             label: el.name,
    //         }));
    //         setManufacturerData(manufacturerInfo);
    //     } catch (err) {
    //         console.log(err);
    //         if (!err?.response) {
    //             console.log('Server Not Responding !!!!');
    //         } else if (err.response?.status === 401) {
    //             console.log('Unauthorized!');
    //         } else {
    //             console.log('Data Not Found!');
    //         }
    //     }
    // }, [axiosPrivate, MANUFACTURER_URL]);

    let SAVE_URL = '';
    if (editData) {
        SAVE_URL = UrlService.updateDrugUrl(editData.drug_id);
    } else {
        SAVE_URL = UrlService.saveDrugUrl();
    }

    // Form Close
    const handleClose = () => {
        setShow(false);
    };

    useEffect(() => {
        if (show === true) {
            genericList();
            categoryList();
            groupList();
            strengthList();
            typeList();
            // manufacturerList();
        }
    }, [
        genericList,
        show,
        editData,
        categoryList,
        groupList,
        strengthList,
        typeList,
        // manufacturerList,
    ]);

    const formSubmitHandler = async (data) => {
        setIsSubmitted(true);

        const formData = {
            ...data,
            category_id: data.category_id.value,
            generic_id: data.generic_id.value,
            name: data.generic_id.label,
            group_id: data.group_id.value,
            pstrength_id: data.pstrength_id.value,
            ptype_id: data.ptype_id.value,
            manufacturer_id: data.manufacturer_id.value,
        };

        try {
            const response = await axiosPrivate.post(SAVE_URL, formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response?.data?.status === 'error') {
                setIsSubmitted(false);

                const message = Object.keys(response.data.message);
                message.forEach((key) => {
                    response.data.message[key].map((el) => ToastifyService.dbErrorNotify(el));
                });
                ToastifyService.dbErrorNotify(response?.data?.message);
                setIsSubmitted(false);
            } else if (editData != null) {
                ToastifyService.updateNotify('Data Update Successfull');
                handleClose();
                reset();
            } else {
                ToastifyService.saveNotify('Data Create Successfull');
                handleClose();
                reset();
            }

            getAllData();
        } catch (err) {
            if (!err?.response) {
                setErrMsg('Server Not Responding!');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized!');
            } else {
                setErrMsg('Data Insert/Update Failed!');
            }
            ToastifyService.errorNotify(errMsg);
            setIsSubmitted(false);
        }
    };

    return (
        <Form>
            <fieldset className="customFieldset p-4">
                {editData && (
                    <Form.Group className="mb-3">
                        <Row>
                            <Col lg={2}>
                                <Form.Label>
                                    UID<span className="invalid">*</span>
                                </Form.Label>
                            </Col>
                            <Col lg={4}>
                                <Form.Control
                                    name="code_no"
                                    id="code_no"
                                    type="text"
                                    placeholder=""
                                    disabled
                                    autoComplete="off"
                                    {...register('code_no')}
                                    className={errors?.code_no && 'invalidInput'}
                                />
                                {errors.code_no && (
                                    <span className="invalid">{errors.code_no?.message}</span>
                                )}
                            </Col>
                        </Row>
                    </Form.Group>
                )}

                <Form.Group className="mb-3">
                    <Row>
                        <Col lg={2}>
                            <Form.Label>
                                Category<span className="invalid">*</span>
                            </Form.Label>
                        </Col>
                        <Col lg={4}>
                            <Controller
                                name="category_id"
                                id="category_id"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        classNamePrefix={errors?.category_id && 'invalidInput'}
                                        options={categoryData}
                                    />
                                )}
                                rules={{ required: 'Please select your category.' }}
                            />
                            {errors.category_id && (
                                <span className="invalid">{errors.category_id?.message}</span>
                            )}
                        </Col>
                    </Row>
                </Form.Group>

                {/* <Form.Group className="mb-3">
                    <Row>
                        <Col lg={2}>
                            <Form.Label>Name</Form.Label>
                        </Col>
                        <Col lg={4}>
                            <Form.Control
                                name="name"
                                id="name"
                                type="text"
                                placeholder=""
                                autoComplete="off"
                                {...register('name', {
                                    required: 'Please enter your name.',
                                    pattern: pattern.namePattern,
                                })}
                                className={errors?.name && 'invalidInput'}
                            />
                            {errors.name && <span className="invalid">{errors.name?.message}</span>}
                        </Col>
                    </Row>
                </Form.Group> */}

                <Form.Group className="mb-3">
                    <Row>
                        <Col lg={2}>
                            <Form.Label>
                                Generic Name<span className="invalid">*</span>
                            </Form.Label>
                        </Col>
                        <Col lg={4}>
                            <Controller
                                name="generic_id"
                                id="generic_id"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        classNamePrefix={errors?.generic_id && 'invalidInput'}
                                        options={genericData}
                                    />
                                )}
                                rules={{ required: 'Please select your generic name.' }}
                            />
                            {errors.generic_id && (
                                <span className="invalid">{errors.generic_id?.message}</span>
                            )}
                        </Col>
                    </Row>
                </Form.Group>

                <Row>
                    <Col lg={4}>
                        <Form.Group className="mb-3">
                            <div className="labelRightButton">
                                <Form.Label>Administration Route</Form.Label>
                                <DrugGroupForm instantCreate />
                            </div>

                            <Controller
                                name="group_id"
                                id="group_id"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        classNamePrefix={errors?.group_id && 'invalidInput'}
                                        options={groupData}
                                        onFocus={() => groupList()}
                                    />
                                )}
                                rules={{ required: 'Please select your drug group.' }}
                            />
                            {errors.group_id && (
                                <span className="invalid">{errors.group_id?.message}</span>
                            )}
                        </Form.Group>
                    </Col>
                    <Col lg={4}>
                        <Form.Group className="mb-3" controlId="formDrugInfoPresentationStrength">
                            <div className="labelRightButton">
                                <Form.Label>Presentation Strength</Form.Label>
                                <PresentationStrengthForm instantCreate />
                            </div>

                            <Controller
                                name="pstrength_id"
                                id="pstrength_id"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        classNamePrefix={errors?.pstrength_id && 'invalidInput'}
                                        options={strengthData}
                                        onFocus={() => strengthList()}
                                    />
                                )}
                                rules={{ required: 'Please select your presentation strength.' }}
                            />
                            {errors.pstrength_id && (
                                <span className="invalid">{errors.pstrength_id?.message}</span>
                            )}
                        </Form.Group>
                    </Col>
                    <Col lg={4}>
                        <Form.Group className="mb-3" controlId="formDrugInfoPresentationType">
                            <div className="labelRightButton">
                                <Form.Label>Presentation Type</Form.Label>
                                <PresentationTypeForm instantCreate />
                            </div>

                            <Controller
                                name="ptype_id"
                                id="ptype_id"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        classNamePrefix={errors?.ptype_id && 'invalidInput'}
                                        options={typeData}
                                        onFocus={() => typeList()}
                                    />
                                )}
                                rules={{ required: 'Please select your presentation type.' }}
                            />
                            {errors.ptype_id && (
                                <span className="invalid">{errors.ptype_id?.message}</span>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
            </fieldset>

            {/* <fieldset className="customFieldset p-4 mt-3">
                <Form.Group className="mb-3" controlId="formDrugInfoPresentationType">
                    <Row>
                        <Col lg={2}>
                            <Form.Label className="float-start pe-5">Manufaturer</Form.Label>
                        </Col>
                        <Col lg={4}>
                            <Controller
                                name="manufacturer_id"
                                id="manufacturer_id"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        classNamePrefix={errors?.manufacturer_id && 'invalidInput'}
                                        options={manufacturerData}
                                    />
                                )}
                                rules={{ required: 'Please select your manufaturer.' }}
                            />
                            {errors.manufacturer_id && (
                                <span className="invalid">{errors.manufacturer_id?.message}</span>
                            )}
                        </Col>
                    </Row>
                </Form.Group>
                <Row>
                    <Col>
                        <Table className="borderDashed" responsive="sm">
                            <tbody>
                                {manufacturers.map((item) => (
                                    <tr>
                                        <td>{item.label}</td>
                                        <td className="text-end">
                                            <Button
                                                className="btn btn-light actionBtn"
                                                variant="primary"
                                                onClick={() =>
                                                    manufacturerRemoveHandler(item.value)
                                                }
                                            >
                                                <i className="fas fa-trash-alt" />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </fieldset> */}
            <div className="formFooter">
                <Button className="btnCancel me-2" variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    className="btnSuccess"
                    onClick={handleSubmit(formSubmitHandler)}
                    type="button"
                    variant="success"
                    disabled={isSubmitted}
                >
                    {editData ? 'Update' : 'Create New'}
                </Button>
            </div>
        </Form>
    );
}
