/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Button, Card, Row, Tab, Tabs } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import CommonApiService from '../../../services/CommonApiService';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { useStockReturnRequest } from './ContextAPI';
import ReturnTable from './ReturnTable';

export default function FormInfo() {
    const { setShow, rowData, returnData, setReturnData, returnList } = useStockReturnRequest();
    const { drugCategory, getDrugCategory } = CommonApiService();
    const [page, setPage] = useState(1);
    const [pageLength, setPageLength] = useState(0);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        rowData?.records?.map((el) => {
            const itemdata = {};
            itemdata.drug_id = el.drug_id;
            itemdata.drug_name = el.drug_name;
            itemdata.d2_details_id = el.d2_details_id;
            itemdata.central_warehouse_qty = el.central_warehouse_qty;
            itemdata.batch_no = el.batch_no;
            itemdata.category_id = el.category_id;
            itemdata.requested_qty = el.requested_qty;
            itemdata.approved_request_qty = el.approved_request_qty;
            itemdata.approved_qty = el.approved_qty > 0 ? el.approved_qty : el.approved_request_qty;

            const list = returnData;
            list[itemdata.drug_id] = itemdata;
            if (itemdata.drug_id === '') {
                delete list[itemdata.drug_id];
            }

            setReturnData(list);

            return returnData;
        });
    }, [rowData.records]);

    const { register, reset, handleSubmit, setValue } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
    });

    // Set default values
    useEffect(() => {
        if (!isEmpty(returnList)) {
            setValue('requisition', returnList);
        }
    }, [returnList]);

    const dataList = useCallback(() => {
        getDrugCategory(); //  Drug Category
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList]);

    useEffect(() => {
        setPageLength(drugCategory?.length);
    }, [drugCategory]);

    const tableDataDisplay = () =>
        drugCategory?.map((item) => (
            <Tab key={item.id} eventKey={item.id} title={item.name}>
                <ReturnTable data={rowData?.records?.filter((el) => el.category_id == item.id)} />
            </Tab>
        ));

    // Form Submit Handelar
    const formSubmitHandler = async (data) => {
        setIsSubmitted(true);

        const formData = {
            ...data,
            d2_master_id: rowData.d2_master_id,
            central_warehouse_id: data.central_warehouse_id.value,
        };
        try {
            await axiosPrivate.post(UrlService.unhcrD2ApprovedUrl(), JSON.stringify(formData), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            ToastifyService.updateNotify('D2 Approve Successfull');
            setShow(false);
            reset();
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Approve Failed!');
            }
        }
    };

    const handleClose = () => {
        setShow(false);
    };

    register('requisition', { required: 'Requisition quantity is required!' });

    return (
        <Card>
            <Card.Header className="cardHeader">
                <h3>D2 Approval</h3>
                <div>
                    <Button onClick={() => handleClose()} className="btnInfo" variant="primary">
                        Back to List
                    </Button>
                </div>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Tabs
                        activeKey={page}
                        transition={false}
                        id="noanim-tab-example"
                        className="mb-3 ps-3"
                        // eslint-disable-next-line radix
                        onSelect={(k) => setPage(parseInt(k))}
                    >
                        {tableDataDisplay()}
                    </Tabs>
                </Row>

                <div className="formFooter">
                    <Button className="btnCancel" variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        className="btnInfo me-2 ms-2"
                        variant="primary"
                        style={{ minWidth: '6rem' }}
                        disabled={page === 1}
                        onClick={() => {
                            setPage((currPage) => currPage - 1);
                        }}
                    >
                        Prev
                    </Button>
                    <Button
                        type="button"
                        className={`btnSuccess ${page === pageLength && 'd-none'}`}
                        variant="success"
                        style={{ minWidth: '6rem' }}
                        onClick={() => setPage((currPage) => currPage + 1)}
                    >
                        Next
                    </Button>

                    <Button
                        onClick={handleSubmit(formSubmitHandler)}
                        className={`btnSuccess ${page !== pageLength && 'd-none'}`}
                        type="button"
                        variant="success"
                        style={{ minWidth: '6rem' }}
                        disabled={isSubmitted}
                    >
                        Approve
                    </Button>
                </div>
            </Card.Body>
        </Card>
    );
}
