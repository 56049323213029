/*
 * Author M. Atoar Rahman
 * Title: Facility Internal Approve
 * Description: Page for Facility Internal Approve
 * Date: 28/04/2022
 */

import { useAuth } from '../../contexts/AuthContext';
import { FacilityInternalRequestApproveProvider } from '../facility/internalRequestApprove/ContextAPI';
import Index from '../facility/internalRequestApprove/Index';
import PermissionDenied from './PermissionDenied';

export default function FacilityInternalRequestApprove() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['facility-internal-request-approval']) return <PermissionDenied />;

    return (
        <FacilityInternalRequestApproveProvider>
            <Index />
        </FacilityInternalRequestApproveProvider>
    );
}
