/* eslint-disable eqeqeq */
/* eslint-disable radix */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Expired Medicine
 * Description: Expired Medicine Form: Create and Update
 * Date: 26/04/2022
 */

import { isArray } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useAuth } from '../../../contexts/AuthContext';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import CommonApiService from '../../../services/CommonApiService';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { useLowStock } from './ContextAPI';
import DispensaryDataTable from './DispensaryDataTable';

export default function FormInfo({ editData = null }) {
    const { setShow, setDispensaryInfo } = useLowStock();
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const empInfo = auth?.employee_info;
    const layer = empInfo?.layer_id;

    // Employee Info
    const partnerName = layer == 1 ? 'Gonoshasthaya Kendra (GK)' : empInfo?.partner_name;
    const partnerId = layer == 1 ? 3 : empInfo?.partner_id;

    const facilityName =
        layer == 1
            ? 'Kutupalong RC PHC'
            : layer == 3
            ? 'Kutupalong RC PHC'
            : empInfo?.facility_name;
    const facilityId = layer == 1 ? 1 : layer == 3 ? 1 : empInfo?.facility_id;

    const dispensaryName =
        layer == 1 ? 'All' : layer == 3 ? 'All' : layer == 4 ? 'All' : empInfo?.dispensary_name;
    const dispensaryId =
        layer == 1 ? -1 : layer == 3 ? -1 : layer == 4 ? -1 : empInfo?.dispensary_id;
    // Employee Info end

    // Local State
    const [partnerData, setPartnerData] = useState();
    const [facilityData, setFacilityData] = useState();
    const [dispensaryData, setDispensaryData] = useState();
    const [partnerList, setPartnerList] = useState();
    const [facilityList, setFacilityList] = useState();
    const [dispensaryList, setDispensaryList] = useState();
    // const [isSubmitted, setIsSubmitted] = useState(false);

    const {
        // register,
        formState: { errors },
        reset,
        resetField,
        handleSubmit,
        control,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
    });

    const {
        partner,
        getPartner,
        facilityByPartner,
        getFacilityByPartner,
        dispensaryByFacility,
        getDispensaryByFacility,
    } = CommonApiService();

    const dataList = useCallback(() => {
        getPartner(); // Partner
        getFacilityByPartner(partnerId);
        getDispensaryByFacility(facilityId);
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList, editData]);

    useEffect(() => {
        // Partner
        setPartnerData(
            partner?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
        // Facility
        setFacilityData(
            facilityByPartner?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
        // Dispensary
        setDispensaryData(
            dispensaryByFacility?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
    }, [partner, facilityByPartner, dispensaryByFacility]);

    useEffect(() => {
        if (partnerData?.length > 0) {
            setPartnerList([
                {
                    value: '-1',
                    label: 'All',
                },
                ...partnerData,
            ]);
        }
        if (facilityData?.length > 0) {
            setFacilityList([
                {
                    value: '-1',
                    label: 'All',
                },
                ...facilityData,
            ]);
        }
        if (dispensaryData?.length > 0) {
            setDispensaryList([
                {
                    value: '-1',
                    label: 'All',
                },
                ...dispensaryData,
            ]);
        }
    }, [partnerData, facilityData, dispensaryData]);

    const d = new Date();
    d.setMonth(d.getMonth() - 34);
    const pDate = d;

    // Default Table Load Data
    useEffect(async () => {
        const data = {
            start_date: pDate.toLocaleDateString('sv-SE'),
            end_date: new Date().toLocaleDateString('sv-SE'),
            partner_ids: [partnerId],
            facility_ids: [facilityId],
            dispensary_ids: [dispensaryId],
        };
        try {
            const response = await axiosPrivate.post(
                UrlService.getAllNearlyExpiredMedicineUrl(layer),
                JSON.stringify(data),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            setDispensaryInfo(response.data.drug_list);

            if (response?.data?.status === 'error') {
                ToastifyService.dbErrorNotify(response?.data?.msg);
                const message = Object.keys(response.data.msg);
                message.forEach((key) => {
                    response.data.message[key].map((el) => ToastifyService.dbErrorNotify(el));
                });
                ToastifyService.dbErrorNotify(response?.data?.message);
            } else {
                setShow(false);
                reset();
            }
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Insert Failed!');
            }
        }
    }, []);

    const formSubmitHandler = async (data) => {
        console.log(data);
        const formData = {
            start_date: pDate.toLocaleDateString('sv-SE'),
            end_date: new Date().toLocaleDateString('sv-SE'),
            partner_ids: isArray(data?.partner_id)
                ? data?.partner_id.map((el) => el.value * 1)
                : data?.partner_id?.value
                ? [data.partner_id.value * 1]
                : [],
            facility_ids: isArray(data?.facility_id)
                ? data?.facility_id.map((el) => el.value * 1)
                : data?.facility_id?.value
                ? [data.facility_id.value * 1]
                : [],
            dispensary_ids: isArray(data?.dispensary_id)
                ? data?.dispensary_id.map((el) => el.value * 1)
                : data?.dispensary_id?.value
                ? [data.dispensary_id.value * 1]
                : [],
        };

        try {
            const response = await axiosPrivate.post(
                UrlService.getAllNearlyExpiredMedicineUrl(layer),
                JSON.stringify(formData),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            setDispensaryInfo(response.data.drug_list);
            if (response?.data?.status === 'error') {
                ToastifyService.dbErrorNotify(response?.data?.msg);
                const message = Object.keys(response.data.msg);
                message.forEach((key) => {
                    response.data.message[key].map((el) => ToastifyService.dbErrorNotify(el));
                });
                ToastifyService.dbErrorNotify(response?.data?.message);
            } else {
                setShow(false);
                reset();
            }
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Insert Failed!');
            }
        }
    };

    return (
        <Card className="mt-3">
            <Card.Header className="cardHeader" style={{ background: '#5f00b5' }}>
                <h3 className="text-white">Department Warehouse: Items with low stock</h3>
            </Card.Header>
            <Card.Body>
                <div className="dataTableHeaderNone">
                    <DispensaryDataTable />
                </div>
                <Form className="mt-4">
                    <Row>
                        <Col lg={3}>
                            <Form.Group className="mb-3">
                                <Form.Label>Partner Agency</Form.Label>
                                <Controller
                                    name="partner_id"
                                    id="partner_id"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            className="unhcrSelect"
                                            classNamePrefix={errors?.partner_id && 'invalidInput'}
                                            options={partnerList}
                                            isDisabled={empInfo?.partner_id}
                                            onBlur={async () => {
                                                await resetField('facility_id');
                                                await resetField('dispensary_id');
                                                await getFacilityByPartner(
                                                    field?.value?.length > 0
                                                        ? field?.value[0]?.value
                                                        : 0
                                                );
                                            }}
                                            isMulti
                                            defaultValue={{ label: partnerName, value: partnerId }}
                                        />
                                    )}
                                    // rules={{ required: 'Please select your partner.' }}
                                />
                                {errors?.partner_id && (
                                    <span className="invalid">{errors?.partner_id?.message}</span>
                                )}
                            </Form.Group>
                        </Col>

                        <Col lg={3}>
                            <Form.Group className="mb-3">
                                <Form.Label>Facility Name</Form.Label>
                                <Controller
                                    name="facility_id"
                                    id="facility_id"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            className="unhcrSelect"
                                            classNamePrefix={errors?.facility_id && 'invalidInput'}
                                            options={facilityList}
                                            isDisabled={empInfo?.partner_id && empInfo?.facility_id}
                                            onBlur={async () => {
                                                await resetField('dispensary_id');
                                                await getDispensaryByFacility(
                                                    field?.value?.length > 0
                                                        ? field?.value[0]?.value
                                                        : 0
                                                );
                                            }}
                                            isMulti
                                            defaultValue={{
                                                label: facilityName,
                                                value: facilityId,
                                            }}
                                        />
                                    )}
                                    // rules={{ required: 'Please select your facility.' }}
                                />
                                {errors?.facility_id && (
                                    <span className="invalid">{errors?.facility_id?.message}</span>
                                )}
                            </Form.Group>
                        </Col>

                        <Col lg={3}>
                            <Form.Group className="mb-3">
                                <Form.Label>Department Name</Form.Label>
                                <Controller
                                    name="dispensary_id"
                                    id="dispensary_id"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            className="unhcrSelect"
                                            isDisabled={
                                                empInfo?.partner_id &&
                                                empInfo?.facility_id &&
                                                empInfo?.dispensary_id
                                            }
                                            classNamePrefix={
                                                errors?.dispensary_id && 'invalidInput'
                                            }
                                            options={dispensaryList}
                                            isMulti
                                            defaultValue={{
                                                label: dispensaryName,
                                                value: dispensaryId,
                                            }}
                                        />
                                    )}
                                    // rules={{ required: 'Please select your dispensary.' }}
                                />
                                {errors?.dispensary_id && (
                                    <span className="invalid">
                                        {errors?.dispensary_id?.message}
                                    </span>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={3} className="my-auto">
                            <Button
                                className="btnSuccess"
                                onClick={handleSubmit(formSubmitHandler)}
                                type="button"
                                variant="success"
                                // disabled={isSubmitted}
                            >
                                View Report
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Card.Body>
        </Card>
    );
}
