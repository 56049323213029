/* eslint-disable eqeqeq */
import { useAuth } from '../../../contexts/AuthContext';
import CentralForm from './CentralForm';
import { NearlyExpiredMedicineProvider } from './ContextAPI';
import DespensaryForm from './DespensaryForm';
import FacilityForm from './FacilityForm';
import PartnerForm from './PartnerForm';

export default function Index() {
    const { auth } = useAuth();

    return (
        <NearlyExpiredMedicineProvider>
            {(auth?.employee_info?.layer_id == 1 || auth?.employee_info?.layer_id == 2) && (
                <CentralForm />
            )}
            {(auth?.employee_info?.layer_id == 1 || auth?.employee_info?.layer_id == 3) && (
                <PartnerForm />
            )}
            {(auth?.employee_info?.layer_id == 1 ||
                auth?.employee_info?.layer_id == 3 ||
                auth?.employee_info?.layer_id == 4) && <FacilityForm />}
            {(auth?.employee_info?.layer_id == 1 ||
                auth?.employee_info?.layer_id == 3 ||
                auth?.employee_info?.layer_id == 4 ||
                auth?.employee_info?.layer_id == 5) && <DespensaryForm />}
        </NearlyExpiredMedicineProvider>
    );
}
