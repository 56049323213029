/* eslint-disable eqeqeq */
/* eslint-disable radix */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * Author M. Atoar Rahman
 * Title: Expired Medicine
 * Description: Expired Medicine Form: Create and Update
 * Date: 26/04/2022
 */

import { isArray } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useAuth } from '../../../contexts/AuthContext';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import CommonApiService from '../../../services/CommonApiService';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';
import { useSleepingStock } from './ContextAPI';

export default function FormInfo({ editData = null }) {
    const { setShow, setData, setShowPdf } = useSleepingStock();
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const layer = auth?.employee_info?.layer_id;

    // Local State
    const [centerWarehouseData, setCenterWarehouseData] = useState();
    const [partnerData, setPartnerData] = useState();
    const [facilityData, setFacilityData] = useState();
    const [dispensaryData, setDispensaryData] = useState();
    const [centralList, setCentralList] = useState();
    const [partnerList, setPartnerList] = useState();
    const [facilityList, setFacilityList] = useState();
    const [dispensaryList, setDispensaryList] = useState();
    // const [isSubmitted, setIsSubmitted] = useState(false);

    const {
        register,
        formState: { errors },
        reset,
        resetField,
        handleSubmit,
        control,
    } = useForm({
        mode: 'onTouched',
        criteriaMode: 'firstError',
        reValidateMode: 'onBlur',
        defaultValues: useMemo(
            () => ({
                partner_id: auth?.employee_info?.partner_id && {
                    label: auth?.employee_info?.partner_name,
                    value: auth?.employee_info?.partner_id,
                },
                facility_id: auth?.employee_info?.facility_id && {
                    label: auth?.employee_info?.facility_name,
                    value: auth?.employee_info?.facility_id,
                },
                dispensary_id: auth?.employee_info?.dispensary_id && {
                    label: auth?.employee_info?.dispensary_name,
                    value: auth?.employee_info?.dispensary_id,
                },
            }),
            []
        ),
    });

    const {
        partner,
        getPartner,
        facilityByPartner,
        getFacilityByPartner,
        dispensaryByFacility,
        getDispensaryByFacility,
        centerWarehouse,
        getCenterWarehouse,
    } = CommonApiService();

    const dataList = useCallback(() => {
        getPartner(); // Partner
        getCenterWarehouse(); // Center Warehouse
    }, []);

    useEffect(() => {
        dataList();
    }, [dataList, editData]);

    useEffect(() => {
        // Partner
        setPartnerData(
            partner?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
        // Facility
        setFacilityData(
            facilityByPartner?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
        // Dispensary
        setDispensaryData(
            dispensaryByFacility?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
        // Center Warehouse
        setCenterWarehouseData(
            centerWarehouse?.map((el) => ({
                value: el.id,
                label: el.name,
            }))
        );
    }, [partner, facilityByPartner, dispensaryByFacility, centerWarehouse]);

    // Get Facility by Partner login
    useEffect(() => {
        if (auth?.employee_info?.partner_id) {
            getFacilityByPartner(auth?.employee_info?.partner_id);
        }
    }, [auth?.employee_info?.partner_id]);

    // Get Dispensary by Facility login
    useEffect(() => {
        if (auth?.employee_info?.facility_id) {
            getDispensaryByFacility(auth?.employee_info?.facility_id);
        }
    }, [auth?.employee_info?.facility_id]);

    useEffect(() => {
        if (centerWarehouseData?.length > 0) {
            setCentralList([
                {
                    value: '-1',
                    label: 'All',
                },
                ...centerWarehouseData,
            ]);
        }
        if (partnerData?.length > 0) {
            setPartnerList([
                {
                    value: '-1',
                    label: 'All',
                },
                ...partnerData,
            ]);
        }
        if (facilityData?.length > 0) {
            setFacilityList([
                {
                    value: '-1',
                    label: 'All',
                },
                ...facilityData,
            ]);
        }
        if (dispensaryData?.length > 0) {
            setDispensaryList([
                {
                    value: '-1',
                    label: 'All',
                },
                ...dispensaryData,
            ]);
        }
    }, [partnerData, facilityData, dispensaryData, centerWarehouseData]);

    const [checked, setChecked] = useState('');

    const handleCentralCurrentStock = () => {
        reset();
        resetField();

        if (checked === 'checked') {
            setChecked('');
        } else {
            setChecked('checked');
        }
    };

    const durationData = [
        { label: '3', value: '3' },
        { label: '6', value: '6' },
        { label: '12', value: '12' },
    ];

    const formSubmitHandler = async (data) => {
        console.log(data);
        const formData = {
            duration: data?.duration?.value,
            warehouse_ids: isArray(data?.warehouse_id)
                ? data?.warehouse_id.map((el) => el.value * 1)
                : data?.warehouse_id?.value
                ? [data.warehouse_id.value * 1]
                : [],
            partner_ids: isArray(data?.partner_id)
                ? data?.partner_id.map((el) => el.value * 1)
                : data?.partner_id?.value
                ? [data.partner_id.value * 1]
                : [],
            facility_ids: isArray(data?.facility_id)
                ? data?.facility_id.map((el) => el.value * 1)
                : data?.facility_id?.value
                ? [data.facility_id.value * 1]
                : [],
            dispensary_ids: isArray(data?.dispensary_id)
                ? data?.dispensary_id.map((el) => el.value * 1)
                : data?.dispensary_id?.value
                ? [data.dispensary_id.value * 1]
                : [],
        };

        try {
            const response = await axiosPrivate.post(
                UrlService.getAllSleepingStockUrl(layer),
                JSON.stringify(formData),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            setData(response.data.drug_list);
            setShowPdf(true);
            if (response?.data?.status === 'error') {
                ToastifyService.dbErrorNotify(response?.data?.msg);
                const message = Object.keys(response.data.msg);
                message.forEach((key) => {
                    response.data.message[key].map((el) => ToastifyService.dbErrorNotify(el));
                });
                ToastifyService.dbErrorNotify(response?.data?.message);
            } else {
                setShow(false);
                reset();
            }
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Insert Failed!');
            }
        }
    };

    return (
        <Card>
            <Card.Header className="cardHeader">
                <h3>Sleeping Stock</h3>
            </Card.Header>
            <Card.Body>
                <Form>
                    {layer == 1 && (
                        <Row>
                            <Col>
                                <Form.Group className="mb-3 d-flex lign-items-center">
                                    <Form.Check
                                        name="central_current_stock"
                                        id="user-status-switch"
                                        type="switch"
                                        checked={checked}
                                        onClick={() => {
                                            handleCentralCurrentStock();
                                        }}
                                        onChange={() => {
                                            handleCentralCurrentStock();
                                        }}
                                        {...register('central_current_stock')}
                                    />
                                    <span className="mt-1">Central Warehouse</span>
                                </Form.Group>
                            </Col>
                            <hr />
                        </Row>
                    )}
                    {((layer == 1 && checked) || layer == 2) && (
                        <Row>
                            <Col lg={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        Warehouse<span className="invalid">*</span>
                                    </Form.Label>
                                    <Controller
                                        name="warehouse_id"
                                        id="warehouse_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                classNamePrefix={
                                                    errors?.warehouse_id && 'invalidInput'
                                                }
                                                options={centralList}
                                            />
                                        )}
                                        rules={{ required: 'Please select your partner.' }}
                                    />
                                    {errors?.warehouse_id && (
                                        <span className="invalid">
                                            {errors?.warehouse_id?.message}
                                        </span>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                    )}
                    {!checked && layer != 2 && (
                        <Row>
                            <Col lg={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        Partner Agency<span className="invalid">*</span>
                                    </Form.Label>
                                    <Controller
                                        name="partner_id"
                                        id="partner_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                classNamePrefix={
                                                    errors?.partner_id && 'invalidInput'
                                                }
                                                options={partnerList}
                                                isDisabled={auth?.employee_info?.partner_id}
                                                onBlur={async () => {
                                                    await resetField('facility_id');
                                                    await resetField('dispensary_id');
                                                    await getFacilityByPartner(
                                                        field?.value?.length > 0
                                                            ? field?.value[0]?.value
                                                            : 0
                                                    );
                                                }}
                                                isMulti
                                            />
                                        )}
                                        rules={{ required: 'Please select your partner.' }}
                                    />
                                    {errors?.partner_id && (
                                        <span className="invalid">
                                            {errors?.partner_id?.message}
                                        </span>
                                    )}
                                </Form.Group>
                            </Col>

                            <Col lg={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Facility Name</Form.Label>
                                    <Controller
                                        name="facility_id"
                                        id="facility_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                classNamePrefix={
                                                    errors?.facility_id && 'invalidInput'
                                                }
                                                options={facilityList}
                                                isDisabled={
                                                    auth?.employee_info?.partner_id &&
                                                    auth?.employee_info?.facility_id
                                                }
                                                onBlur={async () => {
                                                    await resetField('dispensary_id');
                                                    await getDispensaryByFacility(
                                                        field?.value?.length > 0
                                                            ? field?.value[0]?.value
                                                            : 0
                                                    );
                                                }}
                                                isMulti
                                            />
                                        )}
                                        // rules={{ required: 'Please select your facility.' }}
                                    />
                                    {errors?.facility_id && (
                                        <span className="invalid">
                                            {errors?.facility_id?.message}
                                        </span>
                                    )}
                                </Form.Group>
                            </Col>

                            <Col lg={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Department Name</Form.Label>
                                    <Controller
                                        name="dispensary_id"
                                        id="dispensary_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                isDisabled={
                                                    auth?.employee_info?.partner_id &&
                                                    auth?.employee_info?.facility_id &&
                                                    auth?.employee_info?.dispensary_id
                                                }
                                                classNamePrefix={
                                                    errors?.dispensary_id && 'invalidInput'
                                                }
                                                options={dispensaryList}
                                                isMulti
                                            />
                                        )}
                                        // rules={{ required: 'Please select your dispensary.' }}
                                    />
                                    {errors?.dispensary_id && (
                                        <span className="invalid">
                                            {errors?.dispensary_id?.message}
                                        </span>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col lg={4}>
                            <Form.Group className="mb-3">
                                <Form.Label>Duration</Form.Label>
                                <Controller
                                    name="duration"
                                    id="duration"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            classNamePrefix={errors?.duration && 'invalidInput'}
                                            options={durationData}
                                        />
                                    )}
                                    // rules={{ required: 'Please select your dispensary.' }}
                                />
                                {errors?.duration && (
                                    <span className="invalid">{errors?.duration?.message}</span>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3">
                            <Row className="text-end">
                                <Col lg={12}>
                                    <Button
                                        className="btnSuccess"
                                        onClick={handleSubmit(formSubmitHandler)}
                                        type="button"
                                        variant="success"
                                        // disabled={isSubmitted}
                                    >
                                        View Report
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Row>
                </Form>
            </Card.Body>
        </Card>
    );
}
