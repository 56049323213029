/*
 * Author M. Atoar Rahman
 * Title: Dispensary Consumption Tally
 * Description: Page for Dispensary Consumption Tally
 * Date: 15/03/2022
 */

import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { ConsumptionReportsProvider } from '../misReports/consumptionReports/ContextAPI';
import Index from '../misReports/consumptionReports/Index';
import PermissionDenied from './PermissionDenied';

export default function ConsumptionReports() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['consumption-reports']) return <PermissionDenied />;

    return (
        <ConsumptionReportsProvider>
            <Index />
        </ConsumptionReportsProvider>
    );
}
