/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useContext, useReducer, useState } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ToastifyService from '../../../services/ToastifyService';
import UrlService from '../../../services/UrlService';

// Initial State
const initialState = {
    outerAgencies: [],
};

// Reducer Function
const reducer = (state, action) => {
    switch (action.type) {
        case 'DATA_LIST':
            return {
                ...state,
                outerAgencies: action.payload,
            };
        case 'ADD_DATA':
            return {
                ...state,
                outerAgencies: [action.payload, ...state.outerAgencies],
            };
        case 'EDIT_DATA':
            const updateData = action.payload;

            const updateouterAgencies = state.outerAgencies.map((item) => {
                if (item.id === updateData.id) {
                    return updateData;
                }
                return item;
            });
            return {
                ...state,
                outerAgencies: updateouterAgencies,
            };

        default:
            return state;
    }
};

// Create Context
const ContextAPI = createContext(initialState);

export function useOuterAgency() {
    return useContext(ContextAPI);
}

// Provider Component
export function OuterAgencyProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [errMsg, setErrMsg] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    // Dependency
    const axiosPrivate = useAxiosPrivate();

    // API Function: Fatch All Data
    const getAllData = async () => {
        try {
            const response = await axiosPrivate.get(UrlService.getAllOuterAgencyUrl(), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            dispatch({
                type: 'DATA_LIST',
                payload: response?.data?.agency_info,
            });
        } catch (err) {
            if (!err?.response) {
                console.log('Server Not Responding!');
            } else if (err.response?.status === 401) {
                console.log('Unauthorized!');
            } else {
                console.log('Data Not Found!');
            }
        }
    };

    // API Function: Add/Edit Data
    const addOrUodateData = async (data, id) => {
        try {
            const response = await axiosPrivate.post(
                id ? UrlService.updateOuterAgencyUrl(id) : UrlService.saveOuterAgencyUrl(),
                JSON.stringify(data),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            await getAllData();
            await setShowModal(true);
            setIsSubmitted(true);

            if (response?.data?.status === 'error') {
                ToastifyService.errorNotify(response?.data?.message);
                setIsSubmitted(false);
            } else if (id != null) {
                ToastifyService.updateNotify('Data Update Successfull');
            } else {
                ToastifyService.saveNotify('Data Create Successfull');
            }
        } catch (err) {
            if (!err?.response) {
                setErrMsg('Server Not Responding!');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized!');
            } else if (id) {
                setErrMsg('Data Update Failed!');
            } else {
                setErrMsg('Data Insert Failed!');
            }
            ToastifyService.errorNotify(errMsg);
            setIsSubmitted(false);
        }
    };

    // Add OuterAgency
    const addOuterAgency = (value) => {
        addOrUodateData(value);
        dispatch({
            type: 'ADD_DATA',
            payload: value,
        });
    };

    // Edit OuterAgency
    const editOuterAgency = (value, id) => {
        addOrUodateData(value, id);
        dispatch({
            type: 'EDIT_DATA',
            payload: value,
        });
    };

    return (
        <ContextAPI.Provider
            value={{
                outerAgencies: state.outerAgencies,
                getAllData,
                addOuterAgency,
                editOuterAgency,
                showModal,
                isSubmitted,
                setIsSubmitted,
            }}
        >
            {children}
        </ContextAPI.Provider>
    );
}

export default ContextAPI;
