/*
 * Author M. Atoar Rahman
 * Title: Medicines Requisition Partner
 * Description: Page for Medicines Requisition Partner
 * Date: 30/05/2022
 */

import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { D2FormProvider } from '../partner/d2Form/ContextAPI';
import Index from '../partner/d2Form/Index';
import PermissionDenied from './PermissionDenied';

export default function D2Form() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['d2-preparation']) return <PermissionDenied />;

    return (
        <D2FormProvider>
            <Index />
        </D2FormProvider>
    );
}
