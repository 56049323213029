/*
 * Author M. Atoar Rahman
 * Title: Facility Stock Distribution
 * Description: Index for Facility Stock Distribution
 * Date: 28/06/2022
 */

import { Button, Card, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import ApprovedTable from './ApprovedTable';
import { useFacilityStockDistribution } from './ContextAPI';
import FormInfo from './FormInfo';
import PendingTable from './PendingTable';
import ShowModel from './ShowModel';

export default function Index() {
    const { show, formShow, setFormShow } = useFacilityStockDistribution();
    const createHander = () => {
        setFormShow(true);
    };
    return formShow ? (
        <FormInfo />
    ) : (
        <>
            <Card>
                <Card.Header className="cardHeader">
                    <h3>Facility Stock Distribution</h3>
                    <div>
                        <Button
                            onClick={() => createHander()}
                            className="btnInfo"
                            variant="primary"
                        >
                            Create New
                        </Button>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Form>
                        <Row className="requisition_table">
                            <Col>
                                <Tabs
                                    defaultActiveKey="pending"
                                    transition={false}
                                    id="noanim-tab-example"
                                    className="mb-3"
                                >
                                    <Tab eventKey="pending" title="Pending">
                                        <PendingTable />
                                    </Tab>
                                    <Tab eventKey="approved" title="Approved">
                                        <ApprovedTable />
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
            {show && <ShowModel />}
        </>
    );
}
