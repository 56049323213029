/*
 * Author M. Atoar Rahman
 * Title: Purchase Order
 * Description: Page for Purchase Order
 * Date: 15/03/2022
 */

import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { PurchaseOrderProvider } from '../unhcr/purchaseOrder/ContextAPI';
import Index from '../unhcr/purchaseOrder/Index';
import PermissionDenied from './PermissionDenied';

export default function PurchaseOrder() {
    // Route Permission
    const { auth } = useAuth();
    const { route_permissions } = auth;
    if (!route_permissions['purchase-order']) return <PermissionDenied />;

    return (
        <PurchaseOrderProvider>
            <Index />
        </PurchaseOrderProvider>
    );
}
