/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-constructed-context-values */
/*
 * Author M. Atoar Rahman
 * Title: Bin Card Context
 * Description: Bin Card Context
 * Date: 15/07/2022
 */

import { createContext, useContext, useState } from 'react';

// Create Context
const ContextAPI = createContext();

export function useBinCard() {
    return useContext(ContextAPI);
}

// Provider Component
export function BinCardProvider({ children }) {
    const [showBin, setShowBin] = useState(false);
    const [pdfData, setPdfData] = useState();

    return (
        <ContextAPI.Provider
            value={{
                showBin,
                setShowBin,
                pdfData,
                setPdfData,
            }}
        >
            {children}
        </ContextAPI.Provider>
    );
}

export default ContextAPI;
